import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
		width: '100%',
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 1000,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		zIndex: 1201,
		overflowY: 'hidden',
		boxShadow: '2px 5px 16px 0px rgba(196, 196, 196, 0.3)',
		display: 'flex',
		flexDirection: 'column',
	},

	header: {
		paddingBottom: 5,
		height: 62,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
	},

	footer: {
		height: 100,
		width: 500,
		background: theme.colors.white,
		position: 'fixed',
		bottom: 0,
		right: 0,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 20,
		paddingRight: 20,
		display: 'flex',
		justifyContent: 'space-between',
	},
	footer_content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flex: 1,
	},
	allocate_card: {
		padding: 12,
		minHeight: 64,
		borderRadius: 6,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 2px 4px 0px rgba(196, 196, 196, 0.30)`,
		cursor: 'pointer',
	},
	icon_wrap: {
		width: 40,
		height: 40,
		borderRadius: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	map_wrap: { padding: 20, flex: 1, marginRight: 10, borderRadius: 20, height: '100%', overflowY: 'hidden' },

	content: {
		display: 'flex',
		flex: 1,
		overflow: 'hidden',
	},
	right_wrap: { flex: 1, overflow: 'hidden', paddingRight: 30 },
}));

export default useStyles;
