import React, { useEffect, useRef } from 'react';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Routes from 'resources/Routes';
import { SETTINGS_MENU } from 'views/SettingsFlow/OrderSettings/OrderSettings';

export const useStyles = makeStyles(() => ({
	use_case_wrap: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		border: `1px solid ${theme.colors.lightPurple}`,
		padding: 10,
		borderRadius: 36,
		height: 36,
		cursor: 'pointer',
	},
	use_case_item_wrap: {
		position: 'absolute',
		top: 80,
		right: 0,
		maxHeight: 285,
		width: '75%',
		borderRadius: 6,
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.3)',
		overflowY: 'scroll',
		marginTop: 5,
		zIndex: 100,
	},
	description: { fontSize: 10, color: theme.colors.darkGrey2, marginTop: 4 },
	create_wrap: { height: 22, paddingLeft: 8, display: 'flex', alignItems: 'center', width: '100%' },
	create_text: { fontSize: 12, color: theme.colors.primary, textDecoration: 'underline', marginLeft: 4 },
}));

const SmartConfigList = ({ selected_config, show_config, onClick, on_config_select, set_show_usecase, configs, is_manifest = false }) => {
	const history = useHistory();
	const component_ref = useRef(null);
	const classes = useStyles();

	useEffect(() => {
		const handle_click_outside = (event) => {
			if (component_ref.current && !component_ref.current.contains(event.target)) {
				set_show_usecase(false);
			}
		};
		document.addEventListener('click', handle_click_outside, true);
		return () => {
			document.removeEventListener('click', handle_click_outside, true);
		};
	}, []);

	return (
		<div ref={component_ref}>
			<div onClick={onClick} className={classes.use_case_wrap} style={{ width: configs.length > 0 ? 90 : 70 }}>
				<Text semi style={{ color: theme.colors.primary, fontSize: 14 }}>
					{selected_config ? selected_config.title : 'Default'}
				</Text>
				{configs.length > 0 && (
					<img
						src={ImageLinks.arrow_up_circle_solid}
						width={20}
						height={20}
						style={!show_config ? { transform: 'rotate(180deg)' } : {}}
						alt='drop_down'
					/>
				)}
			</div>
			{show_config && (
				<div className={classes.use_case_item_wrap} style={{ background: theme.colors.white }}>
					{_.map(configs, (item, key) => (
						<div
							onClick={() => on_config_select(item)}
							style={{
								padding: 12,
								borderBottom: `1px solid ${theme.colors.lightGrey5}`,
								backgroundColor: selected_config?.id === item.id ? theme.colors.lightPurple : theme.colors.white,
							}}>
							<Text medium style={{ fontSize: 14 }}>{`${item.title}`}</Text>
							<Text medium className={classes.description}>
								{item.description}
							</Text>
						</div>
					))}

					<div
						className={classes.create_wrap}
						onClick={() =>
							history.push(
								Routes.ORDER_SETTINGS.path + `?type=${is_manifest ? SETTINGS_MENU.auto_manifest.id : SETTINGS_MENU.smart_allocation.id}`,
							)
						}>
						<img src={ImageLinks.addPurple} width={12} height={12} alt='' />
						<Text semi className={classes.create_text}>
							Create New
						</Text>
					</div>
				</div>
			)}
		</div>
	);
};

export default SmartConfigList;
