import _ from 'lodash';
import React from 'react';
import { LinearProgress, makeStyles, Modal } from '@material-ui/core';
import Text from 'modules/Text';
import ContainedButton from 'modules/ContainedButton';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import OutlinedButton from 'modules/OutlinedButton';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	modalContainer: { display: 'flex', outline: 'none' },
	componentContainer: {
		padding: 24,
		paddingBottom: 0,
		background: 'white',
		width: 600,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	headerTitle: { fontSize: 18, width: '100%', paddingTop: 5 },
	textContainer: { fontSize: 14, marginTop: 8 },
	containedButtonsContainer: { marginTop: 32, marginBottom: 24, display: 'flex', alignSelf: 'flex-end' },
	button: {
		'&:hover': {
			background: 'linear-gradient(180deg, #BC0000 0%, #620101 112.5%) !important',
			boxShadow: '0px 8px 20px 0px rgba(102, 102, 102, 0.15) !important',
			border: 'none !important',
		},
	},
}));

const ConfirmClose = ({
	close,
	on_confirm,
	title = 'Are you sure you want to close',
	sub_title = '',
	style = {},
	titleStyles = {},
	headerTitle = '',
	negative_btn_text = 'Cancel',
	positive_btn_text = 'Confirm',
	is_error_msg = false,
	is_save_change = false,
	close_on_confirm = true,
	show_negative_btn,
	modal_icon = null,
	count = 0,
	async_length = 1,
	is_async_done = false,
	handle_negative_action,
}) => {
	const handle_confirm = () => {
		on_confirm();
		if (close_on_confirm) close();
	};
	const classes = useStyles();
	return (
		<Modal open={true} onClose={close} className={classes.modalContainer}>
			<div className={classes.componentContainer} style={style}>
				<div style={{ display: 'flex' }}>
					<img
						src={
							!_.isEmpty(modal_icon)
								? modal_icon
								: is_error_msg
								? ImageLinks.delete_red_ripple
								: is_save_change
								? ImageLinks.green_tick_ripple
								: ImageLinks.excalamation_yellow_ripple
						}
						style={{ width: 48, height: 48 }}
						alt=''
					/>
					<div style={{ marginLeft: 16, width: '100%' }}>
						<div style={{ display: 'flex' }}>
							{headerTitle && (
								<Text semi className={classes.headerTitle}>
									{headerTitle}
								</Text>
							)}
							<div style={{ cursor: 'pointer' }} onClick={close}>
								<img src={ImageLinks.crossBlack} style={{ width: 16, height: 16, paddingTop: 0 }} alt='' />
							</div>
						</div>
						<Text medium className={classes.textContainer} style={titleStyles}>
							{title}
						</Text>
						<Text medium className={classes.textContainer} style={titleStyles}>
							{sub_title}
						</Text>
					</div>
				</div>

				{!is_async_done && count === 0 && (
					<div className={classes.containedButtonsContainer}>
						{show_negative_btn && (
							<Button
								type='outlined'
								size='large'
								text={negative_btn_text}
								onClick={handle_negative_action ? handle_negative_action : close}
								style={{ marginRight: 12, minWidth: 110 }}
							/>
						)}
						<Button
							type='round'
							size='large'
							text={positive_btn_text}
							onClick={handle_confirm}
							className={is_error_msg ? classes.button : ''}
							style={{
								backgroundColor: is_error_msg ? theme.colors.red : theme.colors.primary,
								borderColor: is_error_msg ? theme.colors.red : theme.colors.primary,
								minWidth: 110,
							}}
						/>
					</div>
				)}
				{!is_async_done && count !== 0 && (
					<div className='d-flex justify-content-center align-items-center' style={{ padding: '32px 0', flexDirection: 'column' }}>
						<Text bold style={{ marginBottom: 20, color: theme.colors.darkGrey2 }}>
							<Text bold component={'span'} style={{ color: theme.colors.primary }}>
								{count} of {async_length}
							</Text>{' '}
							processed
						</Text>
						<LinearProgress variant='determinate' style={{ width: '60%' }} color='primary' value={(count / async_length) * 100} />
					</div>
				)}
				{is_async_done && (
					<div className={classes.containedButtonsContainer}>
						<Button
							type='round'
							size='large'
							text={positive_btn_text}
							onClick={close}
							className={is_error_msg ? classes.button : ''}
							style={{
								backgroundColor: is_error_msg ? theme.colors.red : theme.colors.primary,
								borderColor: is_error_msg ? theme.colors.red : theme.colors.primary,
								minWidth: 110,
							}}
						/>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default ConfirmClose;
