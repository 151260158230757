import { Divider, makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import React, { useContext } from 'react';
import OrderModalContext from '../OrderModalContext';
import _ from 'lodash';
import theme from 'resources/theme';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		borderRadius: 8,
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		padding: 12,
		marginTop: 20,
	},
	add_button: {
		backgroundColor: theme.colors.lightPurple,
		borderRadius: 18,
		height: 20,
		width: 46,
		padding: 0,
		marginLeft: 10,
		'&:hover': {
			backgroundColor: theme.colors.lightPurple,
		},
	},
}));

function DeliveryCharges() {
	const classes = useStyles();
	const { data } = useContext(OrderModalContext);
	return (
		<div className={classes.wrapper}>
			<div className='d-flex align-items-center justify-content-between'>
				<div className='d-flex align-items-center' style={{ gap: 5 }}>
					<img src={ImageLinks.rupee_outlined} height={16} width={16} alt='' />
					<Text bold component={'span'} style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
						Delivery Charge
					</Text>
				</div>
			</div>
			<Divider style={{ margin: '10px 0' }} />
			<div className='d-flex justify-content-between'>
				<Text style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>Total amount</Text>
				<Text bold style={{ fontSize: 14, color: theme.colors.darkGrey7 }}>
					{data?.fulfillment?.delivery_charge ? data?.fulfillment?.delivery_charge.toFixed(2) : 0}
				</Text>
			</div>
		</div>
	);
}

export default DeliveryCharges;
