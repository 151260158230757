import { makeStyles, useTheme } from '@material-ui/core';
import _ from 'lodash';
import Text from 'modules/Text';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import EmptyState from './EmptyState';
import Loader from 'modules/Loader';

ChartJS.register(CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend, TimeScale, LineElement, Filler);

const useStyles = makeStyles((theme) => ({
	main_container: {
		backgroundColor: theme.colors.white,
		padding: 12,
		boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
		borderRadius: 12,
		height: '100%',
		position: 'relative',
	},
	divider: { height: 1, width: '100%', backgroundColor: theme.colors.lightGrey5 },
	flex_box: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10, marginTop: 5 },
	heading_text: { color: theme.colors.darkGrey4, fontSize: 14 },
	pink_dot: { height: 5, width: 5, borderRadius: 3, backgroundColor: theme.colors.darkPink, marginLeft: 20 },
	purple_dot: { height: 5, width: 5, borderRadius: 3, backgroundColor: theme.colors.darkPurple2 },
	legends_box: { display: 'flex', alignItems: 'center', gap: 5 },
}));

function Revenue({ revenue_data, orders_data, component_loader }) {
	const classes = useStyles();
	const theme = useTheme();

	function nFormatter(num, digits) {
		const lookup = [
			{ value: 1, symbol: '' },
			{ value: 1e3, symbol: 'k' },
			{ value: 1e6, symbol: 'M' },
			{ value: 1e9, symbol: 'G' },
			{ value: 1e12, symbol: 'T' },
			{ value: 1e15, symbol: 'P' },
			{ value: 1e18, symbol: 'E' },
		];
		const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
		var item = lookup
			.slice()
			.reverse()
			.find(function (item) {
				return num >= item.value;
			});
		return item ? (num / item.value).toFixed(2).replace(rx, '$1') + item.symbol : num;
	}

	const data = {
		labels: _.map(revenue_data, (revenue, index) => {
			return moment(revenue._id).date();
		}),
		datasets: [
			{
				data: _.map(revenue_data, (revenue) => {
					return revenue.cod_amount;
				}),
				fill: false,
				borderColor: theme.colors.darkPink,
				tension: 0.5,
				borderWidth: 1,
				pointRadius: 0.5,
			},
			{
				data: _.map(revenue_data, (revenue, index) => {
					return revenue.billed_amount;
				}),
				fill: false,
				borderColor: theme.colors.darkPurple2,
				tension: 0.1,
				borderWidth: 1,
				pointRadius: 0.5,
			},
		],
	};
	const options = {
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: true,
			},
			datalabels: {
				display: false,
			},
		},
		scales: {
			x: {
				grid: {
					display: false,
				},
				ticks: {
					color: theme.colors.lightGrey2,
					font: {
						size: 10,
						family: 'HK Grotesk Medium',
					},
				},
			},
			y: {
				beginAtZero: true,
				ticks: {
					font: {
						size: 8,
						family: 'HK Grotesk Regular',
					},
					callback: nFormatter,
				},
			},
		},
	};
	return (
		<div className={classes.main_container}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 12 }}>
				<Text className={classes.heading_text} bold>
					Revenue
				</Text>
				<div className={classes.legends_box}>
					<div className={classes.purple_dot} />
					<div>
						<Text>Bill Amount</Text>
					</div>
					<div className={classes.pink_dot} />
					<div>
						<Text>Total COD</Text>
					</div>
				</div>
			</div>
			<div className={classes.divider}></div>
			{component_loader ? (
				<Loader visible={component_loader} component={true} />
			) : (
				<>
					<Line data={data} options={options} />
					{_.get(orders_data[0], 'total_order', 0) === 0 && <EmptyState />}
				</>
			)}
		</div>
	);
}

export default Revenue;
