import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
		width: '100%',
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 1000,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		zIndex: 1201,
		overflowY: 'hidden',
		boxShadow: '2px 5px 16px 0px rgba(196, 196, 196, 0.3)',
		display: 'flex',
		flexDirection: 'column',
	},

	header: {
		paddingRight: 30,
		paddingBottom: 5,
		height: 62,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
	},

	footer: {
		height: 100,
		width: 500,
		background: theme.colors.white,
		position: 'fixed',
		bottom: 0,
		right: 0,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 20,
		paddingRight: 20,
		display: 'flex',
		justifyContent: 'space-between',
	},
	footer_content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flex: 1,
	},
	ods_wrap: {
		backgroundColor: theme.colors.primary,
		height: 18.55,
		width: 53,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 2,
		marginLeft: 2,
	},
	allocate_card: {
		padding: 12,
		minHeight: 64,
		borderRadius: 6,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 2px 4px 0px rgba(196, 196, 196, 0.30)`,
		cursor: 'pointer',
	},
	ods_text: {
		fontSize: 12,
		color: theme.colors.white,
		marginLeft: 4,
	},
	icon_wrap: {
		width: 40,
		height: 40,
		borderRadius: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	funds_wrapper: {
		borderRadius: 5,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		padding: '0px 10px',
		marginRight: 8,
		'&:hover': {
			'& .funds-wallet': {
				display: 'none',
			},
			'& .add-purple': {
				display: 'block !important',
			},
		},
	},
	ndr_accordion_details_root: {
		'&.MuiAccordionDetails-root': {
			paddingBottom: 12,
		},
	},
	ndr_accordion_summary_root: {
		'&.MuiAccordionSummary-root': {
			height: 20,
		},
	},

	map_wrap: { padding: 20, flex: 1, marginRight: 10, borderRadius: 20, height: '100%', overflowY: 'hidden' },
	tnc_wrap: {
		borderRadius: 8,
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.lightGrey7}`,
		position: 'absolute',
		bottom: 30,
		right: 540,
		zIndex: 1300,
		width: 430,
		padding: 12,
	},
	tnc_item_wrap: {
		display: 'flex',
		alignItems: 'flex-start',
		marginTop: 8,
	},
	content: {
		display: 'flex',
		flex: 1,
		overflow: 'hidden',
	},
	right_wrap: { flex: 1, overflow: 'hidden' },
	show_hide_text: { fontSize: 12, color: theme.colors.primary, textDecoration: 'underline', cursor: 'pointer' },
	error_footer_wrap: {
		position: 'fixed',
		bottom: 100,
		width: 500,
		right: 0,
		height: 30,
		backgroundColor: theme.colors.lightRed,
		borderTopLeftRadius: 20,
		borderTopRightRadius: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	schedule_wrap: {
		position: 'fixed',
		bottom: 100,
		width: 500,
		right: 0,
		backgroundColor: theme.colors.lightRed,
	},
	scroll_content: {
		overflowY: 'auto',
		paddingBottom: 200,
		height: '100%',
		paddingRight: 30,
		marginTop: 12,
	},
	disabled_card: {
		opacity: 0.3,
		pointerEvents: 'none',
	},
}));

export default useStyles;
