import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { ListItem, makeStyles, Modal, Popover } from '@material-ui/core';
import Text from 'modules/Text';
import ContainedButton from 'modules/ContainedButton';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import OutlinedButton from 'modules/OutlinedButton';
import api_requests from 'resources/api_requests';
import Button from './Button';

const useStyles = makeStyles((theme) => ({
	modal_container: { display: 'flex', outline: 'none' },
	component_container: {
		padding: 24,
		paddingBottom: 0,
		background: 'white',
		width: 600,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	header_title: { fontSize: 18, width: '100%', paddingTop: 5 },
	contained_button_container: { marginTop: 32, marginBottom: 24, display: 'flex', alignSelf: 'flex-end', gap: 10 },
	button: { borderRadius: 30, height: 46, minWidth: `100px !important` },
	rider_preference_wrap: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '80%', marginLeft: '10%', marginTop: 30 },
	lowest_cost_text_wrap: {
		backgroundColor: theme.colors.lightPurple,
		width: '60%',
		marginLeft: '20%',
		marginTop: 12,
		padding: 10,
		display: 'flex',
		gap: 12,
		borderRadius: 4,
		alignItems: 'center',
	},
	add_rider_input_wrap: {
		border: `1px solid ${theme.colors.lightPurple3}`,
		width: '100%',
		padding: '5px 10px',
		height: 26,
		borderRadius: 20,
		display: 'flex',
		position: 'relative',
	},
	add_rider_input: {
		outline: 'none',
		border: 'none',
		marginLeft: 10,
		fontSize: 16,
		width: '100%',
	},
	search_rider_wrap: {
		top: 26,
		position: 'absolute',
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		width: 200,
		zIndex: 1000,
		border: `1px solid ${theme.colors.lightGrey3}`,
		borderRadius: 10,
		background: 'white',
		overflowY: 'auto',
		maxHeight: 200,
	},
	rider_list_item: {
		backgroundColor: theme.colors.lightPurple,
		border: `1px solid ${theme.colors.primary}`,
		padding: 14,
		borderRadius: 6,
	},
	delete_btn: {
		cursor: 'pointer',
	},
}));

function RiderChangeModal({ close, on_confirm, style = {}, details = {} }) {
	const classes = useStyles();

	const [is_lowest_cost, set_is_lowest_cost] = useState(false);
	const [search_rider_id, set_search_rider_id] = useState('');
	const [search_riders, set_search_riders] = useState([]);
	const [selected_riders, set_selected_riders] = useState([]);

	useEffect(() => {
		if (!_.isEmpty(search_rider_id)) {
			api_requests
				.search_rider_without_zone({
					search: search_rider_id,
					is_active: true,
				})
				.then((res) => {
					set_search_riders(res.data);
				});
		} else {
			set_search_riders([]);
		}
	}, [search_rider_id]);

	const handle_select_rider = (rider, is_delete) => {
		const index = _.findIndex(selected_riders, { rider_id: rider.rider_id });
		const _selected_riders = [...selected_riders];

		if (index === -1) {
			_selected_riders.push(rider);
		} else {
			if (is_delete) _selected_riders.splice(index, 1);
		}

		set_selected_riders(_selected_riders);
		set_search_rider_id('');
		set_search_riders([]);
	};

	const handle_confirm = () => {
		on_confirm(selected_riders.length > 0 && !is_lowest_cost ? selected_riders[0] : null);
	};
	const handle_low_cost = () => {
		set_is_lowest_cost((prev) => !prev);
		set_selected_riders([]);
	};
	return (
		<Modal open={true} onClose={close} className={classes.modal_container}>
			<div className={classes.component_container} style={style}>
				<div style={{ display: 'flex' }}>
					<img src={ImageLinks.exclamation_purple_ripple} style={{ width: 48, height: 48 }} alt='' />
					<div style={{ marginLeft: 16, width: '100%' }}>
						<div style={{ display: 'flex' }}>
							<Text semi className={classes.header_title}>
								Change Rider
							</Text>
							<div style={{ cursor: 'pointer' }} onClick={close}>
								<img src={ImageLinks.crossBlack} style={{ width: 16, height: 16, paddingTop: 0 }} alt='' />
							</div>
						</div>
						<Text medium>
							Handover {details.order_count} order from {details.rider_name} ({details.rider_id}). Please select new rider below.
						</Text>
					</div>
				</div>
				<div className={classes.rider_preference_wrap}>
					<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
						Rider Preference:
					</Text>
					<div className='d-flex' style={{ gap: 4 }}>
						<OutlinedButton
							className={classes.button}
							style={{ height: 26, backgroundColor: is_lowest_cost ? theme.colors.primary : theme.colors.white }}
							onClick={() => handle_low_cost()}>
							<Text semi style={{ color: is_lowest_cost ? theme.colors.white : theme.colors.primary, fontSize: 12 }}>
								Lowest Cost
							</Text>
						</OutlinedButton>
						<div className={classes.add_rider_input_wrap}>
							<img src={ImageLinks.search_light_pink} alt='' />
							<input
								disabled={is_lowest_cost || selected_riders.length == 1}
								placeholder='Search Rider'
								className={classes.add_rider_input}
								value={search_rider_id}
								onChange={(e) => set_search_rider_id(e.target.value)}
							/>
							{!_.isEmpty(search_riders) && (
								<div className={classes.search_rider_wrap}>
									{_.map(search_riders, (rider, index) => {
										return (
											<ListItem
												button
												key={`menu${index}`}
												onClick={() => handle_select_rider(rider)}
												tabIndex={index}
												style={{ padding: '12px 20px', ...(index !== 0 && { borderTop: `1px solid ${theme.colors.lightGrey3}` }) }}>
												<Text>{rider.rider_id}</Text>
												<Text style={{ marginLeft: 20 }}>{rider.rider_name}</Text>
											</ListItem>
										);
									})}
								</div>
							)}
						</div>
					</div>
				</div>

				{is_lowest_cost && (
					<div className={classes.lowest_cost_text_wrap}>
						<img src={ImageLinks.inr_circle_light_purple} height={32} width={32} alt='inr' />
						<Text medium style={{ fontSize: 10, color: theme.colors.primary }}>
							<Text bold component={'span'} style={{ fontSize: 10 }}>
								Lowest Cost
							</Text>{' '}
							automatically assign the nearest and most economical rider to fulfill your order
						</Text>
					</div>
				)}

				{_.map(selected_riders, (rider, key) => {
					return (
						<div className='d-flex align-items-center' key={`selected_riders${key}`} style={{ gap: 12, marginTop: 20 }}>
							<ListItem key={`rider${key}`} button className={classes.rider_list_item} onClick={() => {}}>
								<div className={classes.rider_info}>
									<Text bold>
										{_.capitalize(rider.rider_name)} ({rider.rider_id})
									</Text>
								</div>
							</ListItem>

							<div className={classes.delete_btn} onClick={() => handle_select_rider(rider, true)}>
								<img src={ImageLinks.delete} height={20} width={20} alt='' />
							</div>
						</div>
					);
				})}

				<div className={classes.contained_button_container}>
					<Button type='outlined' text='Cancel' size='large' onClick={close} />

					<Button type='round' text='Confirm' size='large' disabled={selected_riders.length == 0 && !is_lowest_cost} onClick={handle_confirm} />
				</div>
			</div>
		</Modal>
	);
}

export default RiderChangeModal;
