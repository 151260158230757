import { LinearProgress, makeStyles, Divider } from '@material-ui/core';
import Lottie from 'react-lottie';
import Animations from 'assets/lottie/Animations';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import IconButton from 'modules/IconButton';
import OutlinedButton from 'modules/OutlinedButton';
import Text from 'modules/Text';
import React, { useMemo, useState } from 'react';
import theme from 'resources/theme';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from 'modules/Button';
import Select from 'modules/Select';
import { toast } from 'react-toastify';

const filter_from_page = ['network_listing', 'wallet', 'routes', 'cod'];
const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 500,
		backgroundColor: theme.colors.white,
		borderTopLeftRadius: 20,
		zIndex: 1199,
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
		paddingBottom: 100,
		padding: 30,
		paddingRight: 60,
		overflow: 'hidden',
	},
	header_wrap: {
		paddingBottom: 4,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	download_btn: {
		width: 200,
		height: 41,
		borderRadius: 30,
		cursor: 'pointer',
		boxShadow: 'none',
		display: 'flex',
	},
	donwload_report_text: { fontSize: 16, color: theme.colors.primary, marginLeft: 6 },
	footer: {
		padding: '10px 60px 25px 60px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: 500,
		background: theme.colors.white,
		position: 'fixed',
		bottom: 0,
		right: 0,
		borderTop: `1px solid ${theme.colors.lightGrey5}`,
	},
	filter_item_wrap: {
		display: 'flex',
		alignItems: 'center',
		minHeight: 36,
		marrginBottom: 20,
		flexDirection: 'column',
		gap: 10,
	},
	dashed_line: {
		width: '100%',
		height: 2,
		marginTop: 20,
		backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='2' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23B29DE2' stroke-width='4' stroke-dasharray='6%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
	},
	report_item_wrap: {
		marginTop: 12,
		borderRadius: 12,
		padding: 12,
		paddingBottom: 0,
	},
	date_filter_wrap: {
		borderRadius: 30,
		height: 40,
		width: 240,
		border: `1px solid ${theme.colors.lightPurple}`,
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingLeft: 14,
		paddingRight: 14,
	},
	date_text: { fontSize: 14, color: theme.colors.primary, marginRight: 20 },
}));

const FilterReportModal = ({
	close,
	wrapper_styles = {},
	report_filters = [],
	report_items = [],
	handle_export = () => {},
	filter_from = '',
	is_report_item_single_select = false,
	skip_date_check = false,
	date_check_array = [],
}) => {
	const initial_params = _.map(
		_.filter(report_items, (item) => {
			return item.default;
		}),
		(item) => {
			return item.category;
		},
	);
	const [selected_params, set_selected_params] = useState(initial_params);
	const [temp_selected_filters, set_temp_selected_filters] = useState({});
	const [range_start, set_range_start] = useState(null);
	const [range_end, set_range_end] = useState(null);
	const [is_loading, set_is_loading] = useState(false);
	const [is_success, set_is_success] = useState(false);
	const [is_error, set_is_error] = useState(false);
	const classes = useStyles();

	const on_range_change = (dates, param) => {
		const [start, end] = dates;
		set_range_start(start);
		set_range_end(end);
		if (filter_from == 'cod') {
			const time_diff = moment(end).diff(moment(start), 'days');
			if (time_diff !== NaN && time_diff > 31) {
				toast.error('Only 30 days filter allowed', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
				return;
			}
		}
		if (start && end) {
			handle_change(param, moment(start).format('YYYY-MM-DD') + ',' + moment(end).format('YYYY-MM-DD'));
		}
	};

	const handle_item_click = (id) => {
		let _selected_params = _.cloneDeep(selected_params);
		const index = _.indexOf(_selected_params, id);

		if (is_report_item_single_select) {
			if (index >= 0) _selected_params = _.cloneDeep(initial_params);
			else _selected_params[0] = id;
		} else {
			if (index >= 0) _selected_params.splice(index, 1);
			_selected_params.push(id);
		}
		set_selected_params(_selected_params);
	};

	const handle_change = (name, value) => {
		set_temp_selected_filters({
			...temp_selected_filters,
			[name]: value,
		});
	};

	const handle_reset = () => {
		set_temp_selected_filters({});
		set_selected_params(initial_params);
		set_range_start(null);
		set_range_end(null);
	};

	const handle_delete = (name) => {
		const _temp_selected_filters = _.cloneDeep(temp_selected_filters);
		delete _temp_selected_filters[name];
		set_temp_selected_filters(_temp_selected_filters);
	};

	const on_report_download = () => {
		set_is_loading(true);
		const categories_str = selected_params.join(',');
		const _selected_filters = _.cloneDeep(temp_selected_filters);
		if (_selected_filters.date) {
			const split_date = _selected_filters.date.split(',');
			const from = split_date[0];
			const to = split_date[1];
			if (filter_from == 'cod') {
				_selected_filters.from = moment(from).startOf('day').toISOString();
				_selected_filters.to = moment(to).endOf('day').toISOString();
			} else {
				_selected_filters.from = from;
				_selected_filters.to = to;
			}
			delete _selected_filters.date;
		}
		if (_selected_filters.entities) {
			_selected_filters.entities = _selected_filters?.entities?.join(',').trim();
		}
		const obj = {
			...(selected_params.length > 0 && { categories: categories_str }),
			..._selected_filters,
		};
		handle_export(obj, (res) => {
			if (res) {
				set_is_loading(false);
				set_is_success(true);
				setTimeout(() => {
					set_is_success(false);
				}, 3000);
			} else {
				set_is_loading(false);
				set_is_error(true);
				setTimeout(() => {
					set_is_error(false);
				}, 3000);
			}
		});
	};

	const is_any_filter_selected = !_.isEmpty(temp_selected_filters);

	const btn_disabled = useMemo(() => {
		let value = true;
		let date_valid = true;
		let category_selected = true;
		let mandatory_filter_selected = true;
		let report_categories = _.map(report_items, (items) => {
			return items.category;
		});
		for (let i = 0; i < report_filters.length; i++) {
			const element = report_filters[i];
			if (element?.is_mandatory && !temp_selected_filters[element.param]) {
				mandatory_filter_selected = false;
				break;
			}
		}
		const is_date_filter = _.filter(report_filters, (item) => item.type === 'date').length > 0;
		const is_default_category = _.filter(report_items, (item) => item.default).length > 0;
		if (is_date_filter && !temp_selected_filters.date && !skip_date_check) {
			date_valid = false;
		}

		if (skip_date_check) {
			let _is_category_included = false;
			_.forEach(date_check_array, (item) => {
				if (_.includes(selected_params, item)) {
					_is_category_included = true;
				}
			});

			if (!temp_selected_filters.date && _is_category_included) {
				date_valid = false;
			}
			if (temp_selected_filters.date && !_is_category_included) {
				date_valid = false;
			}
		}

		if (!is_default_category && selected_params.length === 0) category_selected = false;

		if (category_selected && date_valid && mandatory_filter_selected) value = false;
		return value;
	}, [report_filters, report_items, temp_selected_filters, selected_params]);

	const handle_select_drop_down = (v, filter) => {
		if (filter.multi) {
			let val = [...(temp_selected_filters[filter.param] || [])];
			const index = _.indexOf(val, v);
			if (index !== -1) {
				val.splice(index, 1);
			} else {
				val.push(v);
			}
			if (_.isEmpty(val)) {
				handle_delete(filter);
			} else {
				handle_change(filter.param, val);
			}
			return;
		}
		handle_change(filter.param, v);
	};
	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={classes.container} style={wrapper_styles}>
				<div className={classes.header_wrap}>
					<Text semi style={{ fontSize: 18, color: theme.colors.primary }}>
						Get Report
					</Text>
					{_.includes(filter_from_page, filter_from) ? (
						<div>
							<IconButton onClick={close} style={{ border: 'none' }}>
								<img src={ImageLinks.crossBlack} width={16} height={16} alt='' />
							</IconButton>
						</div>
					) : (
						<Button type='text' size='medium' right_icon={ImageLinks.chevron_right_purple} text='Back' onClick={close} />
					)}
				</div>
				<Divider style={{ marginTop: 0, marginBottom: 10 }} />
				<div style={{ overflowY: 'auto', height: '95vh' }}>
					{report_filters.length > 0 && (
						<div style={{ marginBottom: 8, marginTop: 12 }}>
							<Text semi style={{ fontSize: 18, color: theme.colors.darkGrey2 }}>
								Filter Report by
							</Text>
							<div style={{ marginTop: 16 }}>
								{_.map(report_filters, (item, index) => (
									<div
										className={classes.filter_item_wrap}
										style={{
											...(index !== 0 && { marginTop: 20 }),
										}}
										key={`filter_item_${index}`}>
										<Text semi style={{ width: '100%', color: theme.colors.darkGrey2 }}>
											{item.title}
										</Text>
										<div style={{ width: '100%' }}>
											{item.type === 'date' && item.multi && (
												<div style={{ flex: 1 }}>
													{!(filter_from == 'trace' && _.includes(selected_params, '5mins')) && (
														<DatePicker
															startDate={range_start}
															endDate={range_end}
															onChange={(dates) => on_range_change(dates, item.param)}
															maxDate={
																filter_from != 'fleet'
																	? new Date()
																	: range_start && !range_end
																	? moment(range_start).add(14, 'days').toDate()
																	: moment().add(2, 'years').toDate()
															}
															selectsRange
															customInput={
																<div className={classes.date_filter_wrap}>
																	<Text semi className={classes.date_text}>
																		{temp_selected_filters[item.param]
																			? `${moment(temp_selected_filters[item.param].split(',')[0]).format(
																					'DD-MMM-YY',
																			  )} to ${moment(temp_selected_filters[item.param].split(',')[1]).format(
																					'DD-MMM-YY',
																			  )}`
																			: 'Select Date(s)'}
																	</Text>
																	<img src={ImageLinks.calendar_solid_circle} alt='calendar_filter' />
																</div>
															}
														/>
													)}
													{filter_from == 'trace' && _.includes(selected_params, '5mins') && (
														<DatePicker
															dateFormat='dd-MMM-yyyy'
															selected={
																temp_selected_filters[item.param]
																	? moment(temp_selected_filters[item.param].split(',')[0]).toDate()
																	: new Date()
															}
															onChange={(date) => on_range_change([date, date], item.param)}
															maxDate={new Date()}
															customInput={
																<div className={classes.date_filter_wrap}>
																	<Text semi className={classes.date_text}>
																		{temp_selected_filters[item.param]
																			? `${moment(temp_selected_filters[item.param].split(',')[0]).format('DD-MMM-YY')}`
																			: 'Select Date(s)'}
																	</Text>
																	<img src={ImageLinks.calendar_solid_circle} alt='calendar_filter' />
																</div>
															}
														/>
													)}
												</div>
											)}

											{item.type === 'radio' && (
												<div style={{ flex: 1 }}>
													{_.map(item.values, (value, key2) => {
														const current_val = temp_selected_filters[item.param];
														const isSelected = current_val === value.value || _.includes(current_val, value.value);
														return (
															<OutlinedButton
																key={`option${key2}`}
																onClick={() => {
																	let val = temp_selected_filters[item.param];

																	if (val === value.value) {
																		handle_delete(item.param);
																	} else {
																		handle_change(item.param, value.value);
																	}
																}}
																style={{
																	marginLeft: 10,
																	...(isSelected && { backgroundColor: theme.colors.primary, color: 'white' }),
																}}>
																<Text semi>{value.title}</Text>
															</OutlinedButton>
														);
													})}
												</div>
											)}
											{item.type === 'dropdown' && (
												<div style={{ flex: 1 }}>
													<Select
														value={temp_selected_filters[item.param] || (item.multi ? [] : '')}
														defaultOption={{
															title: `Select`,
															value: '',
														}}
														options={item.values}
														handleChange={(v) => handle_select_drop_down(v, item)}
														containerStyles={{
															height: 36,
															width: 140,
															marginTop: 5,
															border: `1px solid ${theme.colors.lightPurple}`,
															boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
														}}
														text_props={{ semi: true }}
														menu_styles={{
															width: 180,
															height: 'fit-content',
														}}
														multiple={item.multi}
													/>
												</div>
											)}
										</div>
									</div>
								))}
							</div>

							<div className={classes.dashed_line} />
						</div>
					)}

					{_.map(report_items, (item, index) => (
						<div
							key={`report_item_${index}`}
							style={{ marginBottom: 20 }}
							onClick={() => {
								if (!item.default) handle_item_click(item.category);
							}}>
							<div
								className={classes.report_item_wrap}
								style={{
									cursor: item.default ? 'unset' : 'pointer',
									backgroundColor: item.default ? theme.colors.lightGrey7 : theme.colors.white,
									border: `${item.default ? '0px' : '1px'} solid ${
										_.includes(selected_params, item.category) ? theme.colors.lightPurple : theme.colors.lightGrey7
									}`,
								}}>
								<div className='align-items-center' style={{ marginBottom: 12 }}>
									<img
										src={
											item.default
												? ImageLinks.filled_checkbox_disabled
												: _.includes(selected_params, item.category)
												? ImageLinks.filledCheckbox
												: ImageLinks.emptyCheckbox
										}
										width={16}
										height={16}
										alt=''
									/>
									<Text semi style={{ fontSize: 16, marginLeft: 8 }}>
										{item.default ? 'Default items in report' : item.title}
									</Text>
								</div>
								<div style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: 5 }}>
									{item.items_visible &&
										_.map(item.items, (data_item, data_index) => (
											<div
												key={`data_item_${data_index}`}
												style={{
													flex: '50%',
													marginBottom: 12,
												}}>
												<Text medium style={{ fontSize: 12 }}>
													{`${data_index + 1}. ${data_item}`}
												</Text>
											</div>
										))}
								</div>
							</div>
						</div>
					))}
				</div>
				<div className={classes.footer} style={{ ...wrapper_styles, bottom: is_success || is_error ? 15 : 0 }}>
					<div></div>
					{!is_loading && !is_success && !is_error && (
						<div className='d-flex align-items-center' style={{ gap: 10 }}>
							{report_filters.length > 0 && (
								<Button
									type='outlined'
									text='Reset'
									size='large'
									onClick={handle_reset}
									disabled={!is_any_filter_selected}
									left_icon={is_any_filter_selected ? ImageLinks.refresh_single_arrow_purple : ImageLinks.refresh_single_arrow_grey}
								/>
							)}
							<Button
								type='round'
								text='Download'
								size='large'
								onClick={on_report_download}
								disabled={btn_disabled}
								left_icon={btn_disabled ? ImageLinks.download_gray : ImageLinks.download_white}
							/>
						</div>
					)}
					{is_loading && (
						<div className='d-flex justify-content-center align-items-center' style={{ gap: 6 }}>
							<Lottie
								options={{
									loop: true,
									autoplay: true,
									animationData: Animations.circle_loader,
									rendererSettings: {
										preserveAspectRatio: 'xMidYMid slice',
									},
								}}
								height={35}
								width={35}
							/>
							<Text semi style={{ fontSize: 16, color: theme.colors.primary }}>
								Working on it..
							</Text>
						</div>
					)}
					{is_success && (
						<div className='d-flex justify-content-center align-items-center' style={{ gap: 6, position: 'relative', top: 10 }}>
							<img src={ImageLinks.green_tick} width={16} height={16} alt='' />
							<Text semi style={{ fontSize: 16, color: theme.colors.green }}>
								Report Generated
							</Text>
						</div>
					)}
					{is_error && (
						<div className='d-flex justify-content-center align-items-center' style={{ gap: 6, position: 'relative', top: 10 }}>
							<img src={ImageLinks.exclamation_error} width={16} height={16} alt='' />
							<Text semi style={{ fontSize: 16, color: theme.colors.red }}>
								Something went wrong
							</Text>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default FilterReportModal;
