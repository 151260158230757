import { Divider, makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import OrderModalContext from '../OrderModalContext';
import Text from 'modules/Text';
import _ from 'lodash';
import CustomAccordion from 'modules/CustomAccordion';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'absolute',
		top: 0,
		right: '45vw',
		height: '100%',
		width: '25vw',
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 20,
		zIndex: 1201,
		overflowY: 'auto',
		display: 'flex',
		gap: 20,
		overflow: 'scroll',
	},
	product_price_wrap: { flex: 1, backgroundColor: theme.colors.lightPink9, borderRadius: 16, padding: 10 },
	product_tag: {
		borderRadius: 8,
		border: `1px solid ${theme.colors.primary}`,
		background: theme.colors.lightPink4,
		display: 'flex',
		padding: 10,
		gap: 8,
		alignItems: 'center',
	},
	product_card_wrap: {
		border: `1px solid ${theme.colors.lightGrey5}`,
		borderRadius: 8,
		marginTop: 12,
		padding: 12,
		display: 'flex',
		justifyContent: 'space-between',
	},

	total_wrap: {
		padding: 12,
		backgroundColor: theme.colors.lightGrey7,
		borderRadius: 8,
		marginTop: 12,
		display: 'flex',
		justifyContent: 'space-between',
	},
	notes_wrap: {
		borderRadius: 8,
		border: `1px solid ${theme.colors.lightGrey5}`,
		padding: 12,
		backgroundColor: theme.colors.white,
		position: 'absolute',
		width: '100%',
		bottom: 0,
	},
	notes_wrap_open: {
		backgroundColor: theme.colors.white,
		width: '100%',
		bottom: 0,
		height: '40vh',
		overflowY: 'scroll',
		overflowX: 'hidden',
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
			borderRadius: 4,
		},
	},
	product_list_wrap: {
		maxHeight: '60vh',
		overflowY: 'scroll',
		paddingBottom: 40,
	},
}));

function ProductModal() {
	const classes = useStyles();
	const theme = useTheme();
	const { data } = useContext(OrderModalContext);

	const [show_notes, set_show_notes] = useState(false);

	const get_total = (products) => {
		return _.reduce(
			_.map(products, (product) => {
				return product.price * (product.quantity ? product.quantity : 1);
			}),
			(price, price2) => price + price2,
		);
	};

	const get_notes = () => {
		const note_filter = _.filter(data.notes, (note) => {
			return note.name == 'note';
		});
		if (note_filter.length > 0) {
			return note_filter[0].value;
		} else return '-';
	};

	return (
		<div className={clsx(classes.container, 'box-shadow')}>
			<div style={{ flex: 2, position: 'relative' }}>
				<Text semi style={{ fontSize: 18, color: theme.colors.darkGrey2, padding: '12px 0' }}>
					{data.products.length} items
				</Text>
				<Divider />
				{_.map(data.products, (product) => {
					return (
						<div className={classes.product_card_wrap}>
							<div>
								<Text medium style={{ fontSize: 14 }}>
									{product.name}
								</Text>
								<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
									SKU: {product.sku ? product.sku : '-'}
								</Text>
							</div>
							<div className={classes.price_wrap}>
								<Text semi style={{ fontSize: 16 }}>
									&#8377; {product.price * (product.quantity ? product.quantity : 1)}
								</Text>
								<Text medium style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
									{`Price: ₹${product.price} | QTY: ${_.get(product, 'quantity', '1')}`}
								</Text>
							</div>
						</div>
					);
				})}
				<div className={classes.total_wrap}>
					<Text medium style={{ fontSize: 14 }}>
						Order Total
					</Text>
					<div>
						<Text semi style={{ fontSize: 16 }}>
							&#8377; {get_total(data.products)}
						</Text>
					</div>
				</div>
				<div className={classes.notes_wrap}>
					<CustomAccordion
						header_icon_style={{ transform: 'rotate(180deg)' }}
						header={
							<Text semi style={{ fontSize: 14 }}>
								Notes
							</Text>
						}
						content={
							<div className={classes.notes_wrap_open}>
								<Divider style={{ marginTop: 8 }} />
								<Text medium>{get_notes()}</Text>
							</div>
						}
					/>
				</div>
			</div>
		</div>
	);
}

export default ProductModal;
