import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageLinks from 'assets/images/ImageLinks';
import Select from 'modules/Select';
import theme from 'resources/theme';
import { useState } from 'react';
import clsx from 'clsx';
import { NETWORK_TYPE_SEARCH_OPTIONS } from '../helper';

const useStyles = makeStyles((theme) => ({
	searchBoxContainer: {
		height: 36,
		width: 268,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		background: theme.colors.white,
		borderRadius: 46,
		border: `1px solid ${theme.colors.lightPurple6}`,
		marginBottom: 14,
		marginTop: 8,
		padding: `0px 8px`,
	},
	menu_item_class: {
		height: 20,
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
	},
	rider_drop_wrap: { position: 'absolute', width: '95%', maxHeight: 107, marginLeft: 15, marginRight: 12, marginTop: 8 },

	form_wrap: {
		flex: 1,
		marginLeft: 10,
		// width: 200,
	},
}));

const NetworkTypeSearch = ({ handle_filter_search }) => {
	const [search_by, set_search_by] = useState(NETWORK_TYPE_SEARCH_OPTIONS[0].value);
	const [search_text, set_search_text] = useState('');
	const classes = useStyles();

	const handle_search = (e) => {
		set_search_text(e.target.value);
		handle_filter_search(search_by, e.target.value);
	};

	return (
		<div style={{ position: 'relative' }}>
			<div className={classes.searchBoxContainer}>
				<div className='align-items-center' style={{ background: theme.colors.white }}>
					<Select
						value={search_by}
						options={NETWORK_TYPE_SEARCH_OPTIONS}
						handleChange={(val) => set_search_by(val)}
						disablePadding={true}
						containerStyles={{ height: 22, width: 80, background: theme.colors.white }}
						text_styles={{ fontSize: 12, width: '100%' }}
						right_icon={ImageLinks.arrow_up_pink}
						image_styles={{ width: 10 }}
						menu_styles={{ marginTop: 30, paddingTop: 0 }}
						text_props={{ medium: true }}
						menu_item_class={{ className: classes.menu_item_class }}
					/>
					<div className={classes.form_wrap}>
						<input
							placeholder={`Search All Networks`}
							value={search_text}
							style={{ flex: 1, border: 'none', outline: 'none', background: theme.colors.lightGrey7 }}
							onChange={handle_search}
						/>
					</div>
				</div>
			</div>
			<div className={clsx(classes.rider_drop_wrap)}>
				{/* <div className={clsx('d-flex align-items-center justify-content-center', classes.rider_item_wrap)}>
						<img src={ImageLinks.exclamation_error} width={16} height={16} style={{ marginRight: 4 }} />
						<Text semi style={{ fontSize: 14 }}>
							Uh-oh! No rider found
						</Text>
					</div> */}
			</div>
		</div>
	);
};

export default NetworkTypeSearch;
