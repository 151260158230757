import { useTheme } from '@material-ui/core';
import React from 'react';
import Text from './Text';
import _ from 'lodash';
import { ICON_STYLE } from 'views/NetworksFlow/CreateNetwork/helper';

function NetworkAvatar({ configs, height, width, image }) {
	const theme = useTheme();
	const selected_icon_style_val = _.filter(ICON_STYLE, (item) => item.key === configs?.icon_style)[0];
	return configs ? (
		<div
			style={{
				backgroundColor: configs?.avatar_color?.bg ? configs.avatar_color.bg : theme.colors.lightGrey5,
				width: width,
				height: height,
				borderRadius: width / 2,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			{configs?.avatar_style === 'monogram' ? (
				<Text semi style={{ fontSize: Math.floor(height * 0.6), color: theme.colors.white }}>
					{configs?.initials_string}
				</Text>
			) : (
				<img src={selected_icon_style_val?.white_img} width={width / 2} height={height / 2} alt='' />
			)}
		</div>
	) : (
		<img
			src={image}
			style={{
				width: width,
				height: height,
				borderRadius: width / 2,
			}}
			alt=''
		/>
	);
}

export default NetworkAvatar;
