import React, { useMemo, useRef } from 'react';
import theme from 'resources/theme';
import useStyles from './styles';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import { BOTTOM_STATE, TYPES } from './helper';
import _ from 'lodash';
import RiderType from './components/RiderType';
import clsx from 'clsx';
import AllocationFooter from './components/AllocationFooter';
import useAllocationModal from './useAllocationModal';
import NetworkAvatar from 'modules/NetworkAvatar';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import 'leaflet-providers/leaflet-providers.js'; // Import Leaflet Providers
import 'leaflet-routing-machine';
import { MapContainer, TileLayer, Marker, Polyline, useMap, Popup } from 'react-leaflet';
import L from 'leaflet';
import { renderToString } from 'react-dom/server';

const AllocationModal = ({ close, pickup_coords, drop_coords, mcp_ids, is_multiple_network, network_id, on_success }) => {
	const {
		app_configs,
		selected_type,
		biz_available_rider_type,
		network_available_rider_type,
		bottom_bar_state,
		biz_selected_rider,
		network_selected_rider,
		available_rider_list,
		set_network_available_rider_type,
		set_selected_type,
		set_biz_available_rider_type,
		set_biz_selected_rider,
		set_network_selected_rider,
		handle_allocate_rider,
		handle_track,
		handle_try_again,
	} = useAllocationModal(close, pickup_coords, drop_coords, mcp_ids, is_multiple_network, network_id, on_success);

	const classes = useStyles();
	const map_ref = useRef();
	const pick_drop_markers = useMemo(() => {
		const markers = [];
		markers.push({
			position: {
				lat: Number(pickup_coords?.latitude),
				lng: Number(pickup_coords?.longitude),
			},
			image: ImageLinks.rider_pickup,
		});
		_.forEach(drop_coords, (val, index) => {
			markers.push({
				position: { lat: Number(val?.latitude), lng: Number(val?.longitude) },
				image: ImageLinks.marker_purple,
				text: `${index + 1}`,
			});
		});
		return markers;
	}, [drop_coords, pickup_coords]);

	const rider_markers = useMemo(() => {
		const markers = [];
		_.forEach(available_rider_list, (rider, index) => {
			markers.push({
				position: {
					lat: Number(rider.location.latitude),
					lng: Number(rider.location.longitude),
				},
				image: ImageLinks.rider_purple,
				name: rider.rider_name,
				rider_id: rider.rider_id,
				rider: rider,
			});
		});
		return markers;
	}, [available_rider_list]);

	const selected_rider_info = useMemo(() => {
		return _.isEmpty(biz_selected_rider) ? network_selected_rider : biz_selected_rider;
	}, [network_selected_rider, biz_selected_rider]);

	const remove_business_rider = (id) => {
		set_network_selected_rider(null);
		set_biz_selected_rider(null);
		close_pop_up(id);
	};
	const close_pop_up = (id) => {
		let map = map_ref.current;
		map.eachLayer((layer) => {
			if (layer._popup && layer._popup.options.id === id) {
				layer.closePopup();
			}
		});
	};
	const select_business_rider = (rider, id) => {
		if (selected_type === TYPES.BUSINESS) set_biz_selected_rider(rider);
		else set_network_selected_rider(rider);
		close_pop_up(id);
	};

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={classes.container}>
				<div className={classes.content}>
					<div className={clsx(classes.map_wrap)}>
						<div className='map-canvas-wrapper card' style={{ height: '100%', borderRadius: 20 }}>
							<MapContainer
								key={'active_route_map'}
								center={{
									lat: Number(pickup_coords?.latitude),
									lng: Number(pickup_coords?.longitude),
								}}
								ref={map_ref}
								zoom={11}
								scrollWheelZoom={true}
								style={{ zIndex: 1, height: '100%', width: '100%', borderRadius: 20 }}>
								<TileLayer
									attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
									url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
								/>
								{_.map(pick_drop_markers, (marker, key) => {
									return (
										<Marker
											position={marker.position}
											icon={L.divIcon({
												className: 'custom-icon',
												html: renderToString(
													<div
														style={{
															position: 'relative',
															height: 32,
															width: 32,
															transform: 'translate(-6px,-24px)',
														}}>
														<img src={marker.image} width={32} height={32} />
														{marker.text && (
															<Text semi style={{ position: 'absolute', top: 5, color: theme.colors.white, left: 8 }}>
																{marker.text}
															</Text>
														)}
													</div>,
												),
											})}
										/>
									);
								})}
								{_.map(rider_markers, (marker, key) => {
									return (
										<Marker
											position={marker.position}
											icon={L.divIcon({
												className: 'custom-icon',
												html: renderToString(
													<div
														style={{
															border: '4px solid #E4DCF3',
															width: '38px',
															height: '38px',
															borderRadius: '19px',
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
														}}>
														<div
															style={{
																border: '2px solid #492897',
																width: '30px',
																height: '30px',
																borderRadius: '15px',
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
																backgroundColor: '#FFFFFF',
															}}>
															<img src={ImageLinks.rider_purple} width={16} height={16} alt='Rider' />
														</div>
													</div>,
												),
											})}>
											<Popup id={`popup-${marker.rider_id}`} style={{ marginLeft: '20px !important' }}>
												<div className='align-items-center justify-content-between'>
													<div style={{ flex: 0.75, marginRight: 10 }}>
														<Text semi style={{ fontSize: 14 }}>
															{`${marker.name}`}
															<Text component={'span'} semi style={{ fontSize: 14 }}>{`(${marker.rider_id})`}</Text>
														</Text>

														{/* <Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>{`~11 min`}</Text> */}
													</div>
													<div style={{ flex: 0.25 }}>
														<div
															className='cursor-pointer remove_rider_btn'
															style={{
																height: 26,
																width: 66,
																justifyContent: 'center',
																alignItems: 'center',
																display: selected_rider_info?.rider_id === marker.rider_id ? 'flex' : 'none',
															}}
															onClick={() => {
																remove_business_rider(`popup-${marker.rider_id}`);
															}}>
															<Text semi style={{ fontSize: 12, color: theme.colors.darkPurple, textDecoration: 'underline' }}>
																Remove
															</Text>
														</div>

														<div
															className='cursor-pointer save_rider_btn'
															style={{
																height: 26,
																borderRadius: 30,
																width: 66,
																background: theme.colors.primary,
																justifyContent: 'center',
																alignItems: 'center',
																display: selected_rider_info?.rider_id !== marker.rider_id ? 'flex' : 'none',
															}}
															onClick={() => {
																select_business_rider(marker.rider, `popup-${marker.rider_id}`);
															}}>
															<Text semi style={{ fontSize: 12, color: theme.colors.white }}>
																Select
															</Text>
														</div>
													</div>
												</div>
											</Popup>
										</Marker>
									);
								})}
							</MapContainer>
						</div>
					</div>

					<div className={classes.right_wrap}>
						<div className={classes.header}>
							<Text bold style={{ fontSize: 18, color: theme.colors.primary }}>
								{is_multiple_network ? 'Quick Ship multi-network orders' : 'Quick Ship'}
							</Text>
							<div className='cursor-pointer' onClick={close} style={{ marginTop: 3 }}>
								<img src={ImageLinks.crossBlack} width={16} height={16} alt='' />
							</div>
						</div>

						<div
							style={{
								opacity: bottom_bar_state === BOTTOM_STATE.in_progress ? 0.3 : 1,
								pointerEvents: bottom_bar_state === BOTTOM_STATE.in_progress ? 'none' : 'auto',
							}}>
							<div
								onClick={() => set_selected_type(TYPES.BUSINESS)}
								className={classes.allocate_card}
								style={{
									marginTop: 20,
									minHeight: selected_type === TYPES.BUSINESS ? 208 : 64,
									border: `1px solid ${selected_type === TYPES.BUSINESS ? theme.colors.primary : theme.colors.lightGrey7}`,
								}}>
								<div className='align-items-center'>
									<div className={classes.icon_wrap} style={{ border: `0.43px solid ${theme.colors.lightPurple6}` }}>
										<img src={ImageLinks.rider_light_purple3} width={20} height={20} alt='' />
									</div>

									<Text bold style={{ fontSize: 14, marginLeft: 8 }}>
										Business Riders
									</Text>
								</div>
								{selected_type === TYPES.BUSINESS && (
									<RiderType
										network_id={-2}
										available_rider_type={biz_available_rider_type}
										set_available_rider_type={set_biz_available_rider_type}
										selected_rider={biz_selected_rider}
										set_selected_rider={set_biz_selected_rider}
									/>
								)}
							</div>

							{!is_multiple_network && network_id && (
								<div
									onClick={() => set_selected_type(TYPES.NETWORK)}
									className={classes.allocate_card}
									style={{
										marginTop: 12,
										minHeight: selected_type === TYPES.NETWORK ? 208 : 64,
										border: `1px solid ${selected_type === TYPES.NETWORK ? theme.colors.primary : theme.colors.lightGrey7}`,
									}}>
									<div className='align-items-center'>
										<NetworkAvatar
											configs={_.get(app_configs, `network_configs.${network_id}.avatar.configs`, [])}
											height={40}
											width={40}
											image={_.get(app_configs, `network_configs.${network_id}.avatar.image`, [])}
										/>

										<Text bold style={{ fontSize: 14, marginLeft: 8 }}>
											{`${_.get(app_configs, `network_configs.${network_id}.name`, null)} (${network_id})`}
										</Text>
									</div>
									{selected_type === TYPES.NETWORK && (
										<RiderType
											network_id={network_id}
											available_rider_type={network_available_rider_type}
											set_available_rider_type={set_network_available_rider_type}
											selected_rider={network_selected_rider}
											set_selected_rider={set_network_selected_rider}
										/>
									)}
								</div>
							)}
						</div>

						<div className={classes.footer}>
							<div className={classes.footer_content}>
								<AllocationFooter
									total_orders={mcp_ids.length}
									selected_type={selected_type}
									bottom_bar_state={bottom_bar_state}
									handle_allocate_rider={handle_allocate_rider}
									handle_track={handle_track}
									handle_try_again={handle_try_again}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AllocationModal;
