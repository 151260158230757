import _ from 'lodash';
import Socket from './Socket';
import constant from './constant';
import store from '../store';
import { ADD_NEW_ALERT_COUNT, ADD_NEW_NOTIF_COUNT, ADD_USER_TEAMS } from 'actions/actionConstants';
import utils from './utils';

class AppSocket extends Socket {
	constructor(props) {
		super(props);
	}

	initialize({ params = {}, on_socket_connected }) {
		this.connectSocket({
			params,
			on_socket_connected,
		});
	}

	handle_notif_events(data) {
		utils.construct_notif_message(data);
		store.dispatch({ type: ADD_NEW_NOTIF_COUNT });
	}

	handle_alert_events(data) {
		utils.construct_notif_message(data);
		store.dispatch({ type: ADD_NEW_ALERT_COUNT });
	}

	handle_team_events = (data) => {
		const existing_teams = store.getState().app.user_teams || [];
		if (data?.action === 'join') {
			this.join_room(`team_ch_${data.team.id}`);
			const updated_teams = _.cloneDeep(existing_teams);
			updated_teams.push({ team_id: data.team.id, role: '' });
			store.dispatch({
				type: ADD_USER_TEAMS,
				user_teams: updated_teams,
			});
		}
		if (data?.action === 'leave') {
			const user_id = utils._retrieveData(constant.USER_ID);
			const filtered = _.filter(data.user, (item) => item.id == user_id);
			if (filtered.length > 0) {
				this.leave_room(`team_ch_${data.team.id}`);
				const updated_teams = _.cloneDeep(existing_teams);
				const filtered_teams = _.filter(updated_teams, (item) => item.team_id != data.team.id);
				store.dispatch({
					type: ADD_USER_TEAMS,
					user_teams: filtered_teams,
				});
			}
		}
	};

	join_room(room_id) {
		this.emit(constant.SOCKET_EVENTS.JOIN, room_id);
	}

	leave_room(room_id) {
		this.emit(constant.SOCKET_EVENTS.LEAVE, room_id);
	}
}

export default new AppSocket();
