import Text from 'modules/Text';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import cross from 'assets/images/icons/cross.svg';
import { makeStyles, Modal, CircularProgress } from '@material-ui/core';
import ContainedButton from 'modules/ContainedButton';
import ImageLinks from 'assets/images/ImageLinks';

import UndlerlineTextField from 'modules/UndlerlineTextField';
import api_requests from 'resources/api_requests';
import ConfirmClose from './ConfirmClose';
import OutlinedButton from 'modules/OutlinedButton';
import theme from 'resources/theme';
import AddressModalMapComp from 'modules/AddressModalMapComp';
import constant from 'resources/constant';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	container: {
		padding: 20,
		background: 'white',
		width: 650,
		height: 680,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	innerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	editTextContainer: {
		display: 'flex',
		alignItems: 'center',
		height: 30,
	},
	textInput: { fontSize: 20 },
	addressContainer: {
		marginTop: 20,
		marginBottom: 35,
	},
	reachInstructionsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 30,
	},
	containedBtnContainer: {
		margin: '0 auto',
	},
	savedHubDetails: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 16,
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		borderRadius: 8,
		marginBottom: 16,
		border: `1px solid ${theme.colors.primary}`,
	},
	try_again_button_container: {
		background: theme.colors.red,
		border: theme.colors.red,
		color: theme.colors.white,
		width: 132,
		'&:hover': {
			background: 'linear-gradient(180deg, #BC0000 0%, #620101 112.5%) !important',
			boxShadow: '0px 8px 20px 0px rgba(102, 102, 102, 0.15) !important',
			border: 'none !important',
			color: theme.colors.white,
		},
	},
	loading_container_button: {
		background: theme.colors.lightPurple,
		color: theme.colors.primary,
		width: 130,
		'&:hover': {
			background: theme.colors.lightPurple,
			color: theme.colors.primary,
		},
	},
	default_button_container: {
		width: 124,
	},
	info_icon: {
		height: 18,
		background: '#fff',
		borderRadius: '50%',
		marginRight: 4,
	},
}));

const AddressInput = ({
	close,
	on_address_select,
	address_type,
	zone_id,
	title,
	edit_details,
	clear_local_data = () => {},
	is_save_as_required = false,
	show_save_as = true,
	saved_hub_details = null,
	rider_ids,
	saved_hub_flow = false,
	new_location_flow = false,
	show_back_button = false,
	handle_back_nav = () => {},
	track_data_changes = false,
	handle_zone_update = () => {},
	marker_img = ImageLinks.rider_home,
	skip_confirm_close = false,
}) => {
	const [selected_place, set_selected_place] = React.useState(_.get(edit_details, 'selected_place', {}));
	const [instructions, set_instructions] = React.useState(_.get(edit_details, 'instructions', ''));
	const [address_name, set_address_name] = React.useState(_.get(edit_details, 'address_name', ''));
	const [address_line1, set_address_line1] = React.useState(_.get(edit_details, 'address_line1', ''));
	const [button_type, set_button_type] = useState(constant.BTN_STATE.DEFAULT);

	const [confirm_close_modal, set_confirm_close_modal] = React.useState(false);

	const handleClose = () => {
		const all_field_empty = _.isEmpty(address_line1) && _.isEmpty(selected_place);
		if (all_field_empty || btn_disabled || skip_confirm_close) {
			close();
			clear_local_data();
			return;
		}
		set_confirm_close_modal(true);
	};

	const isUpdate = !!edit_details && track_data_changes;

	const handle_address_line = (e) => {
		set_address_line1(e.target.value);
	};

	const handle_confirm_close = () => {
		close();
		clear_local_data();
	};

	const confirm_address = async () => {
		const address_components = selected_place.address_components;

		const city = !_.isEmpty(selected_place?.city)
			? selected_place.city
			: (
					_.find(address_components, (comp) => {
						if (_.includes(comp.types, 'locality')) {
							return true;
						}
					}) || {}
			  ).long_name
			? (
					_.find(address_components, (comp) => {
						if (_.includes(comp.types, 'locality')) {
							return true;
						}
					}) || {}
			  ).long_name
			: 'No City';

		const state = !_.isEmpty(selected_place?.state)
			? selected_place.state
			: (
					_.find(address_components, (comp) => {
						if (_.includes(comp.types, 'administrative_area_level_1')) {
							return true;
						}
					}) || {}
			  ).long_name
			? (
					_.find(address_components, (comp) => {
						if (_.includes(comp.types, 'administrative_area_level_1')) {
							return true;
						}
					}) || {}
			  ).long_name
			: 'No state';
		const pincode = selected_place?.pincode
			? selected_place.pincode.toString()
			: (
					_.find(address_components, (comp) => {
						if (_.includes(comp.types, 'postal_code')) {
							return true;
						}
					}) || {}
			  ).long_name
			? (
					_.find(address_components, (comp) => {
						if (_.includes(comp.types, 'postal_code')) {
							return true;
						}
					}) || {}
			  ).long_name
			: '000000';

		const data = {
			...(!_.isEmpty(address_name) && { label: address_name }),
			address_line1,
			landmark: 'N/A',
			coordinates: {
				latitude: selected_place.geometry.location.lat(),
				longitude: selected_place.geometry.location.lng(),
			},
			is_saved: !_.isEmpty(address_name),
			instructions_to_reach: instructions,
			city,
			state,
			pincode,
			formatted_address: `${_.get(selected_place, 'name', '')} ${selected_place.formatted_address}`.trim(),
		};
		await api_requests.create_address(data).then(async (res) => {
			if (new_location_flow && res.data.address_id !== null) {
				set_button_type(constant.BTN_STATE.LOADING);
				try {
					const locationData = {
						to_address: res.data.address_id,
						rider_ids: [rider_ids],
					};
					const response = await api_requests.admin_location_task(locationData);
					if (response.success) {
						on_address_select();
						close(true);
						set_button_type(constant.BTN_STATE.SUCCESS);
					} else {
						set_button_type(constant.BTN_STATE.FAILED);
					}
				} catch (err) {
					// close(true);
					set_button_type(constant.BTN_STATE.FAILED);
				}
			} else {
				on_address_select(
					{
						id: res.data.address_id,
						selected_place,
						google_maps_address: data.formatted_address,
						latitude: data.coordinates.latitude,
						longitude: data.coordinates.longitude,
						instructions: data.instructions_to_reach,
						...data,
					},
					address_type,
					edit_details,
				);
				close(true);
			}
		});
	};

	const confirm_admin_location = async () => {
		const data = {
			hub_id: saved_hub_details.hub_id,
			rider_ids: [rider_ids],
		};

		set_button_type(constant.BTN_STATE.LOADING);

		try {
			const response = await api_requests.admin_location_task(data);
			if (response.success) {
				on_address_select();
				close(true);
				set_button_type(constant.BTN_STATE.SUCCESS);
			} else {
				set_button_type(constant.BTN_STATE.FAILED);
			}
		} catch (err) {
			// close(true);
			set_button_type(constant.BTN_STATE.FAILED);
		}
	};

	const classes = useStyles();

	const btn_disabled = useMemo(() => {
		let value = true;
		if (!track_data_changes && !_.isEmpty(selected_place.geometry) && !_.isEmpty(address_line1)) {
			value = false;
		}
		// console.log('this is local', selected_place.geometry.location.lat().toFixed(4));
		// console.log('this is from', edit_details.selected_place.geometry.location.lat().toFixed(4));
		if (
			track_data_changes &&
			!_.isEmpty(edit_details) &&
			!_.isEmpty(selected_place.geometry) &&
			!_.isEmpty(address_line1) &&
			(instructions != _.get(edit_details, 'instructions', '') ||
				address_line1 != edit_details.address_line1 ||
				selected_place.geometry.location.lat().toFixed(4) != edit_details.selected_place.geometry.location.lat().toFixed(4) ||
				selected_place.geometry.location.lng().toFixed(4) != edit_details.selected_place.geometry.location.lng().toFixed(4))
		) {
			value = false;
		}
		return value;
	}, [track_data_changes, instructions, address_line1, selected_place, edit_details]);

	return (
		<Modal open={true} onClose={handleClose} style={{ display: 'flex', outline: 'none' }}>
			<div className={classes.container}>
				<div className={classes.innerContainer}>
					<div className={classes.editTextContainer}>
						{show_back_button && (
							<img
								src={ImageLinks.left_arrow}
								alt='back'
								width={20}
								height={20}
								style={{ marginRight: 4 }}
								className='cursor-pointer'
								onClick={handle_back_nav}
							/>
						)}
						<Text bold className={classes.textInput}>
							{title}
						</Text>
					</div>
					<img src={ImageLinks.crossBlack} width={20} height={20} onClick={handleClose} style={{ cursor: 'pointer' }} alt='' />
				</div>
				<AddressModalMapComp
					zone_id={zone_id}
					selected_place={selected_place}
					set_selected_place={set_selected_place}
					custom_marker_location={saved_hub_details?.hub_location}
					saved_hub_flow={saved_hub_flow}
					handle_zone_update={handle_zone_update}
					marker_img={marker_img}
				/>
				{!_.isEmpty(saved_hub_details) && saved_hub_flow ? (
					<div className={classes.savedHubDetails}>
						<div className='d-flex'>
							<div style={{ marginRight: 8, marginTop: 4 }}>
								<img src={ImageLinks.marker_light_pink} alt='location' />
							</div>
							<div style={{ paddingRight: 8 }}>
								<Text bold style={{ fontSize: 16, marginBottom: 8 }}>
									{saved_hub_details.hub_title}
								</Text>
								<Text bold style={{ fontSize: 12, color: theme.colors.lightGrey9 }}>
									{`${
										!_.isEmpty(_.get(saved_hub_details, 'hub_address.address_line1', ''))
											? _.get(saved_hub_details, 'hub_address.address_line1', '')
											: ''
									} ${
										!_.isEmpty(_.get(saved_hub_details, 'hub_address.address_line2', ''))
											? _.get(saved_hub_details, 'hub_address.address_line2', '')
											: ''
									} ${
										!_.isEmpty(_.get(saved_hub_details, 'hub_address.google_maps_address', ''))
											? _.get(saved_hub_details, 'hub_address.google_maps_address', '')
											: ''
									}`}
								</Text>
							</div>
						</div>
						<div>
							<ContainedButton
								show_loader={true}
								disabled
								style={{ background: theme.colors.lightPink, color: theme.colors.darkPurple, boxShadow: 'unset', width: 100 }}>
								<Text semi style={{ fontSize: 12 }}>
									Login Hub
								</Text>
							</ContainedButton>
						</div>
					</div>
				) : (
					<div className={classes.addressContainer}>
						<UndlerlineTextField
							// disabled={_.isEmpty(selected_place.geometry)}
							fullWidth={true}
							label='Address Line*'
							placeholder='Address Line* (Eg: 24 -P&N Pocket, New Delhi)'
							value={address_line1}
							onChange={handle_address_line}
						/>
						<div className={classes.reachInstructionsContainer}>
							<UndlerlineTextField
								style={{ width: '45%' }}
								label='Instructions to Reach'
								placeholder='ex: Take a left'
								value={instructions}
								// disabled={_.isEmpty(selected_place.geometry)}
								onChange={(e) => set_instructions(e.target.value)}
							/>
							{show_save_as && (
								<div style={{ width: '45%', position: 'relative' }}>
									<UndlerlineTextField
										label='Save Address as'
										style={{ width: '100%' }}
										placeholder='ex: Home/Work'
										value={address_name}
										disabled={_.isEmpty(selected_place.geometry)}
										onChange={(e) => set_address_name(e.target.value)}
										required={is_save_as_required}
									/>
									{_.isEmpty(address_name) && (
										<div style={{ position: 'absolute', right: 0, bottom: 5 }}>
											<OutlinedButton
												disabled={_.isEmpty(selected_place)}
												style={{
													height: 20,
													padding: 0,
													width: 60,
													borderColor: theme.colors.lightPurple7,
													color: theme.colors.lightPurple7,
												}}
												onClick={() => set_address_name('Home')}>
												<Text medium style={{ fontSize: 12 }}>
													Home
												</Text>
											</OutlinedButton>
											<OutlinedButton
												disabled={_.isEmpty(selected_place.geometry)}
												style={{
													height: 20,
													padding: 0,
													width: 60,
													marginLeft: 5,
													borderColor: theme.colors.lightPurple7,
													color: theme.colors.lightPurple7,
												}}
												onClick={() => set_address_name('Work')}>
												<Text medium style={{ fontSize: 12 }}>
													Work
												</Text>
											</OutlinedButton>
										</div>
									)}
								</div>
							)}
						</div>
					</div>
				)}
				{!_.isEmpty(saved_hub_details) && saved_hub_flow ? (
					<Button
						type='round'
						size='large'
						text={button_type === constant.BTN_STATE.LOADING ? 'Assigning' : button_type === constant.BTN_STATE.FAILED ? 'Try Again' : 'Confirm'}
						className={`${classes.containedBtnContainer} ${
							button_type === constant.BTN_STATE.LOADING
								? classes.loading_container_button
								: button_type === constant.BTN_STATE.FAILED
								? classes.try_again_button_container
								: classes.default_button_container
						}`}
						onClick={confirm_admin_location}
					/>
				) : (
					// <ContainedButton
					// 	className={`${classes.containedBtnContainer} ${
					// 		button_type === constant.BTN_STATE.LOADING
					// 			? classes.loading_container_button
					// 			: button_type === constant.BTN_STATE.FAILED
					// 			? classes.try_again_button_container
					// 			: classes.default_button_container
					// 	}`}
					// 	show_loader={button_type === constant.BTN_STATE.LOADING ? true : false}
					// 	onClick={confirm_admin_location}>
					// 	{button_type === constant.BTN_STATE.LOADING ? (
					// 		<Text component={'div'} className='align-items-center' semi style={{ fontSize: 18 }}>
					// 			<CircularProgress size={16} style={{ color: theme.colors.primary }} />
					// 			Assigning
					// 		</Text>
					// 	) : button_type === constant.BTN_STATE.FAILED ? (
					// 		<Text component={'div'} className='align-items-center' semi style={{ fontSize: 18 }}>
					// 			<img src={ImageLinks.information_icon} alt='info' className={classes.info_icon} />
					// 			Try Again
					// 		</Text>
					// 	) : (
					// 		<Text semi style={{ fontSize: 18 }}>
					// 			Confirm
					// 		</Text>
					// 	)}
					// </ContainedButton>
					<Button
						type='round'
						size='large'
						text={
							button_type === constant.BTN_STATE.LOADING
								? 'Assigning'
								: button_type === constant.BTN_STATE.FAILED
								? 'Try Again'
								: 'Confirm'
								? new_location_flow
									? 'Confirm'
									: isUpdate
									? 'Update'
									: 'Next'
								: ''
						}
						className={`${classes.containedBtnContainer} ${
							button_type === constant.BTN_STATE.LOADING
								? classes.loading_container_button
								: button_type === constant.BTN_STATE.FAILED
								? classes.try_again_button_container
								: classes.default_button_container
						}`}
						onClick={confirm_address}
						disabled={btn_disabled}
					/>
					// <ContainedButton
					// 	className={`${classes.containedBtnContainer} ${
					// 		button_type === constant.BTN_STATE.LOADING
					// 			? classes.loading_container_button
					// 			: button_type === constant.BTN_STATE.FAILED
					// 			? classes.try_again_button_container
					// 			: classes.default_button_container
					// 	}`}
					// 	show_loader={button_type === constant.BTN_STATE.LOADING ? true : false}
					// 	onClick={confirm_address}
					// 	// disabled={_.isEmpty(address_line1) || _.isEmpty(selected_place.geometry) || (is_save_as_required && _.isEmpty(address_name))}>
					// 	disabled={btn_disabled}>
					// 	{button_type === constant.BTN_STATE.LOADING ? (
					// 		<Text component={'div'} className='align-items-center' semi style={{ fontSize: 18 }}>
					// 			<CircularProgress size={16} style={{ color: theme.colors.primary }} />
					// 			Assigning
					// 		</Text>
					// 	) : button_type === constant.BTN_STATE.FAILED ? (
					// 		<Text component={'div'} className='align-items-center' semi style={{ fontSize: 18 }}>
					// 			<img src={ImageLinks.information_icon} alt='info' className={classes.info_icon} />
					// 			Try Again
					// 		</Text>
					// 	) : (
					// 		<Text semi style={{ fontSize: 18 }}>
					// 			{new_location_flow ? 'Confirm' : isUpdate ? 'Update' : 'Next'}
					// 		</Text>
					// 	)}
					// </ContainedButton>
				)}

				{confirm_close_modal && (
					<ConfirmClose
						headerTitle='Discard Changes'
						title='You have made changes but you have not saved them. Are you sure you want to discard these changes?'
						close={() => set_confirm_close_modal(false)}
						on_confirm={handle_confirm_close}
					/>
				)}
			</div>
		</Modal>
	);
};

export default AddressInput;
