import React, { useEffect, useMemo, useState } from 'react';
import theme from 'resources/theme';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import clsx from 'clsx';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import utils from 'resources/utils';
import constant from 'resources/constant';
import { get_user_details, hide_loader, show_loader, update_biz_list } from 'actions/app';
import AppSocket from 'resources/AppSocket';
import api_requests from 'resources/api_requests';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Button from 'modules/Button';
import { empty_alerts, update_count } from 'actions/alerts';

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	dotsClass: 'slick-dots slick-dots-custom slick-dots-custom-biz',
	customPaging: function (i) {
		return <div style={{ width: 5, height: 5, backgroundColor: '#B1A3D3', borderRadius: 100, margin: '0 auto' }}> </div>;
	},
};

const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 260,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1201,
	},
	container: {
		position: 'absolute',
		top: 0,
		left: 240,
		height: '100%',
		width: 400,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 15,
		zIndex: 1202,
		overflowY: 'auto',
	},
	header_wrap: { borderBottom: `1px solid ${theme.colors.lightGrey5}`, paddingBottom: 13, display: 'flex', justifyContent: 'space-between' },
	business_item_wrap: {
		minHeight: 64,
		borderRadius: 4,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 16,
		width: 350,
		marginBottom: 16,
		cursor: 'pointer',
		backgroundColor: theme.colors.lightGrey7,
		border: `1px solid ${theme.colors.lightPurple}`,
		'&:hover': {
			backgroundColor: theme.colors.lightPurple,
			border: `1px solid ${theme.colors.primary}`,
		},
	},
	page_desc: {
		fontSize: 16,
		color: theme.colors.darkGrey2,
		marginBottom: 20,
		marginTop: 20,
	},

	subtitle_text: { color: theme.colors.primary, fontSize: 14, marginTop: 5, marginBottom: 8 },
	accept_btn: {
		minWidth: 110,
		height: 28,
		borderRadius: 100,
		padding: '6px 8px',
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.green}`,
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.colors.green} !important`,
			'& .green-tick': {
				display: 'none',
			},
			'& .white-tick': {
				display: 'block !important',
			},
			'& $accept_btn_text': {
				color: `${theme.colors.white}`,
			},
		},
	},
	accept_btn_text: {
		fontSize: 12,
		color: theme.colors.green,
		marginLeft: 4,
	},
	invite_info_wrap: {
		height: 65,
		borderRadius: 4,
		backgroundColor: theme.colors.lightPurple9,
		border: `1px solid ${theme.colors.lightPurple}`,
		padding: 16,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 27,
	},
	invite_slider_wrap: {
		position: 'sticky',
		bottom: -40,
		width: '100%',
		height: 140,
		padding: 10,
		backgroundColor: theme.colors.white,
		zIndex: 1201,
	},
	clickable_text: {
		flex: 1,
		maxWidth: '80%',
		display: 'inline-block',
		textDecoration: 'underline',
		cursor: 'pointer',
		color: theme.colors.primary,
		fontSize: 16,
		marginTop: 30,
		'&:hover': {
			color: theme.colors.lightPurple7,
		},
	},
}));

const SwitchBizModal = ({ close, wrapper_styles = {} }) => {
	const [pending_invites, set_pending_invites] = useState([]);
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	const parent_id = utils._retrieveData(constant.PARENT_ID);
	const allowed_enterprises = _.map(JSON.parse(localStorage.getItem(constant.LOCAL_STORAGE_KEYS.AGGREGATOR_INFO)), (item) => {
		return item.business_id.toString();
	});
	const parent_business = _.filter(JSON.parse(localStorage.getItem(constant.LOCAL_STORAGE_KEYS.AGGREGATOR_INFO)), (item) => {
		return item.business_id == parent_id;
	});

	const { business_accounts, user_teams, user_details, selected_business_id, notif_count, alert_count } = useSelector(
		(state) => ({
			business_accounts: state.app.business_accounts,
			user_teams: state.app.user_teams,
			user_details: state.app.user_details,
			selected_business_id: state.app.selected_business_id,
			notif_count: state.alerts.notif_count,
			alert_count: state.alerts.alert_count,
		}),
		shallowEqual,
	);

	useEffect(() => {
		get_invites_list();
	}, []);

	const get_invites_list = async () => {
		try {
			const invites_response = await api_requests.get_pending_invites();
			set_pending_invites(invites_response.data.items);
		} catch (error) {}
	};

	const on_select_business = (business) => {
		const old_data = JSON.parse(localStorage.getItem(constant.LOCAL_STORAGE_KEYS.BIZ_NOTIF_COUNT)) || {};
		if (notif_count === 0 && alert_count === 0) delete old_data[selected_business_id];
		else old_data[selected_business_id] = { notif_count, alert_count };

		const new_biz_data = old_data[business.id];
		localStorage.setItem(constant.LOCAL_STORAGE_KEYS.BIZ_NOTIF_COUNT, JSON.stringify(old_data));

		for (let i = 0; i < user_teams.length; i++) {
			const element = user_teams[i];
			let team_channel = `team_ch_${element.team_id}`;
			AppSocket.leave_room(team_channel);
		}
		utils._storeData(constant.SELECTED_BUSINESS, business);
		dispatch(empty_alerts());
		dispatch(get_user_details(true));
		if (!_.isEmpty(new_biz_data) && (new_biz_data.notif_count > 0 || new_biz_data.alert_count > 0)) {
			setTimeout(() => {
				dispatch(update_count(new_biz_data.notif_count, new_biz_data.alert_count));
			}, 2000);
		}
		close();
	};

	const accept_invite = async (invite) => {
		try {
			dispatch(show_loader());
			const auth_token = utils._retrieveData(constant.AUTH_TOKEN);
			const response = await api_requests.accept_user_invite(invite.id, auth_token);
			if (response.success) {
				dispatch(hide_loader());
				const remaining_invites = _.filter(pending_invites, (item) => item.id != invite.id);
				set_pending_invites(remaining_invites);
				const business_accounts = await api_requests.get_business_accounts();
				dispatch(update_biz_list(business_accounts?.data));
			}
		} catch (error) {
			dispatch(hide_loader());
		}
	};
	const handle_goto_enterprise = () => {
		utils._storeData(constant.SELECTED_BUSINESS, parent_business[0]);
		utils._storeData(constant.PARENT_ID, null);
		utils._storeData(constant.OWNER_ID, null);
		const owner_text = constant.OWNER_TYPE_TEXT[parent_business[0].type_of_owner];
		utils.setRequestAuthHeader(utils._retrieveData(constant.AUTH_TOKEN), { title: owner_text, id: parent_business[0].type_of_owner });
		history.push('/child-business-list');
		window.location.reload();
	};

	const formatted_biz_account = useMemo(() => {
		const value = _.map(business_accounts, (item) => ({ ...item, business_id: item.id }));
		return value;
	}, [business_accounts]);

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={clsx(classes.container, 'box-shadow')} style={{ ...wrapper_styles, display: 'flex', flexDirection: 'column' }}>
				<div style={{ flex: 1, overflowY: 'auto' }}>
					<div className={classes.header_wrap}>
						<Text bold style={{ fontSize: 20, color: theme.colors.primary }}>
							Switch Dashboard
						</Text>
						<div style={{ cursor: 'pointer' }} onClick={close}>
							<img src={ImageLinks.crossBlack} width={16} height={16} alt='' />
						</div>
					</div>

					<Text bold className={classes.page_desc}>
						Please select business
					</Text>

					{_.map(formatted_biz_account, (business, key) => (
						<div key={`business_list_${key}`} onClick={() => on_select_business(business)} className={classes.business_item_wrap} style={{}}>
							<Text medium style={{ fontSize: 20 }}>
								{business.brand_name} ({business.business_id})
							</Text>
							<img src={ImageLinks.arrow_right_purple} width={32} height={32} alt='' />
						</div>
					))}
				</div>
				<div style={{ height: 120 }}>
					{_.includes(allowed_enterprises, utils._retrieveData('parent_id')) && (
						<Button
							type='outlined'
							text='Go to Enterprise'
							size='small'
							onClick={handle_goto_enterprise}
							style={{ marginTop: 30, display: 'inline-block' }}
						/>
					)}
				</div>
				{pending_invites.length > 0 && (
					<div className={classes.invite_slider_wrap}>
						<Slider {...settings} style={{ width: '100%' }}>
							{_.map(pending_invites, (invite, key) => (
								<div key={`invite_${key}`}>
									<Text bold className={classes.subtitle_text}>
										{`${invite.brand_name} `}
										<Text component={'span'} medium style={{ fontSize: 14, color: theme.colors.black }}>
											invited you to join their team.
										</Text>
									</Text>
									<div className={classes.invite_info_wrap}>
										<div>
											<Text semi style={{ fontSize: 14 }}>
												{`${_.get(user_details, 'name', '')} (${_.get(user_details, 'user_id', '')})`}
											</Text>
											<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
												{`${_.get(user_details, 'user_email', '')}| ${_.get(user_details, 'mobile', '')}`}
											</Text>
										</div>

										<div onClick={() => accept_invite(invite)} className={classes.accept_btn}>
											<img className='green-tick' width={12} height={12} src={ImageLinks.tick_circle_green} alt='' />
											<img
												className='white-tick'
												style={{ display: 'none' }}
												width={12}
												height={12}
												src={ImageLinks.tick_circle_white}
												alt=''
											/>
											<Text semi className={classes.accept_btn_text}>
												Accept Invite
											</Text>
										</div>
									</div>
								</div>
							))}
						</Slider>
					</div>
				)}
			</div>
		</>
	);
};

export default SwitchBizModal;
