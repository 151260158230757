import { makeStyles, useTheme } from '@material-ui/core';
import _ from 'lodash';
import Text from 'modules/Text';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
import ImageLinks from 'assets/images/ImageLinks';
import EmptyState from './EmptyState';
import Loader from 'modules/Loader';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const useStyles = makeStyles((theme) => ({
	main_container: {
		backgroundColor: theme.colors.white,
		padding: 12,
		boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
		borderRadius: 12,
		position: 'relative',
	},
	divider: { height: 1, width: '100%', backgroundColor: theme.colors.lightGrey5 },
	flex_box: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10, marginTop: 5 },
	heading_text: { color: theme.colors.darkGrey4, fontSize: 14 },
}));

function DeliveryPartner({ partner_data, component_loader }) {
	const classes = useStyles();
	const theme = useTheme();

	const colors = [
		theme.colors.lightPurple6,
		theme.colors.lightPurple4,
		theme.colors.lightYellow,
		theme.colors.lightGreen4,
		theme.colors.lightPurple7,
		theme.colors.lightOrange,
		theme.colors.cardBackgroundColor4,
		theme.colors.cardBackgroundColor5,
		theme.colors.lightGreen,
		theme.colors.lightRed,
	];
	const data = {
		labels: _.map(
			_.filter(partner_data, (partner) => {
				return !_.isEmpty(partner._id);
			}),
			(partner) => {
				return partner._id === 'captive' ? 'Self-Fulfilled' : partner._id;
			},
		),
		datasets: [
			{
				data: _.map(
					_.filter(partner_data, (partner) => {
						return !_.isEmpty(partner._id);
					}),
					(partner) => {
						return partner.count;
					},
				),
				backgroundColor: colors,
				borderWidth: 1,
			},
		],
	};

	const options = {
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: true,
			},
			datalabels: {
				display: false,
			},
		},
		maintainAspectRatio: true,
	};

	return (
		<div className={classes.main_container}>
			<Text style={{ marginBottom: 12 }} bold className={classes.heading_text}>
				Delivery Partner Split
			</Text>
			<div className={classes.divider}></div>
			<div style={{ width: '70%', margin: 'auto', marginTop: 8 }}>
				<Pie data={data} options={options} />
			</div>
			{component_loader ? (
				<Loader visible={component_loader} component={true} />
			) : (
				<>
					{_.map(
						_.filter(partner_data, (partner) => {
							return !_.isEmpty(partner._id);
						}),
						(partner, index) => {
							return (
								<div className={classes.flex_box} key={`partner_item_${index}`}>
									<div className={classes.flex_box}>
										<div style={{ height: 8, width: 8, backgroundColor: colors[index], borderRadius: 4 }}></div>
										<div>
											{partner._id === 'pidge' ? (
												<img src={ImageLinks.logo} style={{ width: 'auto', maxHeight: 16 }} alt='pidge' />
											) : partner._id === 'dunzo' ? (
												<img src={ImageLinks.dunzo_logo} style={{ width: 'auto', maxHeight: 12 }} alt='dunzo' />
											) : partner._id === 'wefast' ? (
												<img src={ImageLinks.wefast_logo} style={{ width: 'auto', maxHeight: 12 }} alt='wefast' />
											) : partner._id === 'shadowfax' ? (
												<img src={ImageLinks.shadowfax_logo} style={{ width: 'auto', maxHeight: 12 }} alt='shadowfax' />
											) : partner._id === 'loadshare' ? (
												<img src={ImageLinks.loadshare_logo} style={{ width: 'auto', maxHeight: 12 }} alt='loadshare' />
											) : partner._id === 'zomato' ? (
												<img src={ImageLinks.zomato_logo} style={{ width: 'auto', maxHeight: 12 }} alt='zomato' />
											) : partner._id === 'porter' ? (
												<img src={ImageLinks.porter_logo} style={{ width: 'auto', maxHeight: 12 }} alt='porter' />
											) : partner._id === 'captive' ? (
												`Self-Fulfilled`
											) : partner._id ? (
												partner._id
											) : (
												'-'
											)}
										</div>
									</div>
									<div>{partner.count}</div>
								</div>
							);
						},
					)}
					{_.filter(partner_data, (partner) => {
						return !_.isEmpty(partner._id);
					}).length === 0 && <EmptyState />}
				</>
			)}
		</div>
	);
}

export default DeliveryPartner;
