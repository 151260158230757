import React from 'react';
import { Marker } from 'react-leaflet';
import { renderToString } from 'react-dom/server';
import L from 'leaflet';
import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
	icon_outer_circle: {
		width: 38,
		height: 38,
		borderRadius: 19,
	},
	icon_inner_circle: {
		width: 30,
		height: 30,
		borderRadius: 15,
		backgroundColor: theme.colors.white,
	},
}));

const RiderAndTruckMarkers = ({ marker, marker_icon, on_marker_click, inner_circle_color, outer_circle_color, children, dismiss_popup_on_hover = true }) => {
	const classes = useStyles();
	return (
		<Marker
			position={{ lat: marker.rider_location.latitude, lng: marker.rider_location.longitude }}
			data={marker}
			icon={L.divIcon({
				className: 'custom-icon',
				html: renderToString(
					<div
						className={clsx(classes.icon_outer_circle, 'align-items-center justify-content-center')}
						style={{
							border: `4px solid ${outer_circle_color}`,
						}}>
						<div
							className={clsx(classes.icon_inner_circle, 'align-items-center justify-content-center')}
							style={{
								border: `2px solid ${inner_circle_color}`,
							}}>
							<img src={marker_icon} width={16} height={16} alt='' />
						</div>
					</div>,
				),
			})}
			eventHandlers={{
				click: on_marker_click,
				mouseout: (e) => {
					if (dismiss_popup_on_hover) e.target.closePopup();
				},
				mouseover: (e) => {
					e.target.openPopup();
				},
			}}>
			{children}
		</Marker>
	);
};

export default RiderAndTruckMarkers;
