// AccessDeniedPage.js
import React from 'react';
import { makeStyles } from '@material-ui/core';
import history from 'browserHistory';
import Button from 'modules/Button';
import Routes from 'resources/Routes';
import Text from 'modules/Text';
import theme from 'resources/theme';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '100vh',
	},
}));

const PageNotFound = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Text bold style={{ fontSize: 20, color: theme.colors.red }}>
				404 Page not Found
			</Text>
			<Text semi style={{ fontSize: 14, marginBottom: 15 }}>
				Don't panic, maybe the page you are looking for cannot be found or never existed...
			</Text>
			<Button type='round' size='large' text='Back to Home' className={classes.button} onClick={() => history.push(Routes.DASHBOARD.path)}></Button>
		</div>
	);
};

export default PageNotFound;
