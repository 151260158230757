import {
	ADD_NEW_ALERT_COUNT,
	ADD_NEW_NOTIF_COUNT,
	REMOVE_ALERT_COUNT,
	REMOVE_ALL_ALERTS,
	REMOVE_NOTIF_COUNT,
	UPDATE_NOTIF_COUNT,
} from '../actions/actionConstants';

const initialState = {
	notif_count: 0,
	alert_count: 0,
};

export default function get_app_alerts(state = initialState, action) {
	switch (action.type) {
		case ADD_NEW_NOTIF_COUNT:
			return {
				...state,
				notif_count: state.notif_count + 1,
			};

		case ADD_NEW_ALERT_COUNT:
			return {
				...state,
				alert_count: state.alert_count + 1,
			};

		case REMOVE_NOTIF_COUNT:
			return {
				...state,
				notif_count: 0,
			};

		case REMOVE_ALERT_COUNT:
			return {
				...state,
				alert_count: 0,
			};

		case UPDATE_NOTIF_COUNT:
			return {
				...state,
				alert_count: action.alert_count,
				notif_count: action.notif_count,
			};

		case REMOVE_ALL_ALERTS:
			return { ...initialState };

		default:
			return state;
	}
}
