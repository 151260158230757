import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import api_requests from 'resources/api_requests';
import { AVAILABLE_RIDER_TYPE, BOTTOM_STATE, FULFILLMENT_TYPE, TYPES } from './helper';
import constant from 'resources/constant';
import ImageLinks from 'assets/images/ImageLinks';
import { useHistory } from 'react-router-dom';
import Routes from 'resources/Routes';
import { ALL_ORDERS_MENU_ITEMS } from 'views/AllOrders/helper';
import { toggle_support_modal } from 'actions/uiUpdates';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import utils from 'resources/utils';

const initial_biz_details = {
	wallet_balance: null,
	business_details: null,
};

const useCdrAllocationModal = (cdr_ids, cdr_datas = [], close, on_success = () => {}, parent_api_headers = {}) => {
	const { network_configs } = useSelector(
		(state) => ({
			network_configs: state.app.app_configs.network_configs,
		}),
		shallowEqual,
	);
	const history = useHistory();
	const dispatch = useDispatch();
	const [selected_type, set_selected_type] = useState('');
	const [smart_allocate_configs, set_smart_allocate_configs] = useState([]);
	const [auto_manifest_configs, set_auto_manifest_configs] = useState([]);
	const [selected_smart_config, set_selected_smart_config] = useState(null);
	const [selected_auto_config, set_selected_auto_config] = useState(null);
	const [show_smart_config, set_show_smart_config] = useState(false);
	const [show_auto_config, set_show_auto_config] = useState(false);
	const [is_schedule_success, set_is_schedule_success] = useState(false);
	//biz states
	const [has_captive_riders, set_has_captive_riders] = useState(false);
	const [biz_available_rider_type, set_biz_available_rider_type] = useState(AVAILABLE_RIDER_TYPE.LOWEST_COST);
	const [biz_details, set_biz_details] = useState(_.cloneDeep(initial_biz_details));
	const [biz_selected_rider, set_biz_selected_rider] = useState(null);
	const biz_selected_rider_ref = useRef(null);

	// network state
	const [selected_network, set_selected_network] = useState(null);
	const [network_available_rider_type, set_network_available_rider_type] = useState(AVAILABLE_RIDER_TYPE.LOWEST_COST);
	const [show_network_all, set_show_network_all] = useState(false);
	const [network_selected_rider, set_network_selected_rider] = useState(null);
	const network_selected_rider_ref = useRef(null);

	//three pl states
	const [selected_three_pl, set_selected_three_pl] = useState(null);
	const [show_three_pl_all, set_show_three_pl_all] = useState(false);
	const [schedule_slots, set_schedule_slots] = useState([]);
	const [show_schedule, set_show_schedule] = useState(false);
	const [selected_date, set_selected_date] = useState(null);
	const [selected_time, set_selected_time] = useState(null);

	const [show_add_funds, set_show_add_funds] = useState(false);
	const [refresh_details, set_refresh_details] = useState(false);
	const [bottom_bar_state, set_bottom_bar_state] = useState(BOTTOM_STATE.start);
	const [partners_fetched, set_partners_fetched] = useState(false);

	//footer anim state
	const [success_count, set_success_count] = useState(0);
	const [failed_count, set_failed_count] = useState(0);
	const [failed_msg, set_failed_msg] = useState('');
	const network_partners_list = useRef([]);
	const three_pl_partners_list = useRef([]);
	const timer_ref = useRef(null);

	const [available_rider_list, set_available_rider_list] = useState([]);

	useEffect(() => {
		fetch_captive_riders();
		fetch_partners_list();
		fetch_allocation_configs();
	}, []);

	useEffect(() => {
		set_biz_available_rider_type(AVAILABLE_RIDER_TYPE.LOWEST_COST);
		set_network_available_rider_type(AVAILABLE_RIDER_TYPE.LOWEST_COST);
		set_network_selected_rider(null);
		set_biz_selected_rider(null);
		handle_schedule_close();
		if (selected_type !== TYPES.THREE_PL) set_selected_three_pl(null);
		if (selected_type !== TYPES.NETWORK) set_selected_network(null);
	}, [selected_type]);

	useEffect(() => {
		if (_.isEmpty(selected_three_pl)) return;
		if (selected_three_pl.service != ' pidge') handle_schedule_close();
	}, [selected_three_pl]);

	useEffect(() => {
		network_selected_rider_ref.current = network_selected_rider;
	}, [network_selected_rider]);

	useEffect(() => {
		biz_selected_rider_ref.current = biz_selected_rider;
	}, [biz_selected_rider]);

	useEffect(() => {
		api_requests.get_business_details().then((res) => {
			set_biz_details({
				wallet_balance: res.data.balance,
				business_details: res.data,
			});
		});
	}, [refresh_details]);

	useEffect(() => {
		if (
			biz_available_rider_type === AVAILABLE_RIDER_TYPE.AVAILABLE ||
			network_available_rider_type === AVAILABLE_RIDER_TYPE.AVAILABLE ||
			biz_available_rider_type === AVAILABLE_RIDER_TYPE.TOP_RIDER ||
			network_available_rider_type === AVAILABLE_RIDER_TYPE.TOP_RIDER
		) {
			fetch_top_riders();
		} else set_available_rider_list([]);
	}, [biz_available_rider_type, network_available_rider_type]);

	function buildContent(property) {
		const content = document.createElement('div');
		content.innerHTML = `
		  <div style="border:4px solid #E4DCF3;width:38px;height:38px;border-radius:19px;display:flex;justify-content:center;align-items:center"><div style="border:2px solid #492897;width:30px;height:30px;border-radius:15px;display:flex;justify-content:center;align-items:center;background-color:#FFFFFF"><img src=${ImageLinks.rider_purple} width={16} height={16}/></div></div>
		  `;
		return content;
	}

	const fetch_captive_riders = async () => {
		try {
			const response = await api_requests.get_captive_riders(parent_api_headers);
			const rider_exists = _.get(response, 'data', []).length > 0;
			set_has_captive_riders(rider_exists);
			if (rider_exists) set_selected_type(TYPES.BUSINESS);
			else set_selected_type(TYPES.SMART);
		} catch (error) {}
	};

	const fetch_partners_list = async () => {
		try {
			const response = await api_requests.fetch_partners_stream({ ids: cdr_ids.join(',') }, msg_handler, parent_api_headers);
		} catch (error) {
			console.log(error);
		}
	};

	const fetch_allocation_configs = async () => {
		const res = await api_requests.get_smart_allocation_configs(parent_api_headers);
		const _smart = _.filter(res.data, (item) => item.order_process_type === 1);
		const _auto = _.filter(res.data, (item) => item.order_process_type === 2);
		const fromatted_smart = _.map(_smart, (item, index) => ({ ...item, title: `SA-${index + 1}` }));
		const fromatted_auto = _.map(_auto, (item, index) => ({ ...item, title: `AM-${index + 1}` }));
		const default_smart = _.filter(fromatted_smart, (item) => item.default);
		const default_auto = _.filter(fromatted_auto, (item) => item.default);
		set_smart_allocate_configs(fromatted_smart);
		set_auto_manifest_configs(fromatted_auto);
		if (!_.isEmpty(_smart)) set_selected_smart_config(_.isEmpty(default_smart) ? fromatted_smart[0] : default_smart[0]);
		if (!_.isEmpty(_auto)) set_selected_auto_config(_.isEmpty(default_auto) ? fromatted_auto[0] : default_auto[0]);
	};

	const fetch_top_riders = async () => {
		const network_id = selected_type === TYPES.BUSINESS ? -2 : selected_network.network_id;
		let constraints = {};
		if (selected_type !== TYPES.BUSINESS) {
			const selected_network_config = network_configs[selected_network.network_id];
			const network_constraints = _.get(selected_network_config, 'config.constraints', {});
			constraints = {
				...(network_constraints?.permissible_outdated_rider_location_time_window && {
					permissible_outdated_rider_location_time_window: network_constraints.permissible_outdated_rider_location_time_window,
				}),

				...(network_constraints?.last_task_rider_distance_check && {
					last_task_rider_distance_check: network_constraints.last_task_rider_distance_check,
				}),

				...(network_constraints?.last_task_rider_time_check && {
					last_task_rider_time_check: network_constraints.last_task_rider_time_check,
				}),

				...(network_constraints?.pickup_distance && {
					pickup_distance: network_constraints.pickup_distance,
				}),

				...(network_constraints?.rider_limit && {
					rider_limit: network_constraints.rider_limit,
				}),

				...(network_constraints?.eta_min && {
					eta_min: network_constraints.eta_min,
				}),

				...(network_constraints?.eta_max && {
					eta_max: network_constraints.eta_max,
				}),
			};
		}

		const lat = Number(cdr_datas[0]?.sender_detail?.address?.latitude);
		const long = Number(cdr_datas[0]?.sender_detail?.address?.longitude);
		try {
			const response = await api_requests.get_network_top_riders(
				{
					network_id,
					lat,
					long,
					...((biz_available_rider_type === AVAILABLE_RIDER_TYPE.TOP_RIDER || network_available_rider_type === AVAILABLE_RIDER_TYPE.TOP_RIDER) && {
						last_task_rider: true,
						...constraints,
					}),
				},
				parent_api_headers,
			);
			if (response.success && response.data.length > 0) {
				set_available_rider_list(response.data);
				// draw_rider_markers(response.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handle_track = () => {
		history.push(Routes.ALL_ORDERS.path + '?type=' + ALL_ORDERS_MENU_ITEMS.ReadyToShip.id);
	};

	const handle_all_riders_nav = () => {
		history.push(Routes.ALL_RIDERS.path);
	};

	const msg_handler = (data) => {
		const parsed_data = JSON.parse(data);
		const service = _.get(parsed_data, 'service', null);
		const quote = _.get(parsed_data, 'quote', null);
		const schedule = _.get(parsed_data, 'schedule', null);
		const pickup_now = _.get(parsed_data, 'pickup_now', null);
		const manifest = _.get(parsed_data, 'manifest', null);
		if (_.isEmpty(service) || _.isEmpty(quote) || !(schedule || pickup_now || manifest)) return;
		if (network_configs[parsed_data.network_id]?.implementation == 'ppn-private') {
			network_partners_list.current.push(parsed_data);
		} else {
			three_pl_partners_list.current.push(parsed_data);
		}
		set_partners_fetched((prev) => !prev);
	};

	const process_single_id = (id, smart_allocation_id) => {
		return new Promise(async (resolve, reject) => {
			try {
				let payload = {
					ids: [id],
					smart_allocation_id,
					fulfillment_type: selected_time ? FULFILLMENT_TYPE.SYSTEM_SCHEDULE : FULFILLMENT_TYPE.SMART,
					...(selected_time && { schedule: selected_time }),
				};
				api_requests.store_fulfill_order(payload, false, parent_api_headers).then(
					(res) => {
						resolve(1);
					},
					(err) => {
						reject(0);
					},
				);
			} catch (error) {
				reject(0);
			}
		});
	};

	const handle_ods_flow = async (smart_allocation_id) => {
		let s_count = 0;
		let f_count = 0;
		for (let i = 0; i < cdr_ids.length; i++) {
			const id = cdr_ids[i];
			try {
				const result = await process_single_id(id, smart_allocation_id);
				if (result) {
					set_success_count((prev) => prev + 1);
					s_count += 1;
				} else {
					set_failed_count((prev) => prev + 1);
					f_count += 1;
				}
			} catch (error) {
				f_count += 1;
				set_failed_count((prev) => prev + 1);
			}
		}
		if (s_count === 0) set_bottom_bar_state(BOTTOM_STATE.failed);
		else set_bottom_bar_state(BOTTOM_STATE.success);
	};

	const handle_allocate_rider = async (is_manifest) => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.ALLOCATE + `_${selected_type}`);
		set_bottom_bar_state(BOTTOM_STATE.in_progress);
		set_show_schedule(false);
		let payload;
		let invalid_payload = false;
		let smart_allocation_id = null;
		switch (selected_type) {
			case TYPES.SMART:
				try {
					if (_.isEmpty(selected_smart_config)) {
						const res = await api_requests.get_smart_allocation_default({ order_process_type: 1 }, parent_api_headers);
						if (res.data) smart_allocation_id = res.data.id;
						else invalid_payload = true;
					} else smart_allocation_id = selected_smart_config.id;
				} catch (err) {}

				if (!invalid_payload) {
					handle_ods_flow(smart_allocation_id);
					return;
				}
				break;

			case TYPES.AUTO_MANIFEST:
				try {
					if (_.isEmpty(selected_auto_config)) {
						const res = await api_requests.get_smart_allocation_default({ order_process_type: 2 }, parent_api_headers);
						if (res.data) smart_allocation_id = res.data.id;
						else invalid_payload = true;
					} else smart_allocation_id = selected_auto_config.id;
				} catch (err) {}

				if (!invalid_payload) {
					handle_ods_flow(smart_allocation_id);
					return;
				}
				break;

			case TYPES.BUSINESS:
				payload = {
					ids: cdr_ids,
					service: 'captive',
					network_id: -2,
					pickup_now: selected_time ? false : true,
					fulfillment_type: selected_time ? FULFILLMENT_TYPE.SYSTEM_SCHEDULE : FULFILLMENT_TYPE.IMMEDIATE,
					allocation_param: {
						cost:
							biz_available_rider_type === AVAILABLE_RIDER_TYPE.BORADCAST
								? 4
								: biz_available_rider_type === AVAILABLE_RIDER_TYPE.AVAILABLE
								? 3
								: biz_available_rider_type === AVAILABLE_RIDER_TYPE.TOP_RIDER
								? 5
								: 1,
						...(biz_selected_rider && { rider_id: biz_selected_rider?.rider_id }),
					},
					...(selected_time && { schedule: selected_time }),
				};
				break;

			case TYPES.NETWORK: // will be added later when allocations options will be added
				payload = {
					ids: cdr_ids,
					service: selected_network.service,
					pickup_now: is_manifest ? false : selected_network.pickup_now,
					token: selected_network.token,
					network_id: selected_network.network_id,
					fulfillment_type: selected_network.schedule && selected_time ? FULFILLMENT_TYPE.SYSTEM_SCHEDULE : FULFILLMENT_TYPE.IMMEDIATE,
					...(is_manifest && { manifest: true }),
					...(selected_network.schedule && selected_time && { schedule: selected_time }),
					...(selected_network.can_choose_algo && {
						allocation_param: {
							cost:
								network_available_rider_type === AVAILABLE_RIDER_TYPE.BORADCAST
									? 4
									: network_available_rider_type === AVAILABLE_RIDER_TYPE.AVAILABLE
									? 3
									: network_available_rider_type === AVAILABLE_RIDER_TYPE.TOP_RIDER
									? 5
									: 1,
							...(network_selected_rider && { rider_id: network_selected_rider?.rider_id }),
						},
					}),
				};
				break;

			default:
				payload = {
					ids: cdr_ids,
					service: selected_three_pl.service,
					pickup_now:
						selected_type === TYPES.THREE_PL && selected_three_pl.schedule && selected_time
							? false
							: is_manifest
							? false
							: selected_three_pl.pickup_now,
					token: selected_three_pl.token,
					network_id: selected_three_pl.network_id,
					fulfillment_type:
						selected_type === TYPES.THREE_PL && selected_three_pl.schedule && selected_time
							? FULFILLMENT_TYPE.REMOTE_SCHEDULE
							: FULFILLMENT_TYPE.IMMEDIATE,
					...(is_manifest && { manifest: true }),
					...(selected_type === TYPES.THREE_PL && selected_three_pl.schedule && selected_time && { schedule: selected_time }),
				};
				break;
		}
		timer_ref.current = setTimeout(() => {
			if (invalid_payload) {
				set_bottom_bar_state(BOTTOM_STATE.failed);
				set_failed_msg('No default config exists');
				return;
			}
			api_requests.store_fulfill_order(payload, false, parent_api_headers).then(
				(res) => {
					if (
						(selected_type === TYPES.THREE_PL && selected_three_pl.schedule && selected_time) ||
						(selected_type === TYPES.NETWORK && selected_network.schedule && selected_time) ||
						((selected_type === TYPES.BUSINESS || selected_type === TYPES.SMART || selected_type === TYPES.AUTO_MANIFEST) && selected_time)
					) {
						set_is_schedule_success(true);
					}

					handle_schedule_close();
					set_bottom_bar_state(BOTTOM_STATE.success);
					on_success();
				},
				(err) => {
					const err_obj = _.get(err, 'response.data.error', {});
					if (err_obj?.code === 'wallet.insufficient_balance') set_failed_msg(err_obj?.message);
					set_bottom_bar_state(BOTTOM_STATE.failed);
					handle_schedule_close();
				},
			);
		}, 4000);
	};

	const handle_network_discover = () => {
		window.open('https://docs.google.com/forms/d/e/1FAIpQLSf-hLvM4vVZUQ39tHucBpaTD4ZPf6FgbBk9L-ELWIQh8UDzQg/viewform', '_blank');
		return;
		const support_data = {
			show_modal: true,
			mode: 'network_discover',
		};
		dispatch(toggle_support_modal(support_data));
	};

	const handle_schedule_get_slots = async () => {
		try {
			const pass_network_id = selected_type === TYPES.BUSINESS || selected_type === TYPES.THREE_PL || selected_type === TYPES.NETWORK;

			let service_name = 'system';
			if (selected_type === TYPES.THREE_PL) service_name = selected_three_pl.service;

			const response = await api_requests.get_schedule_slots_fulfillment_service(
				service_name,
				{
					...(pass_network_id && {
						network_id:
							selected_type === TYPES.BUSINESS
								? -2
								: selected_type === TYPES.THREE_PL
								? selected_three_pl.network_id
								: selected_network.network_id,
					}),
					ids: cdr_ids.join(','),
				},
				parent_api_headers,
			);
			set_schedule_slots(response.data.items);
			if (response.data.items.length > 0 && selected_type === TYPES.BUSINESS) {
				set_selected_date(response.data.items[0].date);
				set_selected_time(_.get(response.data.items, '0.slots.0.value'));
			}
			set_show_schedule(true);
		} catch (error) {
			console.log(error);
		}
	};

	const handle_schedule_close = () => {
		set_schedule_slots([]);
		set_show_schedule(false);
		set_selected_date(null);
		set_selected_time(null);
	};

	const handle_try_again = () => {
		set_schedule_slots([]);
		set_show_schedule(false);
		set_selected_date(null);
		set_selected_time(null);
		set_bottom_bar_state(BOTTOM_STATE.start);
		set_success_count(0);
		set_failed_count(0);
		set_failed_msg('');
	};

	return {
		selected_type,
		set_selected_type,
		has_captive_riders,
		biz_available_rider_type,
		set_biz_available_rider_type,
		network_partners_list: network_partners_list.current,
		network_available_rider_type,
		set_network_available_rider_type,
		selected_network,
		set_selected_network,
		show_network_all,
		set_show_network_all,
		three_pl_partners_list: three_pl_partners_list.current,
		selected_three_pl,
		set_selected_three_pl,
		show_three_pl_all,
		set_show_three_pl_all,
		show_add_funds,
		set_show_add_funds,
		biz_details,
		set_refresh_details,
		bottom_bar_state,
		set_bottom_bar_state,
		biz_selected_rider,
		set_biz_selected_rider,
		network_selected_rider,
		set_network_selected_rider,
		handle_allocate_rider,
		fetch_top_riders,
		handle_track,
		handle_all_riders_nav,
		success_count,
		failed_count,
		partners_fetched,
		handle_network_discover,
		handle_schedule_get_slots,
		schedule_slots,
		show_schedule,
		selected_date,
		set_selected_date,
		selected_time,
		set_selected_time,
		handle_schedule_close,
		failed_msg,
		handle_try_again,
		smart_allocate_configs,
		auto_manifest_configs,
		show_smart_config,
		set_show_smart_config,
		show_auto_config,
		set_show_auto_config,
		selected_smart_config,
		selected_auto_config,
		set_selected_smart_config,
		set_selected_auto_config,
		is_schedule_success,
		available_rider_list,
	};
};

export default useCdrAllocationModal;
