import React, { useMemo, useRef } from 'react';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import 'leaflet-providers/leaflet-providers.js'; // Import Leaflet Providers
import 'leaflet-routing-machine';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import { renderToString } from 'react-dom/server';
import _, { transform } from 'lodash';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import theme from 'resources/theme';
import { TYPES } from '../helper';

function CdrAllocationMap({
	cdr_datas,
	available_rider_list,
	biz_selected_rider,
	network_selected_rider,
	set_biz_selected_rider,
	set_network_selected_rider,
	selected_type,
}) {
	const map_ref = useRef();
	const pick_drop_markers = useMemo(() => {
		const markers = [];
		markers.push({
			position: {
				lat: Number(cdr_datas[0]?.sender_detail?.address?.latitude),
				lng: Number(cdr_datas[0]?.sender_detail?.address?.longitude),
			},
			image: ImageLinks.rider_pickup,
		});
		_.forEach(cdr_datas, (val, index) => {
			markers.push({
				position: { lat: Number(val?.customer_detail?.address?.latitude), lng: Number(val?.customer_detail?.address?.longitude) },
				image: ImageLinks.marker_purple,
				text: `${index + 1}`,
			});
		});

		return markers;
	}, [cdr_datas]);

	const rider_markers = useMemo(() => {
		const markers = [];
		_.forEach(available_rider_list, (rider, index) => {
			markers.push({
				position: {
					lat: Number(rider.location.latitude),
					lng: Number(rider.location.longitude),
				},
				image: ImageLinks.rider_purple,
				name: rider.rider_name,
				rider_id: rider.rider_id,
				rider: rider,
			});
		});
		return markers;
	}, [available_rider_list]);

	function ChangeView() {
		const map = useMap();
		let bounds = L.latLngBounds();
		let pickup_coord = L.latLng(Number(cdr_datas[0]?.sender_detail?.address?.latitude), Number(cdr_datas[0]?.sender_detail?.address?.longitude));
		bounds.extend(pickup_coord);
		cdr_datas.forEach((val) => {
			let drop_coord = L.latLng(Number(val?.customer_detail?.address?.latitude), Number(val?.customer_detail?.address?.longitude));
			bounds.extend(drop_coord);
		});
		available_rider_list.forEach((rider) => {
			let rider_coord = L.latLng(Number(rider.location.latitude), Number(rider.location.longitude));
			bounds.extend(rider_coord);
		});
		map.fitBounds(bounds);
		return null;
	}

	const selected_rider_info = useMemo(() => {
		return _.isEmpty(biz_selected_rider) ? network_selected_rider : biz_selected_rider;
	}, [network_selected_rider, biz_selected_rider]);

	const close_pop_up = (id) => {
		let map = map_ref.current;
		map.eachLayer((layer) => {
			if (layer._popup && layer._popup.options.id === id) {
				layer.closePopup();
			}
		});
	};

	const select_business_rider = (rider, id) => {
		if (selected_type === TYPES.BUSINESS) set_biz_selected_rider(rider);
		else set_network_selected_rider(rider);
		close_pop_up(id);
	};
	const remove_business_rider = (id) => {
		set_network_selected_rider(null);
		set_biz_selected_rider(null);
		close_pop_up(id);
	};

	return (
		<div style={{ height: '100%' }}>
			<MapContainer
				key={'active_route_map'}
				center={{
					lat: Number(cdr_datas[0]?.sender_detail?.address?.latitude),
					lng: Number(cdr_datas[0]?.sender_detail?.address?.longitude),
				}}
				zoom={11}
				scrollWheelZoom={true}
				style={{ zIndex: 1 }}
				ref={map_ref}>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
				/>
				<ChangeView />
				{_.map(pick_drop_markers, (marker, key) => {
					return (
						<Marker
							position={marker.position}
							icon={L.divIcon({
								className: 'custom-icon',
								html: renderToString(
									<div
										style={{
											position: 'relative',
											height: 32,
											width: 32,
											transform: 'translate(-6px,-24px)',
										}}>
										<img src={marker.image} width={32} height={32} alt='marker' />
										{marker.text && (
											<Text semi style={{ position: 'absolute', top: 5, color: theme.colors.white, left: 8 }}>
												{marker.text}
											</Text>
										)}
									</div>,
								),
							})}
						/>
					);
				})}
				{_.map(rider_markers, (marker, key) => {
					return (
						<Marker
							position={marker.position}
							icon={L.divIcon({
								className: 'custom-icon',
								html: renderToString(
									<div
										style={{
											border: '4px solid #E4DCF3',
											width: '38px',
											height: '38px',
											borderRadius: '19px',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}>
										<div
											style={{
												border: '2px solid #492897',
												width: '30px',
												height: '30px',
												borderRadius: '15px',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												backgroundColor: '#FFFFFF',
											}}>
											<img src={ImageLinks.rider_purple} width={16} height={16} alt='Rider' />
										</div>
									</div>,
								),
							})}>
							<Popup id={`popup-${marker.rider_id}`} style={{ marginLeft: '20px !important' }}>
								<div className='align-items-center justify-content-between'>
									<div style={{ flex: 0.75, marginRight: 10 }}>
										<Text semi style={{ fontSize: 14 }}>
											{`${marker.name}`}
											<Text component={'span'} semi style={{ fontSize: 14 }}>{`(${marker.rider_id})`}</Text>
										</Text>

										{/* <Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>{`~11 min`}</Text> */}
									</div>
									<div style={{ flex: 0.25 }}>
										<div
											className='cursor-pointer remove_rider_btn'
											style={{
												height: 26,
												width: 66,
												justifyContent: 'center',
												alignItems: 'center',
												display: selected_rider_info?.rider_id === marker.rider_id ? 'flex' : 'none',
											}}
											onClick={() => {
												remove_business_rider(`popup-${marker.rider_id}`);
											}}>
											<Text semi style={{ fontSize: 12, color: theme.colors.darkPurple, textDecoration: 'underline' }}>
												Remove
											</Text>
										</div>

										<div
											className='cursor-pointer save_rider_btn'
											style={{
												height: 26,
												borderRadius: 30,
												width: 66,
												background: theme.colors.primary,
												justifyContent: 'center',
												alignItems: 'center',
												display: selected_rider_info?.rider_id !== marker.rider_id ? 'flex' : 'none',
											}}
											onClick={() => {
												select_business_rider(marker.rider, `popup-${marker.rider_id}`);
											}}>
											<Text semi style={{ fontSize: 12, color: theme.colors.white }}>
												Select
											</Text>
										</div>
									</div>
								</div>
							</Popup>
						</Marker>
					);
				})}
			</MapContainer>
		</div>
	);
}

export default CdrAllocationMap;
