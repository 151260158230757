import Text from 'modules/Text';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import cross from 'assets/images/icons/cross.svg';
import { makeStyles, Modal, Grid } from '@material-ui/core';
import ContainedButton from 'modules/ContainedButton';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import theme from 'resources/theme';
import ConfirmClose from 'modules/modals/ConfirmClose';
import constant from 'resources/constant';
import moment from 'moment';
import ImageLinks from 'assets/images/ImageLinks';
import Button from 'modules/Button';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import { Icon } from 'leaflet';
import L from 'leaflet';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: 20,
		background: theme.colors.white,
		width: 580,
		height: 600,
		margin: 'auto auto',
		borderRadius: 20,
		outline: 'none',
	},
	address_container: {
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		borderRadius: 12,
		marginTop: 16,
	},
	map_canvas_wrap: { height: 140, borderRadius: 12 },
	address_wrap: { padding: 8 },
	addr_label: { color: theme.colors.darkGrey2, fontSize: 10, marginBottom: 4 },
	addr_text: { fontSize: 14, lineHeight: '22px' },
	input_wrap: { display: 'flex', flexDirection: 'column', marginBottom: 35 },
	input_row_wrap: { marginTop: 18 },
	width45: { width: '45%' },
	address_and_button_container: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '94%' },
	cta_btn: {
		padding: '8px 32px',
		borderRadius: 30,
		margin: '0 auto',
		'&:disabled': {
			color: theme.colors.darkGrey2,
		},
	},
}));

const DeliveryDetailsModal = ({
	close,
	zone_id,
	handle_drop_details,
	drop_address,
	edit_details,
	clear_local_data,
	local_receiver_name,
	local_receiver_contact,
	title = 'Delivery Details',
	show_back_button = true,
	action_on_back_button = () => {},
	track_data_changes = false,
	edit_fields = [],
	lock_fields = false,
}) => {
	const [receiver_name, set_receiver_name] = React.useState(_.get(edit_details, 'receiver_name') || local_receiver_name || '');
	const [receiver_contact, set_receiver_contact] = React.useState(_.get(edit_details, 'receiver_contact') || local_receiver_contact || '');
	const [reference_id, set_reference_id] = React.useState(_.get(edit_details, 'reference_id', ''));
	const [cod_amount, set_cod_amount] = React.useState(_.get(edit_details, 'cod_amount', ''));
	const [bill_amount, set_bill_amount] = useState(_.get(edit_details, 'bill_amount', ''));
	const [delivery_date, set_delivery_date] = React.useState(_.get(edit_details, 'delivery_date', ''));
	const [delivery_slot, set_delivery_slot] = React.useState(_.get(edit_details, 'delivery_slot', ''));

	const [confirm_close_modal, set_confirm_close_modal] = React.useState(false);
	const [slot_valid, set_slot_valid] = React.useState(true);
	const map = React.useRef(null);

	const classes = useStyles();

	const handleClose = () => {
		if (btn_disabled && track_data_changes) close();
		else set_confirm_close_modal(true);
	};

	const initialize = () => {
		if (map.current) {
			map.current.setView([Number(drop_address.coordinates.latitude), Number(drop_address.coordinates.longitude)], 13);
			const drop_icon = new Icon({ iconUrl: ImageLinks.marker_down_arrow, iconSize: [30, 30] });
			L.marker([Number(drop_address.coordinates.latitude), Number(drop_address.coordinates.longitude)], { icon: drop_icon }).addTo(map.current);
		}
	};

	const confirm_address = () => {
		let formatted_date = delivery_date;
		if (delivery_date) {
			formatted_date = moment(delivery_date).format('YYYY-MM-DD');
		}
		handle_drop_details(edit_details, receiver_name, receiver_contact, cod_amount, bill_amount, reference_id, formatted_date, delivery_slot);
		close();
	};

	const handle_confirm_close = () => {
		close();
		clear_local_data();
	};

	const handle_slot_change = (e) => {
		set_delivery_slot(e.target.value);
		const value = e.target.value;
		if (_.isEmpty(value)) {
			set_slot_valid(true);
			return;
		}
		let regex = new RegExp(/^(([01]\d|2[0-3]):([0-5]\d))-(([01]\d|2[0-3]):([0-5]\d))$/);
		if (regex.test(value)) set_slot_valid(true);
		else set_slot_valid(false);
	};

	useEffect(() => {
		setTimeout(() => {
			initialize();
		}, 800);
	}, []);

	const btn_disabled = useMemo(() => {
		let value = true;

		if (!track_data_changes && !_.isEmpty(receiver_name) && receiver_contact.length == 10 && slot_valid) {
			value = false;
		}

		if (
			track_data_changes &&
			!_.isEmpty(edit_details) &&
			!_.isEmpty(receiver_name) &&
			receiver_contact.length == 10 &&
			slot_valid &&
			(receiver_name != edit_details.receiver_name ||
				receiver_contact != edit_details.receiver_contact ||
				reference_id != edit_details.reference_id ||
				cod_amount != edit_details.cod_amount ||
				bill_amount != edit_details.bill_amount ||
				delivery_date != edit_details.delivery_date ||
				delivery_slot != edit_details.delivery_slot)
		) {
			value = false;
		}
		return value;
	}, [track_data_changes, edit_details, slot_valid, receiver_name, receiver_contact, reference_id, cod_amount, bill_amount, delivery_date, delivery_slot]);

	return (
		<Modal open={true} onClose={handleClose} style={{ display: 'flex', outline: 'none' }}>
			<div className={classes.wrapper}>
				<div className='justify-content-between align-items-center'>
					<div className='align-items-center'>
						{show_back_button && (
							<img
								src={ImageLinks.left_arrow}
								alt='back'
								width={20}
								height={20}
								style={{ marginRight: 4 }}
								className='cursor-pointer'
								onClick={action_on_back_button}
							/>
						)}

						<Text bold style={{ fontSize: 20 }}>
							{title}
						</Text>
					</div>
					<img src={ImageLinks.crossBlack} width={20} height={20} onClick={handleClose} style={{ cursor: 'pointer' }} />
				</div>
				<div className={classes.address_and_button_container}>
					<div>
						<Grid container spacing={2} className={classes.address_container}>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<div className={classes.address_wrap}>
									<Text medium className={classes.addr_label}>
										Address
									</Text>
									<Text semi className={classes.addr_text}>
										{drop_address.address_line1}
									</Text>
									<Text medium className={classes.addr_label} style={{ marginTop: 10 }}>
										Instructions to Reach
									</Text>
									<Text semi className={classes.addr_text}>
										{drop_address.instructions_to_reach || '-'}
									</Text>
								</div>
							</Grid>

							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<div className={classes.map_canvas_wrap}>
									<MapContainer
										ref={map}
										center={{ lat: Number(28.56434), lng: Number(77.19591) }}
										zoom={16}
										scrollWheelZoom={true}
										zoomControl={false}>
										<TileLayer
											attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
											url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
										/>
										<ZoomControl position='bottomright' />
									</MapContainer>
								</div>
							</Grid>
						</Grid>
						<Grid container className={classes.input_row_wrap} spacing={2}>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<UndlerlineTextField
									disabled={lock_fields && !_.includes(edit_fields, constant.DELIVERY_DETAIL_MODAL_FILEDS.name)}
									label='Name of Receiver*'
									className='w-100'
									value={receiver_name}
									onChange={(e) => set_receiver_name(e.target.value)}
								/>
							</Grid>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<UndlerlineTextField
									disabled={lock_fields && !_.includes(edit_fields, constant.DELIVERY_DETAIL_MODAL_FILEDS.mobile)}
									className='w-100'
									label='Contact of Receiver*'
									type='number'
									value={receiver_contact}
									onChange={(e) => set_receiver_contact(e.target.value)}
								/>
							</Grid>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<UndlerlineTextField
									disabled={lock_fields && !_.includes(edit_fields, constant.DELIVERY_DETAIL_MODAL_FILEDS.ref_id)}
									className={'w-100'}
									label='Reference ID'
									value={reference_id}
									onChange={(e) => set_reference_id(e.target.value)}
								/>
							</Grid>
							<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
								<UndlerlineTextField
									disabled={lock_fields && !_.includes(edit_fields, constant.DELIVERY_DETAIL_MODAL_FILEDS.cod)}
									className={'w-100'}
									label={
										<div>
											<img src={ImageLinks.inr_filled} width={12} height={12} alt='inr' style={{ marginRight: 4 }} />
											COD Amount
										</div>
									}
									type='number'
									value={cod_amount}
									onChange={(e) => Number(set_cod_amount(e.target.value))}
								/>
							</Grid>
							<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
								<UndlerlineTextField
									disabled={lock_fields && !_.includes(edit_fields, constant.DELIVERY_DETAIL_MODAL_FILEDS.bill)}
									className={'w-100'}
									label='Bill Amount'
									type='number'
									value={bill_amount}
									onChange={(e) => Number(set_bill_amount(e.target.value))}
								/>
							</Grid>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<DatePicker
									disabled={lock_fields && !_.includes(edit_fields, constant.DELIVERY_DETAIL_MODAL_FILEDS.date)}
									dateFormat='yyyy-MM-dd'
									selected={!moment(delivery_date, 'YYYY-MM-DD', true).isValid() ? '' : new Date(delivery_date)}
									onChange={(date) => {
										set_delivery_date(date);
									}}
									customInput={
										<UndlerlineTextField
											style={{ width: '100%' }}
											label='Delivery Date'
											value={delivery_date}
											disabled={lock_fields && !_.includes(edit_fields, constant.DELIVERY_DETAIL_MODAL_FILEDS.date)}
										/>
									}
								/>
							</Grid>
							<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
								<UndlerlineTextField
									disabled={lock_fields && !_.includes(edit_fields, constant.DELIVERY_DETAIL_MODAL_FILEDS.slot)}
									className={'w-100'}
									label='Delivery Slot (e.g. 14:00-18:00)'
									value={delivery_slot}
									onChange={handle_slot_change}
								/>
							</Grid>
						</Grid>
					</div>
					<div className='d-flex justify-content-center align-items-center text-align-center'>
						<Button
							type='round'
							text={!_.isEmpty(edit_details) && track_data_changes ? 'Update' : 'Next'}
							size='large'
							onClick={confirm_address}
							disabled={btn_disabled}
							style={{ width: 110 }}
						/>
					</div>
				</div>
				{confirm_close_modal && (
					<ConfirmClose
						headerTitle='Discard Changes'
						title='You have made changes but you have not saved them. Are you sure you want to discard these changes?'
						close={() => set_confirm_close_modal(false)}
						on_confirm={handle_confirm_close}
					/>
				)}
			</div>
		</Modal>
	);
};

export default DeliveryDetailsModal;
