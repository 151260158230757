import React, { useEffect, useState, useRef } from 'react';
import { LinearProgress } from '@material-ui/core';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import { BOTTOM_STATE } from '../helper';
import Button from 'modules/Button';

const AllocationFooter = ({ bottom_bar_state, total_orders, btn_disabled, handle_allocate_rider, handle_track, handle_try_again }) => {
	const [progress, setProgress] = useState(0);
	const timer_ref = useRef(null);

	useEffect(() => {
		return () => {
			clearInterval(timer_ref.current);
		};
	}, []);

	useEffect(() => {
		if (bottom_bar_state === BOTTOM_STATE.in_progress) start_animation();
		else {
			setProgress(0);
			clearInterval(timer_ref.current);
		}
	}, [bottom_bar_state]);

	const start_animation = () => {
		timer_ref.current = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress === 100) {
					clearInterval(timer_ref.current);
				}
				return oldProgress + 25;
			});
		}, 1000);
	};

	return (
		<>
			{bottom_bar_state === BOTTOM_STATE.start && (
				<>
					<div className='align-items-center justify-content-between' style={{ flex: 1 }}>
						<div></div>
						<Button
							disabled={btn_disabled}
							type='round'
							text='Allocate Now'
							size='large'
							onClick={() => handle_allocate_rider(false)}
							style={{ marginLeft: 4 }}
						/>
					</div>
				</>
			)}

			{bottom_bar_state === BOTTOM_STATE.in_progress && (
				<div style={{ flex: 1 }}>
					<LinearProgress variant='determinate' style={{ width: '100%' }} color='primary' value={progress} />
					<Text bold style={{ fontSize: 16, marginTop: 10 }}>
						Allocation requested,
						<Text bold component={'span'} style={{ fontSize: 16, color: theme.colors.primary, marginLeft: 2 }}>
							processing orders..
						</Text>
					</Text>
					<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2, marginTop: 2 }}>
						Processing will continue in background
					</Text>
				</div>
			)}

			{bottom_bar_state === BOTTOM_STATE.success && (
				<>
					<div className='align-items-center'>
						<img src={ImageLinks.tickCircleSolid} width={16} height={16} alt='' />
						<Text semi style={{ fontSize: 16, color: theme.colors.green, marginLeft: 8 }}>
							{`${total_orders} Orders Allocated Successfully!`}
						</Text>
					</div>
					<Button type='round' text='Track' size='large' left_icon={ImageLinks.track} onClick={handle_track} />
				</>
			)}

			{bottom_bar_state === BOTTOM_STATE.failed && (
				<>
					<div className='align-items-center'>
						<img src={ImageLinks.exclamation_error} width={16} height={16} alt='' />
						<Text semi style={{ fontSize: 16, color: theme.colors.red, marginLeft: 8 }}>
							{`Unable to allocate ${total_orders} orders`}
						</Text>
					</div>
					<Button type='round' text='Try Again' size='large' onClick={handle_try_again} />
				</>
			)}
		</>
	);
};

export default AllocationFooter;
