import { makeStyles, useTheme } from '@material-ui/core';
import _ from 'lodash';
import Text from 'modules/Text';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import utils from 'resources/utils';

const useStyles = makeStyles((theme) => ({
	outer_box: { padding: 16, background: theme.colors.lightPurple9, borderRadius: 12 },
	inner_box: { padding: '12px 20px', display: 'flex', background: theme.colors.white, borderRadius: 12 },
	stat_items: { flex: 1, padding: 20, display: 'flex' },
	stat_header: { fontSize: 30, color: theme.colors.primary, width: '90%' },
	stat_type: { fontSize: 12, width: '90%' },
	stat_divider: { width: 1, height: '100%', backgroundColor: theme.colors.lightGrey5 },
}));

function Overview({ selected_menu, ids = [] }) {
	const theme = useTheme();
	const classes = useStyles();
	const [order_stats, set_order_stats] = useState({});
	const owner_type = utils._retrieveData(constant.TYPE_OF_OWNER);

	const get_orders_stats = async (owner_ids = '') => {
		try {
			const res = await api_requests.get_all_orders_overview_orders_stats({
				...(selected_menu === 'created_at' && { created_at: moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD') }),
				...(selected_menu === 'pickup_at' && { pickup_at: moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD') }),
				...(selected_menu === 'delivery_at' && { delivery_at: moment().format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD') }),
				...(!_.isEmpty(owner_ids) && { owner_ids }),
			});
			set_order_stats(res.data);
		} catch (err) {}
	};

	useEffect(() => {
		if (owner_type.id == constant.OWNER_TYPE.AGGREGATOR.id) {
			if (!_.isEmpty(ids)) {
				let owner_ids = _.join(_.map(ids, String), ',');
				get_orders_stats(owner_ids);
			}
		} else {
			get_orders_stats();
		}
	}, [selected_menu, ids]);

	return (
		<div className={classes.outer_box}>
			<div className={classes.inner_box}>
				<div className={classes.stat_items}>
					<div style={{ width: '100%' }}>
						<Text style={{ fontSize: 20, color: theme.colors.primary }} bold>
							Today's
						</Text>
						<Text style={{ fontSize: 16, color: theme.colors.primary }} semi>
							Overview
						</Text>
						<Text style={{ fontSize: 10, color: theme.colors.lightGrey2, marginTop: 4 }} semi>
							{moment().format('DD/MM/YY')}
						</Text>
					</div>
				</div>
				<div className={classes.stat_items} style={{ backgroundColor: theme.colors.lightPurple, borderRadius: 12 }}>
					<div style={{ width: '100%' }}>
						<Text className={classes.stat_header} bold>
							{_.get(order_stats, 'stats.total_order', '')}
						</Text>
						<Text className={classes.stat_type} semi>
							Total Orders
						</Text>
					</div>
				</div>
				<div className={classes.stat_items}>
					<div style={{ width: '100%' }}>
						<Text className={classes.stat_header} bold>
							{_.get(order_stats, 'stats.pending', 0) + _.get(order_stats, 'stats.processing', 0)}
						</Text>

						<Text className={classes.stat_type} semi>
							Pending
						</Text>
					</div>
					<div className={classes.stat_divider}></div>
				</div>
				<div className={classes.stat_items}>
					<div style={{ width: '100%' }}>
						<Text className={classes.stat_header} bold>
							{_.get(order_stats, 'stats.ready_to_ship', '')}
						</Text>
						<Text className={classes.stat_type} semi>
							AWB Manifested
						</Text>
					</div>
					<div className={classes.stat_divider}></div>
				</div>
				<div className={classes.stat_items}>
					<div style={{ width: '100%' }}>
						<Text className={classes.stat_header} bold>
							{_.get(order_stats, 'stats.shipped', '')}
						</Text>
						<Text className={classes.stat_type} semi>
							Shipped
						</Text>
					</div>
					<div className={classes.stat_divider}></div>
				</div>
				<div className={classes.stat_items}>
					<div style={{ width: '100%' }}>
						<Text className={classes.stat_header} bold>
							{_.get(order_stats, 'stats.completed', '')}
						</Text>
						<Text className={classes.stat_type} semi>
							Completed
						</Text>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Overview;
