import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import constant from 'resources/constant';

/**
 * creates input with autocomplete features
 */
const StandaloneSearchBoxComp = ({
	placeHolder,
	onplaceChange,
	zone_id,
	style,
	value,
	onTextChange,
	set_is_permission_denied,
	disabled,
	current_location,
	set_current_location,
}) => {
	const zones = useSelector((state) => state.app.zones);
	const zone = _.find(zones, { id: zone_id || constant.DEFAULT_ZONE_ID });

	useEffect(() => {
		if ('geolocation' in navigator) {
			navigator.permissions.query({ name: 'geolocation' }).then((permission_status) => {
				if (permission_status.state === 'denied') {
					set_is_permission_denied(true);
				}
			});
			navigator.geolocation.getCurrentPosition(function (position) {
				set_current_location({
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
				});
			});
		}
	}, []);

	useEffect(() => {
		// Create a bounding box with sides ~10km away from the center
		let defaultBounds = {};
		if (zone) {
			defaultBounds = {
				north: zone.locationRestriction.latitudeNE,
				south: zone.locationRestriction.latitudeSW,
				east: zone.locationRestriction.longitudeNE,
				west: zone.locationRestriction.longitudeSW,
			};
		} else if (zone_id == 'near_me' && !_.isEmpty(current_location)) {
			defaultBounds = {
				north: current_location.latitude + 0.25,
				south: current_location.latitude - 0.25,
				east: current_location.longitude + 0.35,
				west: current_location.longitude - 0.35,
			};
		}
		const input = document.getElementById('pac-input');

		const options = {
			...(!_.isEmpty(defaultBounds) && { bounds: defaultBounds }),
			componentRestrictions: { country: 'in' },
			strictBounds: true,
			types: ['establishment'],
		};
		const searchBox = new window.google.maps.places.Autocomplete(input, options);

		const searchListner = searchBox.addListener('place_changed', () => {
			const place = searchBox.getPlace();
			if (!place.geometry) {
				return;
			}
			onplaceChange(place);
		});
		return () => {
			searchListner.remove();
		};
	}, [zone, current_location]);

	const onFocus = (event) => {
		if (event.target.autocomplete) {
			event.target.autocomplete = 'new-address';
		}
	};

	return (
		<input
			id='pac-input'
			style={{ border: 'none', outline: 'none', ...(style || {}) }}
			placeholder={placeHolder}
			onChange={onTextChange}
			onFocus={onFocus}
			value={value}
			disabled={disabled}
		/>
	);
};

export default StandaloneSearchBoxComp;
