import React, { useEffect, useState, useMemo } from 'react';
import { ListItem, makeStyles } from '@material-ui/core';
import _ from 'lodash';

import theme from 'resources/theme';
import Text from 'modules/Text';
import UndlerlineTextField from 'modules/UndlerlineTextField';
import ContainedButton from 'modules/ContainedButton';
import Button from 'modules/Button';
import Switch from 'modules/Switch';
import ImageLinks from 'assets/images/ImageLinks';
import utils from 'resources/utils';
import constant from 'resources/constant';
import { MapContainer, TileLayer, ZoomControl, useMap } from 'react-leaflet';
import { Icon } from 'leaflet';
import L from 'leaflet';

let marker;
let cityCircle;
const tabs = ['Pickup', 'Drop'];

const useStyles = makeStyles(() => ({
	heading_container: { display: 'flex', justifyContent: 'space-between', marginBottom: 10 },
	heading: { color: theme.colors.primary, fontSize: 18, marginBottom: 10 },
	sub_heading: { color: theme.colors.darkGrey2 },
	info_container: { width: 340, backgroundColor: theme.colors.lightPurple10, borderRadius: 20, padding: 20, height: 640 },
	geo_fence: { marginTop: 30, fontSize: 16, marginBottom: 5 },
	tab_section: { marginTop: 20, marginBottom: 20, display: 'flex' },
	mapContainer: { height: '360px', width: '100%', marginTop: 30, paddingBottom: 10 },
	width_url: { width: 100, marginLeft: 10, marginTop: 5 },
	update: { borderRadius: 30, fontSize: 16, marginTop: 10, width: 100 },
	switch_flex: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
	tabs: {
		marginTop: 18,
		display: 'flex',
		paddingBottom: 8,
		borderBottom: `1px solid ${theme.colors.lightPurple}`,
	},
	tabs_item: {
		borderRadius: 8,
		padding: '8px 12px',
		cursor: 'pointer',
		gap: 8,
	},
}));

const TabValue = [
	{ text: '500m', value: 500 },
	{ text: '1500m', value: 1500 },
	{ text: '3000m', value: 3000 },
];
const ListValue = [
	{ text: 'Minimizes fake attempts' },
	{ text: 'Improves likelihood of delivery % and improves SLAs' },
	{ text: 'Proactively informs admins about distant attempts' },
	{ text: 'Reduces need for excessive communication' },
];

const GeoLocation = ({ geofencing, handle_update_settings, business_settings }) => {
	const is_menu_option_selected_default = _.find(TabValue, { value: geofencing }) || {};
	const classes = useStyles();
	const [pickup_selected_menu_value, set_pickup_selected_menu_value] = React.useState(business_settings?.geofence_config?.pickup?.geofence || 3000);
	const [drop_selected_menu_value, set_drop_selected_menu_value] = React.useState(business_settings?.geofence_config?.drop?.geofence || 3000);
	const [pickup_custom_value, set_pickup_custom_value] = React.useState(business_settings?.geofence_config?.pickup?.geofence || '');
	const [drop_custom_value, set_drop_custom_value] = React.useState(business_settings?.geofence_config?.drop?.geofence || '');
	const [otp_toggle, set_otp_toggel] = useState(business_settings?.geofence_config?.drop?.otp || true);
	const [selected_tab, set_selected_tab] = useState(0);
	const [pickup_modular_toggle, set_pickup_modular_toggle] = useState(business_settings?.geofence_config?.pickup?.block || false);
	const [drop_modular_toggle, set_drop_modular_toggle] = useState(business_settings?.geofence_config?.drop?.block || false);

	const map = React.useRef(null);

	useEffect(() => {
		setTimeout(() => {
			initialize();
		}, 800);
	}, [pickup_selected_menu_value, pickup_custom_value, drop_selected_menu_value, drop_custom_value, selected_tab]);

	const disabled = useMemo(() => {
		let value = false;
		if (
			otp_toggle === business_settings?.geofence_config?.drop?.otp &&
			pickup_selected_menu_value === business_settings?.geofence_config?.pickup?.geofence &&
			drop_selected_menu_value === business_settings?.geofence_config?.drop?.geofence &&
			pickup_modular_toggle == business_settings?.geofence_config?.pickup?.block &&
			drop_modular_toggle == business_settings?.geofence_config?.drop?.block
		) {
			value = true;
		}
		return value;
	}, [otp_toggle, pickup_selected_menu_value, drop_selected_menu_value, pickup_modular_toggle, drop_modular_toggle]);

	const initialize = () => {
		if (map.current) {
			map.current.setView([Number(28.56434), Number(77.19591)], 13);
			const icon_pickup = new Icon({ iconUrl: ImageLinks.marker_green, iconSize: [30, 30] });
			L.marker([Number(28.56434), Number(77.19591)], { icon: icon_pickup }).addTo(map.current);
		}
	};

	const CircleComponent = () => {
		const map = useMap();

		useEffect(() => {
			const center = [28.56434, 77.19591];
			const radius =
				selected_tab == 0
					? pickup_custom_value
						? Number(pickup_custom_value)
						: pickup_selected_menu_value
					: drop_custom_value
					? Number(drop_custom_value)
					: drop_selected_menu_value;

			const circle = L.circle(center, {
				color: theme.colors.primary,
				fillColor: theme.colors.lightPurple,
				fillOpacity: 0.35,
				strokeOpacity: 0.8,
				strokeWeight: 2,
				radius: radius,
			}).addTo(map);

			return () => {
				map.removeLayer(circle);
			};
		}, [map]);

		return null;
	};
	const handle_tab_select = (key) => {
		set_selected_tab(key);
	};

	const update_setting = () => {
		const data = {
			geofence_config: {
				pickup: {
					block: pickup_modular_toggle,
					geofence: pickup_custom_value !== '' ? pickup_custom_value : pickup_selected_menu_value,
					otp: false,
				},
				drop: {
					block: drop_modular_toggle,
					geofence: drop_custom_value !== '' ? drop_custom_value : drop_selected_menu_value,
					otp: otp_toggle,
				},
			},
		};
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.SAVE_GEOLOCATION, data);
		handle_update_settings(data);
	};

	const handle_set_modular = () => {
		if (selected_tab == 0) {
			set_pickup_modular_toggle((prev) => !prev);
		} else {
			set_drop_modular_toggle((prev) => !prev);
		}
	};

	const handle_geo_fence = (value) => {
		if (selected_tab == 0) {
			set_pickup_selected_menu_value(value);
			set_pickup_custom_value('');
		} else {
			set_drop_selected_menu_value(value);
			set_drop_custom_value('');
		}
	};

	const handle_geo_custom = (value) => {
		if (selected_tab == 0) {
			set_pickup_custom_value(value);
			set_pickup_selected_menu_value(null);
		} else {
			set_drop_custom_value(value);
			set_drop_selected_menu_value(null);
		}
	};

	return (
		<>
			<div>
				<div className={classes.heading_container}>
					<Text bold className={classes.heading}>
						Geo Location & Fencing
					</Text>
					<ListItem
						key={`tips`}
						button
						onClick={() => {}}
						style={{
							backgroundColor: theme.colors.lightPurple,
							marginRight: 0,
							width: 80,
							borderRadius: 5,
							display: 'flex',
							justifyContent: 'center',
							padding: '8px 8px',
						}}>
						<Text bold style={{ color: theme.colors.primary, fontSize: 10, marginLeft: 2 }}>
							Tips & Info
						</Text>
					</ListItem>
				</div>

				<div style={{ display: 'flex' }}>
					<div style={{ marginRight: 20, flex: '1' }}>
						<Text semi className={classes.sub_heading} style={{ fontSize: 14 }}>
							Establishes a virtual boundary around the pickup or delivery location. Virtual boundary distance can be customized based on business
							requirements.
						</Text>
						<Text semi className={classes.sub_heading} style={{ fontSize: 14, marginTop: 20 }}>
							The virtual boundary allows for automatic approvals, but in case the executive tries to update a location outside the boundary for
							any reason, they can confirm the location via OTP from the receiver, or take approval from an admin.
						</Text>
						<div className={classes.tabs}>
							{_.map(tabs, (tab, key) => (
								<div
									key={`filter_item_${key}`}
									className={classes.tabs_item}
									style={{
										background: selected_tab === key ? theme.colors.primary : 'transparent',
									}}
									onClick={() => handle_tab_select(key)}>
									<Text
										bold
										style={{
											fontSize: 14,
											color: selected_tab === key ? theme.colors.white : theme.colors.lightPurple6,
										}}>
										{tab}
									</Text>
								</div>
							))}
						</div>
						<div className={classes.switch_flex}>
							<div style={{ width: '70%' }}>
								<Text semi className={classes.geo_fence}>
									Set Geofence Type
								</Text>
								<Text className={classes.sub_heading} style={{ fontSize: 12 }}>
									Select what happens when an executive is outside the allowed geofence
								</Text>
							</div>
							<div className='d-flex align-items-center' style={{ width: '30%', justifyContent: 'flex-end' }}>
								<Text
									bold={selected_tab == 0 ? !pickup_modular_toggle : !drop_modular_toggle}
									style={{
										fontSize: 12,
										color:
											selected_tab == 0
												? !pickup_modular_toggle
													? theme.colors.darkGrey7
													: theme.colors.darkGrey2
												: !drop_modular_toggle
												? theme.colors.darkGrey7
												: theme.colors.darkGrey2,
									}}>
									Warn
								</Text>
								<Switch
									checked={selected_tab == 0 ? pickup_modular_toggle : drop_modular_toggle}
									onChange={handle_set_modular}
									red={selected_tab == 0 ? pickup_modular_toggle : drop_modular_toggle}
									yellow={selected_tab == 0 ? !pickup_modular_toggle : !drop_modular_toggle}
								/>
								<Text
									bold={selected_tab == 0 ? pickup_modular_toggle : drop_modular_toggle}
									style={{
										fontSize: 12,
										color:
											selected_tab == 0
												? pickup_modular_toggle
													? theme.colors.darkGrey7
													: theme.colors.darkGrey2
												: drop_modular_toggle
												? theme.colors.darkGrey7
												: theme.colors.darkGrey2,
									}}>
									Block
								</Text>
							</div>
						</div>
						{selected_tab == 1 && drop_modular_toggle && (
							<div className={classes.switch_flex}>
								<div style={{ width: '75%' }}>
									<Text semi className={classes.geo_fence}>
										OTP Verify Location
									</Text>
									<Text className={classes.sub_heading} style={{ fontSize: 12 }}>
										Executive can take OTP from customer to verify location in case of wrong coordinates
									</Text>
								</div>
								<div className='d-flex align-items-center' style={{ width: '25%', justifyContent: 'flex-end' }}>
									<Text regular style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
										{!otp_toggle ? 'Inactive' : 'Active'}
									</Text>
									<Switch
										checked={otp_toggle}
										onChange={(e) => {
											set_otp_toggel((prev) => !prev);
										}}
										green={true}
									/>
								</div>
							</div>
						)}
						<div>
							<Text semi className={classes.geo_fence}>
								Set Geo Fence
							</Text>
							<Text className={classes.sub_heading} style={{ fontSize: 12 }}>
								Define the acceptable radius for approving geo-location
							</Text>
							<div style={{ display: 'flex' }}>
								<div className={classes.tab_section}>
									{_.map(TabValue, (menu_item, key) => {
										const active =
											selected_tab == 0 ? pickup_selected_menu_value === menu_item.value : drop_selected_menu_value === menu_item.value;
										return (
											<ListItem
												key={`menuItem${key}`}
												button
												onClick={() => handle_geo_fence(menu_item.value)}
												style={{
													backgroundColor: active ? theme.palette.primary.main : 'white',
													border: '1px solid #492897',
													marginRight: 10,
													width: 90,
													borderRadius: 30,
													display: 'flex',
													justifyContent: 'center',
												}}>
												<Text semi style={{ color: active ? 'white' : theme.colors.primary }}>
													{menu_item.text}
												</Text>
											</ListItem>
										);
									})}
								</div>
								<UndlerlineTextField
									label='Custom in meters'
									type={'number'}
									value={selected_tab == 0 ? pickup_custom_value : drop_custom_value}
									onChange={(e) => handle_geo_custom(e.target.value)}
									className={classes.width_url}
									textStyle={{ fontSize: 16 }}
									style={{ width: 160 }}
									labelStyle={{ fontSize: 16, color: theme.colors.lightGrey2 }}
								/>
							</div>
							<Text className={classes.sub_heading} style={{ fontSize: 10 }}>
								*Recommended to avoid fake attempts.
							</Text>
						</div>
						<div className={classes.mapContainer}>
							<MapContainer
								ref={map}
								center={{ lat: Number(28.56434), lng: Number(77.19591) }}
								zoom={16}
								scrollWheelZoom={true}
								zoomControl={false}>
								<TileLayer
									attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
									url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
								/>
								<CircleComponent />
								<ZoomControl position='bottomright' />
							</MapContainer>
						</div>
					</div>
					<div className={classes.info_container}>
						<Text bold style={{ fontSize: 18 }}>
							Recommended Geo Location Settings
						</Text>
						<div
							style={{
								width: 300,
								height: 130,
								marginTop: 20,
								marginBottom: 10,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: 12,
							}}>
							<img src={ImageLinks.m500} alt='' width='100%' />
						</div>
						<Text bold style={{ fontSize: 16 }}>
							500 m
						</Text>
						<Text style={{ fontSize: 12, marginTop: 10, marginBottom: 10 }}>
							Delivery executives can only perform updates if they are within a radius of 500 meters to the pickup or drop location.
						</Text>
						<Text semi style={{ fontSize: 14 }}>
							Benefits:
						</Text>
						<ol style={{ paddingLeft: 15 }}>
							{_.map(ListValue, (item, index) => {
								return (
									<li key={index}>
										<Text style={{ fontSize: 12, marginBottom: 5 }}>{item.text}</Text>
									</li>
								);
							})}
						</ol>
					</div>
				</div>
			</div>
			<div style={{ position: 'absolute', bottom: 30, right: 30 }}>
				<Button disabled={disabled} onClick={update_setting} type='round' text='Save' size='large' style={{ width: 110 }} />
			</div>
		</>
	);
};

export default GeoLocation;
