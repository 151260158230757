import React from 'react';
import theme from 'resources/theme';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import moment from 'moment/moment';
import useNotifListing from './useNotifListing';
import RiderOverviewModal from '../RiderOverviewModal';
import Routes from 'resources/Routes';
import Button from 'modules/Button';
import OrderModal from '../OrderModal/OrderModal';

const tabs = ['Alerts', 'Notifications'];

const action_mapping = {
	approve: 'Approved',
	reject: 'Rejected',
};

const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 260,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1201,
	},
	container: {
		position: 'absolute',
		top: 0,
		left: 240,
		height: '100%',
		width: 400,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 30,
		zIndex: 1202,
		overflowY: 'auto',
		// paddingBottom: 120,
	},
	header_wrap: { borderBottom: `1px solid ${theme.colors.lightGrey5}`, paddingBottom: 13, display: 'flex', justifyContent: 'space-between' },
	item_wrap: {
		display: 'flex',
		padding: '12px 8px',
		paddingLeft: 0,
		minHeight: 62,
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
	},
	footer: {
		height: 100,
		width: 570,
		background: theme.colors.white,
		position: 'fixed',
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		paddingRight: 30,
		boxShadow: '0px -4px 14px 0px rgba(0, 0, 0, 0.05)',
		display: 'flex',
		justifyContent: 'space-between',
	},
	download_btn: {
		width: 183,
		height: 42,
		borderRadius: 30,
		display: 'flex',
		cursor: 'pointer',
		boxShadow: 'none',
	},
	donwload_report_text: { fontSize: 16, color: theme.colors.primary, marginLeft: 6 },
	action_btns: {
		height: 24,
		padding: '2px 12px',
		borderRadius: 44,
		marginRight: 4,
	},
	notif_text_wrap: { marginLeft: 8, flex: 1, display: 'flex', justifyContent: 'space-between' },
	listing_wrap: {
		height: '80vh',
		overflowY: 'scroll',
		overflowX: 'hidden',
		'&::-webkit-scrollbar': {
			width: '0.2em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
			borderRadius: 4,
		},
	},
}));

const NotificationsListing = ({ user_teams, close, wrapper_styles = {}, notif_tab }) => {
	const {
		notification_config,
		selected_tab,
		set_selected_tab,
		handle_report_download,
		export_loading,
		export_status,
		notif_list,
		alert_list,
		trigger_manual_action,
		show_cdr_modal,
		set_show_cdr_modal,
		cdr_order_id,
		show_rider_modal,
		rider_details,
		set_rider_details,
		handle_rider_close,
		slider_ref,
		slider_scrolled,
		is_loading,
		notif_count,
		alert_count,
	} = useNotifListing(user_teams, notif_tab);
	const classes = useStyles();
	const history = useHistory();

	const in_between_text_click = (notif_item, handler = () => {}, key) => {
		handler(notif_item, key);
	};

	const parse_string = (string) => {
		const pattern = /(\#\#.*?\#\#)/g;
		const split_string_by_pattern = string.split(pattern);
		const output = _.map(split_string_by_pattern, (item) =>
			item.match(pattern) ? { type: 'braces', value: item.replaceAll('#', '') } : { type: 'text', value: item },
		);
		return output;
	};

	const format_data = (key, value) => {
		if ((key === 'start_ts' || key === 'end_ts') && value) return moment(value).format('DD-MM-YYYY hh:mm A');
		if ((key === 'speed' || key === 'distance') && value) return value.toFixed(3);
		return value;
	};

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={clsx(classes.container, 'box-shadow')} style={wrapper_styles}>
				<div className={classes.header_wrap}>
					<Text bold style={{ fontSize: 20, color: theme.colors.primary }}>
						Notifications
					</Text>
					<div style={{ cursor: 'pointer' }} onClick={close}>
						<img src={ImageLinks.crossBlack} width={16} height={16} alt='' />
					</div>
				</div>

				<div style={{ marginTop: 12, display: 'flex' }}>
					{_.map(tabs, (item, index) => (
						<ContainedButton
							onClick={() => set_selected_tab(index)}
							disableElevation
							key={`tab_item_${index}`}
							style={{ height: 33, borderRadius: 10, marginRight: 10, ...(index !== selected_tab && { background: 'transparent' }) }}>
							<Text bold style={{ fontSize: 14, color: theme.colors.white, ...(index !== selected_tab && { color: theme.colors.lightPurple6 }) }}>
								{item}
							</Text>
							{(index === 0 && alert_count > 0) || (index === 1 && notif_count > 0) ? (
								<div
									style={{ width: 12, height: 12, borderRadius: 6, backgroundColor: theme.colors.red, marginLeft: 4, marginBottom: 4 }}></div>
							) : null}
						</ContainedButton>
					))}
				</div>
				<div className={classes.listing_wrap} ref={slider_ref} onScroll={slider_scrolled}>
					{selected_tab === 0 &&
						_.map(alert_list, (item, index) => {
							const notif_config = notification_config[item.data.type];
							const received_time = moment(item.data.timestamp);
							const is_same_date = received_time.isSame(moment(), 'day');
							let time_stamp = received_time.format('DD MMM YYYY hh:mm A');
							if (is_same_date) time_stamp = `Today at ${received_time.format('hh:mm A')}`;

							let content = _.get(item, 'data.body.content', '');
							let content_arr = parse_string(content);
							const actions = _.get(item, 'data.body.actions', []);

							return (
								<div key={`notif_item_${index}`} className={classes.item_wrap}>
									<img src={ImageLinks.marker_purple} width={16} height={16} alt='' />
									<div style={{ marginLeft: 8 }}>
										<div style={{ flex: 1 }}>
											<Text semi style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
												{time_stamp}
											</Text>
											<div>
												{_.map(content_arr, (content, index) => (
													<Text
														key={`prototype_text_${index}`}
														medium={content.type === 'text'}
														bold={content.type === 'braces'}
														{...(content.type === 'braces' &&
															notif_config?.[content.value]?.is_clickable_handler?.(item.data) && {
																onClick: () => {
																	in_between_text_click(item.data, notif_config?.[content.value]?.handler, content.value);
																},
															})}
														component={'span'}
														style={{
															fontSize: 12,
															lineHeight: 1.5,
															...(content.type === 'braces' &&
																notif_config?.[content.value]?.is_clickable_handler?.(item.data) && {
																	textDecoration: 'underline',
																	cursor: 'pointer',
																}),
														}}>
														{content.type === 'text' ? content.value : item.data.variables?.[content.value]}
													</Text>
												))}
											</div>
										</div>
										<div style={{ marginTop: 12 }}>
											{_.map(actions, (btn_action, btn_index) => (
												<ContainedButton
													onClick={() => {
														trigger_manual_action(item.data.id, btn_action.value);
													}}
													key={`action_btn_${btn_index}`}
													className={classes.action_btns}
													style={{
														backgroundColor: btn_action.value === 'approve' ? theme.colors.green : theme.colors.red,
													}}>
													<Text semi style={{ fontSize: 12 }}>
														{btn_action.text}
													</Text>
												</ContainedButton>
											))}
										</div>
									</div>
								</div>
							);
						})}

					{selected_tab === 1 &&
						_.map(notif_list, (item, index) => {
							const notif_config = notification_config[item.data.type];
							const received_time = moment(item.data.timestamp);
							const is_same_date = received_time.isSame(moment(), 'day');
							let time_stamp = received_time.format('DD MMM YYYY hh:mm A');
							if (is_same_date) time_stamp = `Today at ${received_time.format('hh:mm A')}`;

							let content = _.get(item, 'data.body.content', '');
							let seclected_action = _.get(item, 'data.body.selectedAction', null);
							let actor = _.get(item, 'data.body.actor', null);
							let content_arr = parse_string(content);

							return (
								<div key={`notif_item_${index}`} className={classes.item_wrap}>
									<img src={ImageLinks.box2_filled_purple} width={16} height={16} alt='' />

									<div className={classes.notif_text_wrap}>
										<div>
											<Text semi style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
												{time_stamp}
											</Text>
											<div style={{ float: 'left' }}>
												{_.map(content_arr, (content, index) => (
													<Text
														key={`prototype_text_${index}`}
														medium={content.type === 'text'}
														bold={content.type === 'braces'}
														{...(content.type === 'braces' &&
															notif_config?.[content.value]?.is_clickable_handler?.(item.data) && {
																onClick: () => {
																	in_between_text_click(item.data, notif_config?.[content.value]?.handler, content.value);
																},
															})}
														component={'span'}
														style={{
															fontSize: 12,
															lineHeight: 1.5,
															...(content.type === 'braces' &&
																notif_config?.[content.value]?.is_clickable_handler?.(item.data) && {
																	textDecoration: 'underline',
																	cursor: 'pointer',
																}),
														}}>
														{content.type === 'text'
															? content.value
															: item.data.type === 'TRACE_LOCATION'
															? format_data(content.value, item.data.variables?.[content.value])
															: item.data.variables?.[content.value]}
													</Text>
												))}
											</div>
										</div>
									</div>
									{!_.isEmpty(seclected_action) && (
										<div style={{ marginTop: 5 }}>
											<Text
												semi
												style={{ fontSize: 12, color: seclected_action.value === 'approve' ? theme.colors.green : theme.colors.red }}>
												{`${action_mapping[seclected_action.value]} by ${_.capitalize(actor.name)}`}
											</Text>
										</div>
									)}
								</div>
							);
						})}
					{selected_tab == 0 && alert_list.length == 0 && !is_loading && (
						<div className='d-flex justify-content-center align-items-center' style={{ flexDirection: 'column', gap: 10, marginTop: '40%' }}>
							<img src={ImageLinks.empty_state_notification_alerts} alt='' />
							<Text semi style={{ fontSize: 16 }}>
								Set up your alerts
							</Text>
							<Button
								type='round'
								text='Alerts'
								size='small'
								left_icon={ImageLinks.addWhite}
								onClick={() => {
									history.push(`${Routes.CREATE_ALERT.path}?key=order`);
									close();
								}}
							/>
						</div>
					)}
					{selected_tab == 1 && notif_list.length == 0 && !is_loading && (
						<div className='d-flex justify-content-center align-items-center' style={{ flexDirection: 'column', gap: 10, marginTop: '50%' }}>
							<img src={ImageLinks.empty_state_notification} alt='' />
							<Text semi style={{ fontSize: 16, color: theme.colors.primary }}>
								No Notification yet!
							</Text>
						</div>
					)}
				</div>

				{/* <div className={classes.footer} style={wrapper_styles}>
					<div className='align-items-center justify-content-between' style={{ flex: 1 }}>
						{!export_loading && _.isEmpty(export_status) && (
							<ContainedButton
								style={{ backgroundColor: theme.colors.lightPurple }}
								className={classes.download_btn}
								onClick={handle_report_download}>
								<img width={16} height={16} src={ImageLinks.download} alt='' />
								<Text semi className={classes.donwload_report_text}>
									Download Report
								</Text>
							</ContainedButton>
						)}
						{export_loading && (
							<div className='align-items-center'>
								<CircularProgress size={14} style={{ color: theme.colors.primary }} />
								<Text semi className={classes.donwload_report_text}>
									Working on it..
								</Text>
							</div>
						)}
						{!_.isEmpty(export_status) && (
							<div className='align-items-center'>
								<img src={export_status === 'success' ? ImageLinks.tickCircleSolid : ImageLinks.exclamation_error} width={16} height={16} />
								<Text
									semi
									className={classes.donwload_report_text}
									style={{ color: export_status === 'success' ? theme.colors.green : theme.colors.red }}>
									{export_status === 'success' ? 'Report Generated' : 'Something went wrong'}
								</Text>
							</div>
						)}
						<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							DD/MM/YYY
						</Text>
					</div>
				</div> */}
			</div>
			{show_cdr_modal && <OrderModal id={cdr_order_id} close={() => set_show_cdr_modal(false)} />}
			{show_rider_modal && <RiderOverviewModal rider_data={rider_details} set_rider_data={set_rider_details} close={handle_rider_close} />}
		</>
	);
};

export default NotificationsListing;
