import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import allowedRoutes from './allowedRoutes';

const allowedRoutes = ['/', '/dashboard', '/user-management', '/all-orders', '/networks', '/settings'];
const ProtectedRouts = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				return allowedRoutes.includes(props.location.pathname) ? <Component {...props} /> : <Redirect to='/access-denied' />;
			}}
		/>
	);
};

export default ProtectedRouts;
