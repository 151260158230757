import { Divider, makeStyles, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import React, { useContext, useState } from 'react';
import OrderModalContext from '../OrderModalContext';
import _ from 'lodash';
import OutlinedTextField from 'modules/OutlinedTextField';
import utils from 'resources/utils';
import constant from 'resources/constant';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		borderRadius: 8,
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		padding: 12,
		marginTop: 20,
	},
	inputs: { width: 80, height: 32 },
	view_product_text: { fontSize: 12, textDecoration: 'underline', color: theme.colors.primary, cursor: 'pointer' },
	product_count_wrap: {
		borderRadius: 6,
		border: `1px solid ${theme.colors.lightGrey5}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 8,
		gap: 5,
		cursor: 'pointer',
	},
	product_count: {
		height: 18,
		width: 18,
		borderRadius: 9,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.colors.primary,
		fontSize: 12,
	},
	menu_icon: {
		'&:hover': {
			'& .light-purple': {
				display: 'none !important',
			},
			'& .primary-purple': {
				display: 'block !important',
			},
		},
	},
	menu_wrap: { backgroundColor: theme.colors.white, boxShadow: '0px 1px 10px 1px rgba(202, 202, 202, 1)', borderRadius: 6 },
}));

function PackageDetails() {
	const classes = useStyles();
	const theme = useTheme();
	const { data, set_show_package_modal, set_show_product_modal } = useContext(OrderModalContext);
	const [volume, set_volume] = useState('');
	const [weight, set_weight] = useState('');

	const get_volume_weight = (key) => {
		return _.reduce(data.packages, (prev, curr) => prev + curr[key] * curr.quantity, 0);
	};

	return (
		<div className={classes.wrapper}>
			<div className='d-flex align-items-center justify-content-between'>
				<div className='d-flex align-items-center' style={{ gap: 5 }}>
					<img src={ImageLinks.orders} height={16} width={16} alt='' />
					<Text bold component={'span'} style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
						Package Details
					</Text>
				</div>
				<div className='d-flex' style={{ gap: 5 }}>
					{data.products?.length > 0 && (
						<Text semi className={classes.view_product_text} onClick={() => set_show_product_modal((prev) => !prev)}>
							View Products
						</Text>
					)}
					{data.status == 1 && (
						<div
							style={{ cursor: 'pointer' }}
							className={classes.menu_icon}
							onClick={() => {
								utils.track_event_for_analytics(constant.TRACKING_EVENTS.EDIT_PACKAGE_DETAILS);
								set_show_package_modal(true);
							}}>
							<img src={ImageLinks.edit_filled_light_purple} style={{ cursor: 'pointer' }} alt='' />
						</div>
					)}
				</div>
			</div>
			<Divider style={{ margin: '10px 0' }} />
			<div className='d-flex justify-content-between align-items-center'>
				<div className='d-flex justify-content-between align-items-center' style={{ gap: 10 }}>
					<OutlinedTextField
						label='Volume*'
						disabled={true}
						value={(get_volume_weight('volumetric_weight') / 1).toFixed(0)}
						onChange={(e) => set_volume(e.target.value)}
						className={classes.inputs}
						size='small'
						InputProps={{
							style: { height: 32, paddingRight: 0 },
							endAdornment: (
								<Text medium style={{ width: 50 }}>
									in<sup>3</sup>
								</Text>
							),
						}}
					/>
					<OutlinedTextField
						disabled={true}
						label='Weight*'
						value={(get_volume_weight('dead_weight') / 1000).toFixed(1)}
						onChange={(e) => set_weight(e.target.value)}
						className={classes.inputs}
						size='small'
						InputProps={{
							style: { height: 32, paddingRight: 0 },
							endAdornment: (
								<Text medium style={{ width: 50 }}>
									KG
								</Text>
							),
						}}
					/>
				</div>
				<div
					className={classes.product_count_wrap}
					onClick={() => {
						if (data.status == 1) set_show_package_modal(true);
					}}>
					<Text semi className={classes.product_count}>
						{_.reduce(data.packages, (prev, curr) => prev + curr.quantity, 0)}
					</Text>
					<Text semi style={{ fontSize: 12 }}>
						Package
					</Text>
				</div>
			</div>
		</div>
	);
}

export default PackageDetails;
