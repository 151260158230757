import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Text from './Text';
import _ from 'lodash';
import theme from 'resources/theme';

const useStyles = makeStyles(() => ({
	round: {
		border: `2px solid ${theme.colors.primary}`,
		borderRadius: 30,
		padding: ({ left_icon, right_icon }) =>
			!_.isEmpty(left_icon) && _.isEmpty(right_icon)
				? '8px 16px 8px 12px'
				: _.isEmpty(left_icon) && !_.isEmpty(right_icon)
				? '8px 12px 8px 16px'
				: _.isEmpty(left_icon) && _.isEmpty(right_icon)
				? '8px 16px'
				: '8px 12px',
		background: theme.colors.primary,
		color: theme.colors.white,
		gap: 6,
		'&:hover': {
			background: 'linear-gradient(180deg, #492897 0%, #25144C 100%)',
			boxShadow: '0px 8px 20px 0px rgba(102, 102, 102, 0.15)',
			padding: ({ left_icon, right_icon }) =>
				!_.isEmpty(left_icon) && _.isEmpty(right_icon)
					? '8px 18px 8px 14px'
					: _.isEmpty(left_icon) && !_.isEmpty(right_icon)
					? '8px 14px 8px 18px'
					: _.isEmpty(left_icon) && _.isEmpty(right_icon)
					? '8px 18px'
					: '8px 14px',
			border: 'none',
		},
		cursor: 'pointer',
	},
	half_round: {
		border: `2px solid ${theme.colors.primary}`,
		borderRadius: '30px 0px 0px 30px',
		padding: ({ left_icon, right_icon }) =>
			!_.isEmpty(left_icon) && _.isEmpty(right_icon)
				? '8px 16px 8px 12px'
				: _.isEmpty(left_icon) && !_.isEmpty(right_icon)
				? '8px 12px 8px 16px'
				: _.isEmpty(left_icon) && _.isEmpty(right_icon)
				? '8px 16px'
				: '8px 12px',
		background: theme.colors.primary,
		color: theme.colors.white,
		gap: 6,
		'&:hover': {
			background: 'linear-gradient(180deg, #492897 0%, #25144C 100%)',
			boxShadow: '0px 8px 20px 0px rgba(102, 102, 102, 0.15)',
			padding: ({ left_icon, right_icon }) =>
				!_.isEmpty(left_icon) && _.isEmpty(right_icon)
					? '8px 18px 8px 14px'
					: _.isEmpty(left_icon) && !_.isEmpty(right_icon)
					? '8px 14px 8px 18px'
					: _.isEmpty(left_icon) && _.isEmpty(right_icon)
					? '8px 18px'
					: '8px 14px',
			border: 'none',
		},
		cursor: 'pointer',
	},
	outlined: {
		border: `1px solid ${theme.colors.primary}`,
		borderRadius: 30,
		padding: ({ left_icon, right_icon }) =>
			!_.isEmpty(left_icon) && _.isEmpty(right_icon)
				? '8px 16px 8px 12px'
				: _.isEmpty(left_icon) && !_.isEmpty(right_icon)
				? '8px 12px 8px 16px'
				: _.isEmpty(left_icon) && _.isEmpty(right_icon)
				? '8px 16px'
				: '8px 12px',
		background: theme.colors.white,
		color: theme.colors.primary,
		gap: 6,
		'&:hover': {
			background: theme.colors.lightPurple,
			boxShadow: '0px 8px 20px 0px rgba(102, 102, 102, 0.15)',
		},
		cursor: 'pointer',
	},
	text_button: {
		borderRadius: 30,
		padding: ({ left_icon, right_icon }) =>
			!_.isEmpty(left_icon) && _.isEmpty(right_icon)
				? '8px 16px 8px 12px'
				: _.isEmpty(left_icon) && !_.isEmpty(right_icon)
				? '8px 12px 8px 16px'
				: _.isEmpty(left_icon) && _.isEmpty(right_icon)
				? '8px 16px'
				: '8px 12px',
		color: theme.colors.primary,
		gap: 6,
		cursor: 'pointer',
		textDecoration: 'underline',
		'&:hover': {
			background: theme.colors.lightPink4,
			textDecoration: 'none',
		},
	},
	disabled_button: {
		border: ({ type }) => (type == 'outlined' ? `1px solid ${theme.colors.darkGrey8}` : type == 'text' ? 'none' : `2px solid ${theme.colors.lightGrey5}`),
		background: ({ type }) => (type == 'outlined' || type == 'text' ? theme.colors.white : theme.colors.lightGrey5),
		color: theme.colors.darkGrey8,
		pointerEvents: 'none',
		cursor: 'auto',
		textDecoration: 'none',
	},
	disabled_button_outlined: {
		border: `1px solid ${theme.colors.darkGrey8}`,
		background: theme.colors.white,
		color: theme.colors.darkGrey8,
		cursor: 'auto',
	},
	large: {
		height: 36,
	},
	medium: {
		height: 34,
	},
	small: {
		height: 32,
	},
	extra_small: {
		height: 26,
		padding: ({ left_icon, right_icon }) =>
			!_.isEmpty(left_icon) && _.isEmpty(right_icon)
				? '8px 12px 8px 8px'
				: _.isEmpty(left_icon) && !_.isEmpty(right_icon)
				? '8px 8px 8px 12px'
				: _.isEmpty(left_icon) && _.isEmpty(right_icon)
				? '6px 12px'
				: '8px 8px',
		'&:hover': {
			padding: ({ left_icon, right_icon, type }) =>
				!_.isEmpty(left_icon) && _.isEmpty(right_icon) && type == 'round'
					? '8px 14px 8px 10px'
					: _.isEmpty(left_icon) && !_.isEmpty(right_icon) && type == 'round'
					? '8px 10px 8px 14px'
					: _.isEmpty(left_icon) && _.isEmpty(right_icon) && type == 'round'
					? '8px 14px'
					: '8px 12px 8px, 10px',
		},
	},
	text_large: {
		fontSize: 16,
	},
	text_medium: {
		fontSize: 14,
	},
	text_small: {
		fontSize: 12,
	},
	img_size_large: {
		width: 16,
		height: 16,
	},
	img_size_medium: {
		width: 14,
		height: 14,
	},
	img_size_small: {
		width: 12,
		height: 12,
	},
}));

const Button = ({
	className = '',
	text = '',
	type = 'round',
	size = 'small',
	left_icon = null,
	right_icon = null,
	disabled = false,
	onClick = () => {},
	style = {},
	left_image_styles = {},
	right_image_styles = {},
}) => {
	const classes = useStyles({ type, left_icon, right_icon, size });
	let button_size = classes.large;
	let text_font = classes.text_large;
	let img_size = classes.img_size_large;
	switch (size) {
		case 'medium':
			button_size = classes.medium;
			text_font = classes.text_large;
			img_size = classes.img_size_medium;
			break;
		case 'small':
			button_size = classes.small;
			text_font = classes.text_small;
			img_size = classes.img_size_small;
			break;
		case 'extra_small':
			button_size = classes.extra_small;
			text_font = classes.text_small;
			img_size = classes.img_size_small;
			break;
		default:
			button_size = classes.large;
			text_font = classes.text_large;
			img_size = classes.img_size_large;
	}
	return (
		<div
			style={style}
			onClick={onClick}
			className={clsx(
				'd-flex justify-content-center align-items-center',
				button_size,
				disabled ? classes.disabled_button : '',
				type == 'round'
					? classes.round
					: type == 'half_round'
					? classes.half_round
					: type == 'outlined'
					? classes.outlined
					: type == 'text'
					? classes.text_button
					: '',
				className,
			)}>
			{!_.isEmpty(left_icon) && <img src={left_icon} className={clsx(img_size)} style={{ ...left_image_styles }} alt='' />}
			<Text semi className={clsx(text_font, 'button-text')}>
				{text}
			</Text>
			{!_.isEmpty(right_icon) && <img src={right_icon} className={clsx(img_size)} style={{ ...right_image_styles }} alt='' />}
		</div>
	);
};

export default Button;
