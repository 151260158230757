import { REMOVE_ALL_ALERTS, UPDATE_NOTIF_COUNT } from './actionConstants';

export const empty_alerts = () => ({
	type: REMOVE_ALL_ALERTS,
});

export const update_count = (notif_count, alert_count) => ({
	type: UPDATE_NOTIF_COUNT,
	notif_count,
	alert_count,
});
