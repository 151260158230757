import history from 'browserHistory';
import _ from 'lodash';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import utils from 'resources/utils';
import {
	BUSINESS_PROPERTIES_RECEIVED,
	LOGIN_DETAILS_RECEIVED,
	LOGIN_FAILED,
	MASTER_ZONES_RECEIVED,
	SHOW_LOADER,
	HIDE_LOADER,
	USER_DETAILS_RECEIVED,
	REFRESH_MASTER_ZONES,
	ADD_USER_TEAMS,
	UPDATE_BIZ_LIST,
	ADD_ENTERPRISE_CHILD_BUSINESS_DATA,
} from './actionConstants';
import AppSocket from 'resources/AppSocket';
import qs from 'qs';
import Routes from 'resources/Routes';

export const get_user_details =
	(from_biz_switch = false) =>
	async (dispatch) => {
		const auth_token = utils._retrieveData(constant.AUTH_TOKEN);
		const type_of_owner = utils._retrieveData(constant.TYPE_OF_OWNER);
		const selected_business = utils._retrieveData(constant.SELECTED_BUSINESS);
		try {
			if (!auth_token) {
				const query_object = qs.parse(window.location.search.slice(1));
				dispatch(login_failed(query_object.shopify_token));
				return Promise.resolve();
			}

			utils.setRequestAuthHeader(auth_token, type_of_owner);
			const business_accounts = await api_requests.get_business_accounts();

			// const permissions = (await api_requests.get_permissions(selected_business.business_id)).data;
			const permissions = [];
			const bop_packages = (await api_requests.get_bop_packages()).data;
			const app_configs = (await api_requests.get_common_configs(selected_business.business_id)).data;
			const teams = await api_requests.get_joined_teams(selected_business.business_id);
			const network = (await api_requests.get_network_list(selected_business.business_id)).data;
			let user_teams = _.get(teams, 'data.items', []);
			if (from_biz_switch) {
				for (let i = 0; i < user_teams.length; i++) {
					const element = user_teams[i];
					let team_channel = `team_ch_${element.team_id}`;
					AppSocket.join_room(team_channel);
				}
			}
			// todo put get user details API
			const user_details = {};
			let enterprise_child_business_data = [];
			if (type_of_owner.id == constant.OWNER_TYPE.AGGREGATOR.id) {
				enterprise_child_business_data = (await api_requests.get_child_business(selected_business.business_id)).data;
			}
			// placing this as last since it sets redux state to succeeded, zones are necessary to run the app
			dispatch(
				login_details_received(
					user_details,
					business_accounts.data,
					permissions,
					bop_packages,
					selected_business,
					user_teams,
					app_configs,
					network,
					enterprise_child_business_data,
				),
			);

			return Promise.resolve();
		} catch (err) {
			dispatch(login_failed());
			return Promise.reject(err);
		}
	};

export const get_business_properties = () => async (dispatch) => {
	try {
		const business_properties = (await api_requests.get_business_properties()).data;
		dispatch(business_properties_received(business_properties));
		return Promise.resolve();
	} catch (err) {
		return Promise.reject(err);
	}
};

const login_details_received = (
	user_details,
	business_accounts,
	permissions,
	bop_packages,
	selected_business,
	user_teams,
	app_configs,
	network,
	enterprise_child_business_data,
) => ({
	type: LOGIN_DETAILS_RECEIVED,
	user_details,
	business_accounts,
	permissions,
	bop_packages,
	selected_business,
	user_teams,
	app_configs,
	network,
	enterprise_child_business_data,
});

export const user_details_received = (name, mobile, user_id, user_type, user_email) => ({
	type: USER_DETAILS_RECEIVED,
	name,
	mobile,
	user_id,
	user_type,
	user_email,
});

export const master_zones_received = (zones) => ({
	type: MASTER_ZONES_RECEIVED,
	zones,
});

const business_properties_received = (business_properties) => ({
	type: BUSINESS_PROPERTIES_RECEIVED,
	business_properties,
});

export const login_failed = (data) => {
	const { logout, shopify_token } = { ...data };
	utils.logout();
	if (logout) {
		history.push(Routes.LOGIN.path);
	} else if (!_.isEmpty(shopify_token)) {
		history.push(`${Routes.LOGIN.path}/?shopify_token=${shopify_token}&is_login=true`);
		window.location.reload(true);
	} else {
		history.push('/');
	}
	return { type: LOGIN_FAILED };
};

export const show_loader = () => async (dispatch) => {
	dispatch({ type: SHOW_LOADER });
};

export const hide_loader = () => async (dispatch) => {
	dispatch({ type: HIDE_LOADER });
};

export const refresh_master_zones_action = () => ({
	type: REFRESH_MASTER_ZONES,
});

export const add_user_teams = (user_teams) => ({
	type: ADD_USER_TEAMS,
	user_teams,
});

export const update_biz_list = (business_accounts) => ({
	type: UPDATE_BIZ_LIST,
	business_accounts,
});

export const add_enterprise_child_business_data = (enterprise_child_business_data) => ({
	type: ADD_ENTERPRISE_CHILD_BUSINESS_DATA,
	enterprise_child_business_data,
});
