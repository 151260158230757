import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import clsx from 'clsx';
import Text from 'modules/Text';
import React from 'react';
import theme from 'resources/theme';
import utils from 'resources/utils';

const useStyles = makeStyles(() => ({
	wrapper: {
		padding: 20,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		border: `1px solid ${theme.colors.lightGrey5}`,
		borderRadius: 6,
		backgroundColor: utils.convert_hex_to_rgba(theme.colors.white, 90),
	},
	text_wrap: {
		flex: 1,
		flexDirection: 'column',
	},
	add_rider_text: {
		fontSize: 12,
		color: theme.colors.primary,
		textDecoration: 'underline',
	},
	description: { fontSize: 12, color: theme.colors.darkGrey2, marginTop: 8 },
}));

const NoThreePl = () => {
	const classes = useStyles();
	return (
		<div className={classes.wrapper}>
			<div className={clsx('d-flex justify-content-between', classes.text_wrap)}>
				<div>
					<Text semi style={{ fontSize: 16 }}>
						Uh-oh, no partners available!
					</Text>
					<Text semi className={classes.description}>
						Try again in some time or try changing order size and pickup locations.
					</Text>
				</div>
			</div>

			<img src={ImageLinks.exclation_web} width={100} height={100} alt='' />
		</div>
	);
};

export default NoThreePl;
