import { makeStyles } from '@material-ui/core';

import theme from 'resources/theme';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';

const useStyles = makeStyles(() => ({
	label_subtitle: { fontSize: 12, color: theme.colors.darkGrey2, marginTop: 4 },
	reattempt_info: { display: 'flex', padding: '0px 20px', justifyContent: 'space-between', alignItems: 'flex-start' },
	info_img: { display: 'flex', justifyContent: 'center', gap: 10, alignItems: 'center', flexDirection: 'column', textAlign: 'center' },
}));

const UndeliveredOrdersInfo = () => {
	const classes = useStyles();
	return (
		<div>
			<div>
				<Text semi style={{ fontSize: 14, color: theme.colors.lightPurple5 }}>
					How does reattempt of orders work?
				</Text>
				<Text medium className={classes.label_subtitle} style={{ marginTop: 8 }}>
					Reattempt of orders can be successful done with the following:
				</Text>
			</div>
			<div className={classes.reattempt_info}>
				<div className={classes.info_img}>
					<img src={ImageLinks.network_undelivered_orders_1} width={70} height={70} alt='' />
					<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
						Self or Supplier Warehouse
					</Text>
				</div>
				<div className={classes.info_img}>
					<img src={ImageLinks.network_undelivered_orders_2} width={70} height={70} alt='' />
					<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
						Find the nearest warehouse
					</Text>
				</div>
				<div className={classes.info_img}>
					<img src={ImageLinks.network_undelivered_orders_3} width={70} height={70} alt='' />
					<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
						Manage on Admin App
					</Text>
				</div>
			</div>
		</div>
	);
};

export default UndeliveredOrdersInfo;
