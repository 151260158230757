import Text from 'modules/Text';
import IconButton from 'modules/IconButton';
import React, { useEffect, useState } from 'react';
import theme from 'resources/theme';
import { makeStyles } from '@material-ui/styles';
import cross_black from 'assets/images/icons/cross-black.svg';

import tick_circle_green_solid from 'assets/images/icons/tick-circle-green-solid.svg';
import purple_dot_circle from 'assets/images/icons/purple-dot-circle.svg';
import blank_circle from 'assets/images/icons/blank_circle.svg';
import green_arrow_up from 'assets/images/icons/green-arrow-up.svg';
import purple_arrow_down from 'assets/images/icons/purple-arrow-down.svg';
import dash_line from 'assets/images/icons/dash-line.svg';
import ImageLinks from 'assets/images/ImageLinks';
import constant from 'resources/constant';
import moment from 'moment';
import _ from 'lodash';
import clsx from 'clsx';
import Select from 'modules/Select';
import { failed_options, get_allowable_status, status_options } from '../constants';
import OutlinedButton from 'modules/OutlinedButton';
import ContainedButton from 'modules/ContainedButton';
import api_requests from 'resources/api_requests';
import { toast } from 'react-toastify';
import { MapContainer, TileLayer, Marker, Polyline, useMap } from 'react-leaflet';
import L from 'leaflet';
import { renderToString } from 'react-dom/server';

let map = null;
let marker;

const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	container: {
		position: 'fixed',
		top: 0,
		right: 555,
		height: '100%',
		width: 550,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 50,
		zIndex: 1199,
		overflowY: 'auto',
	},
	trip_type_container: {
		borderRadius: 20,
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: theme.colors.lightPink1,
		padding: '10px 20px',
		width: 'auto',
	},
	pbidContainer: {
		fontSize: 12,
		color: theme.colors.primary,
	},
	mapContainer: { height: '250px', width: '100%', paddingTop: 5, paddingBottom: 10 },
	header: {
		borderBottom: '1px solid lightgrey',
		paddingBottom: 10,
		display: 'flex',
		justifyContent: 'space-between',
	},
	order_id: {
		fontSize: 20,
		color: theme.colors.lightPurple6,
	},
	divider: {
		fontSize: 20,
		color: theme.colors.lightPurple6,
		marginRight: 5,
		marginLeft: 5,
	},
	headerMainId: {
		fontSize: 20,
		color: theme.colors.primary,
	},
	riderNameContainer: {
		borderBottom: '1px solid #E5E5E5',
		display: 'flex',
		justifyContent: 'space-between',
		paddingBottom: '15px',
		alignItems: 'center',
	},
	riderName: {
		fontSize: 10,
		color: theme.colors.lightGrey6,
	},
	iconContainer: {
		width: 116,
		justifyContent: 'space-evenly',
		display: 'flex',
		backgroundColor: theme.colors.primary,
		padding: '8px',
		borderRadius: '4px',
		height: '50px',
	},
	iconContainerSeparator: {
		border: '1px solid #7953D2',
		height: '16px',
		alignSelf: 'center',
		margin: '4px',
		marginLeft: '10px',
	},
	riderLogs: {
		fontSize: 14,
		color: theme.colors.darkGrey2,
		fontWeight: 700,
	},
	riderLogsSubCon: {
		padding: '0 40px',
		backgroundColor: theme.colors.lightGrey7,
		height: '54px',
		marginTop: '10px',
		borderRadius: '6px',
		paddingTop: 12,
	},
	riderStatusBar: { display: 'flex', justifyContent: 'space-between' },
	riderStatusDashLine: { flex: 1, marginTop: -5, alignSelf: 'start' },
	orderStatus: { display: 'flex', padding: '5px 45px', justifyContent: 'space-between' },
	status: { display: 'flex', alignItems: 'center' },
	statusText: { fontSize: 12, color: theme.colors.lightGrey },
	statusTextBold: { fontSize: 12, color: '#000000', fontWeight: 600 },
	marginRight: { marginRight: '5px' },
	status_icon: { display: 'flex', alignItems: 'end', marginRight: 5 },
	trackDetailsContainer: { borderRadius: 10, overflow: 'hidden', padding: 15, marginTop: 10 },
	riderDetails: { borderRadius: 10, overflow: 'hidden', marginTop: 10 },
	display: { display: 'flex' },
	pickUpAddress: {
		fontSize: 12,
		color: theme.colors.darkGrey,
		fontWeight: 700,
		paddingLeft: '21px',
	},
	pickUpDetailsName: { fontSize: 16, fontWeight: 700 },
	pickUpText: {
		fontSize: 12,
		color: theme.colors.darkGrey2,
		'&:hover': { cursor: 'pointer' },
	},
	paddingTop15: { paddingTop: 15 },
	greenTickIcon: { marginRight: 5, opacity: 0.5 },
	greenTickIconNoOpacity: { opacity: 1 },
	underline: { textDecoration: 'underline' },
	footer: {
		width: '100%',
		position: 'fixed',
		bottom: 0,
		right: 555,
		width: 550,
		height: 100,
		backgroundColor: theme.colors.white,
		zIndex: 1500,
		boxShadow: '0px -4px 14px rgba(0, 0, 0, 0.05)',
	},
	status_strip_wrap: {
		display: 'flex',
		flexDirectoin: 'row',
		alignItems: 'center',
		border: `1px solid ${theme.colors.lightPurple}`,
		backgroundColor: theme.colors.lightPurple,
		boxShadow: '0px -4px 14px rgba(0, 0, 0, 0.05)',
		paddingLeft: 50,
		height: 30,
		marginTop: -30,
	},
	status_text: {
		fontSize: 16,
		color: theme.colors.primary,
	},
	footer_btn_wrap: {
		flexDirection: 'row',
		alignItems: 'center',
		display: 'flex',
		height: '100%',
		paddingLeft: 50,
	},
	reset_btn: { width: 125, height: 46, borderRadius: 30, borderWidth: 2 },
	save_btn: {
		width: 125,
		height: 46,
		borderRadius: 30,
		marginLeft: 16,
	},
	container_bottom_padding: {
		paddingBottom: 150,
	},
}));

const TripDetails = ({ close, trip_data, order_id, brand_name, on_update_success, wrapper_styles = {} }) => {
	const [pickup_terminal_status, set_pickup_terminal_status] = React.useState('Pickup');
	const [trip_status, set_trip_status] = useState(trip_data.status_id);
	const [failed_reason, set_failed_reason] = useState('');
	const [show_status_strip, set_show_status_strip] = useState(false);

	const handleClose = () => {
		close();
	};

	function FitBounds({ pickupCoord, dropCoord }) {
		const map = useMap();
		useEffect(() => {
			if (pickupCoord && dropCoord) {
				const bounds = L.latLngBounds([pickupCoord, dropCoord]);
				map.fitBounds(bounds);
			}
		}, [map, pickupCoord, dropCoord]);
		return null;
	}

	const classes = useStyles();

	const attempt_type_text = constant.ATTEMPT_TYPE_TEXT[trip_data.attempt_type];
	const rider_logs_status_pickup = [
		{ text: 'Package Scanned', time: trip_data.timeline.package_scanned },
		{ text: 'Reached Pickup', time: trip_data.timeline.reached_pickup },
		{ text: 'Started for Pickup', time: trip_data.timeline.started_for_pickup },
	];

	const rider_logs_status_terminal = [
		{ text: 'Package Dropped', time: trip_data.timeline.trip_completed },
		{ text: 'Reached Drop', time: trip_data.timeline.reached_drop },
		{ text: 'Started for Drop', time: trip_data.timeline.started_for_drop },
	];

	const allowable_status = get_allowable_status(trip_data.trip_type, trip_data.status_id);

	const on_status_reset = () => {
		set_trip_status(trip_data.status_id);
	};

	const on_status_update = async () => {
		const remark = _.filter(status_options, (item) => item.value === trip_status)[0].title;
		const payload = {
			trip_type: trip_data.trip_type,
			trip_id: trip_data.id,
			action: trip_status,
			remark,
		};
		if (trip_data.group_id) payload.group_id = trip_data.group_id;
		if (trip_status == 5) {
			if (!failed_reason) {
				toast.error('Please select reason for failed delivery.', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
				return;
			}
			const reason_str = _.filter(failed_options, (item) => item.value === failed_reason)[0].title;
			payload.remark = reason_str;
		}

		try {
			const response = await (trip_status === 130
				? api_requests.auto_quick_scan({
						trip_type: trip_data.trip_type,
						group_id: trip_data.group_id,
						remark: 'admin quick scan',
				  })
				: api_requests.update_trip_status(payload));
			if (response.success) {
				toast.success('Status updated successfully.', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
				on_update_success();
				close();
			}
		} catch (error) {}
	};

	return (
		<>
			<div className={classes.closeContainer} onClick={close}></div>
			<div className={clsx(classes.container, trip_status != trip_data.status_id && classes.container_bottom_padding)} style={wrapper_styles}>
				<Text bold className={classes.pbidContainer}>
					{brand_name}
				</Text>
				<div className={classes.header}>
					<div className={classes.display} style={{ alignItems: 'center' }}>
						<Text bold className={classes.order_id}>
							{order_id}
						</Text>
						<Text className={classes.divider}>|</Text>
						<Text bold className={classes.headerMainId}>
							{trip_data.id}
						</Text>
						{attempt_type_text && (
							<div style={{ marginLeft: 20 }}>
								<div className={classes.trip_type_container}>
									<Text bold style={{ color: theme.colors.darkPurple, fontSize: 12 }}>
										{attempt_type_text}
									</Text>
								</div>
							</div>
						)}
					</div>
					<IconButton>
						<img src={cross_black} width={14} height={14} onClick={handleClose} alt='' />
					</IconButton>
				</div>

				<div className={`${classes.riderDetails} box-shadow `}>
					<div style={{ padding: 15, paddingTop: 30 }}>
						<div className={classes.riderNameContainer}>
							<div>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<img src={ImageLinks.rider_light_purple} alt='' />
									<div style={{ marginLeft: 20 }}>
										<Text semi style={{ fontSize: 14 }}>
											{!_.isEmpty(trip_data.rider.name) ? trip_data.rider.name : '-'} ({trip_data.rider.id || '-'})
										</Text>
										<Text className={classes.riderName}>Rider</Text>
										{/* <div style={{ marginTop: 5 }}>
											<Text semi style={{ fontSize: 14, color: theme.colors.primary }}>
												{constant.TRIP_STATUS_TEXT[trip_data.status_id]}
											</Text>
										</div> */}
										{trip_status >= 80 && (
											<Select
												value={trip_status === 80 ? '' : trip_status}
												defaultOption={{
													title: 'Rider Allocated',
													value: '',
												}}
												options={_.map(status_options, (item) => {
													return {
														title: item.title,
														value: item.value,
														disabled: !allowable_status.includes(item.value),
													};
												})}
												handleChange={(v) => {
													if (
														(trip_data.trip_type == 5 && (v == 90 || v == 100 || v == 120 || v == 130)) ||
														(trip_data.trip_type == 3 && (v == 150 || v == 170 || v == 190 || v == 5))
													)
														set_show_status_strip(false);
													else set_show_status_strip(true);
													set_trip_status(v);
												}}
												containerStyles={{ height: 40, width: 180, marginTop: 5 }}
												text_props={{ semi: true }}
												menu_styles={{
													width: 180,
													height: 200,
												}}
											/>
										)}
									</div>
								</div>
								{trip_status == 5 && (
									<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
										<Text semi style={{ fontSize: 14 }}>
											Reason:{' '}
										</Text>
										<Select
											value={failed_reason}
											defaultOption={{
												title: `Choose Remarks`,
												value: '',
											}}
											options={failed_options}
											handleChange={(v) => {
												set_failed_reason(v);
											}}
											containerStyles={{ height: 40, width: 180, marginLeft: 10 }}
											text_props={{ semi: true }}
											menu_styles={{
												width: 250,
												height: 200,
											}}
										/>
									</div>
								)}
							</div>
							{/* <div className={classes.iconContainer}>
	                            <div style={{ marginLeft: 2 }}><IconButton style={{ border: 'none' }}><img src={changeRider} width='48' height='32' /></IconButton></div>
	                            <div className={classes.iconContainerSeparator}></div>
	                            <div><IconButton style={{ border: 'none' }}><img src={unassign} width='48' height='32' /></IconButton></div>
	                        </div> */}
						</div>
						<div>
							<div className={classes.paddingTop15}>
								<Text bold className={classes.riderLogs}>
									Rider Logs
								</Text>
								<div className={classes.riderLogsSubCon}>
									<div className={classes.riderStatusBar}>
										<div>
											{trip_data.timeline.package_scanned ? (
												<img src={tick_circle_green_solid} width='24' height='16' alt='' />
											) : (
												<img src={purple_dot_circle} width='24' height='16' alt='' />
											)}
										</div>
										<div className={classes.riderStatusDashLine}>
											<img src={dash_line} width='100%' height='5' alt='' />
										</div>
										<div>
											{trip_data.timeline.trip_completed ? (
												<img src={tick_circle_green_solid} width='24' height='16' alt='' />
											) : trip_data.timeline.package_scanned ? (
												<img src={purple_dot_circle} width='24' height='16' alt='' />
											) : (
												<img src={blank_circle} width='24' height='16' alt='' />
											)}
										</div>
									</div>
									<div className={classes.riderStatusBar} style={{ width: '104%', marginLeft: '-3px' }}>
										<Text
											bold
											className={clsx(classes.pickUpText, pickup_terminal_status === 'Pickup' && classes.underline)}
											onClick={() => set_pickup_terminal_status('Pickup')}>
											Pickup
										</Text>
										<Text
											bold
											className={clsx(classes.pickUpText, pickup_terminal_status === 'Terminal' && classes.underline)}
											onClick={() => set_pickup_terminal_status('Terminal')}>
											Terminal
										</Text>
									</div>
								</div>
								<div>
									{pickup_terminal_status === 'Pickup' &&
										_.map(rider_logs_status_pickup, (item, index) => {
											return (
												<div className={classes.orderStatus} style={{ paddingTop: '10px' }}>
													<div className={classes.status}>
														<div className={clsx(classes.greenTickIcon, _.isEmpty(item.time) && classes.greenTickIconNoOpacity)}>
															{item.time ? (
																<img src={tick_circle_green_solid} width='12' height='12' alt='' />
															) : (
																<img src={blank_circle} width='12' height='12' alt='' />
															)}
														</div>
														<Text className={clsx(classes.statusText, _.isEmpty(item.time) && classes.statusTextBold)}>
															{item.text}
														</Text>
													</div>
													<Text className={clsx(classes.statusText, _.isEmpty(item.time) && classes.statusTextBold)}>
														{!_.isNull(item.time) && !_.isEmpty(item.time) ? moment(item.time).format('hh:mm A') : '-'}
													</Text>
												</div>
											);
										})}

									{pickup_terminal_status === 'Terminal' &&
										_.map(rider_logs_status_terminal, (item, index) => {
											return (
												<div className={classes.orderStatus} style={{ paddingTop: '10px' }}>
													<div className={classes.status}>
														<div className={clsx(classes.greenTickIcon, _.isEmpty(item.time) && classes.greenTickIconNoOpacity)}>
															{item.time ? (
																<img src={tick_circle_green_solid} width='12' height='12' alt='' />
															) : (
																<img src={blank_circle} width='12' height='12' alt='' />
															)}
														</div>
														<Text className={clsx(classes.statusText, _.isEmpty(item.time) && classes.statusTextBold)}>
															{item.text}
														</Text>
													</div>
													<Text className={clsx(classes.statusText, _.isEmpty(item.time) && classes.statusTextBold)}>
														{!_.isNull(item.time) && !_.isEmpty(item.time) ? moment(item.time).format('hh:mm A') : '-'}
													</Text>
												</div>
											);
										})}
								</div>

								{/* <div>
									<div className={classes.orderStatus} style={{ paddingTop: '10px' }}>
										<div className={classes.status}>
											<div className={classes.greenTickIcon}>
												<img src={tick_circle_green_solid} width='12' height='12' />
											</div>
											<Text className={classes.statusText}>Package Scanned</Text>
										</div>
										<Text className={classes.statusText}>{moment(trip_data.timeline.package_scanned).format('hh:mm A')}</Text>
									</div>
									<div className={classes.orderStatus}>
										<div className={classes.status}>
											<div className={classes.greenTickIcon}>
												<img src={tick_circle_green_solid} width='12' height='12' />
											</div>
											<Text className={classes.statusText}>Reached Pickup</Text>
										</div>
										<Text className={classes.statusText}>{moment(trip_data.timeline.reached_pickup).format('hh:mm A')}</Text>
									</div>
									<div className={classes.orderStatus}>
										<div className={classes.status}>
											<div className={classes.greenTickIcon}>
												<img src={tick_circle_green_solid} width='12' height='12' />
											</div>
											<Text className={classes.statusText}>Started for Pickup</Text>
										</div>
										<Text className={classes.statusText}>{moment(trip_data.timeline.started_for_pickup).format('hh:mm A')}</Text>
									</div>
								</div> */}
							</div>
						</div>
					</div>
				</div>

				<div className={classes.paddingTop15}>
					<Text style={{ fontSize: 14, color: theme.colors.darkGrey2, fontWeight: 700 }}>Location Details</Text>
					<div className={classes.mapContainer}>
						<MapContainer
							style={{ height: '100%', borderRadius: 10, zIndex: 1 }}
							key={'active_route_map'}
							center={{
								lat: Number(trip_data.drop_details.address.exact_location.latitude),
								lng: Number(trip_data.drop_details.address.exact_location.longitude),
							}}
							zoom={10}
							scrollWheelZoom={true}>
							<TileLayer
								attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
								url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
							/>
							<FitBounds
								pickupCoord={[
									Number(trip_data.pickup_details.address.exact_location.latitude),
									Number(trip_data.pickup_details.address.exact_location.longitude),
								]}
								dropCoord={[
									Number(trip_data.drop_details.address.exact_location.latitude),
									Number(trip_data.drop_details.address.exact_location.longitude),
								]}
							/>
							<Marker
								position={{
									lat: Number(trip_data.pickup_details.address.exact_location.latitude),
									lng: Number(trip_data.pickup_details.address.exact_location.longitude),
								}}
								icon={L.divIcon({
									className: 'custom-icon',
									html: renderToString(
										<div
											style={{
												position: 'relative',
												height: 32,
												width: 32,
												transform: 'translate(-6px,-24px)',
											}}>
											<img src={ImageLinks.rider_pickup} width={32} height={32} />
										</div>,
									),
								})}
							/>
							<Marker
								position={{
									lat: Number(trip_data.drop_details.address.exact_location.latitude),
									lng: Number(trip_data.drop_details.address.exact_location.longitude),
								}}
								icon={L.divIcon({
									className: 'custom-icon',
									html: renderToString(
										<div
											style={{
												position: 'relative',
												height: 32,
												width: 32,
												transform: 'translate(-6px,-24px)',
											}}>
											<img src={ImageLinks.marker_down_arrow} width={32} height={32} />
										</div>,
									),
								})}
							/>
						</MapContainer>
					</div>
				</div>

				<div className={`${classes.trackDetailsContainer} box-shadow`}>
					<div style={{ borderBottom: '1px solid #E5E5E5', paddingBottom: '15px' }}>
						<div className={classes.display}>
							<div className={classes.status_icon}>
								<img src={green_arrow_up} width='15' height='18' alt='' />
							</div>
							<Text bold className={classes.pickUpDetailsName}>
								{trip_data.pickup_details.name}
							</Text>
						</div>
						<Text className={classes.pickUpAddress}>{trip_data.pickup_details.address.address_line1}</Text>
					</div>
					<div className={classes.paddingTop15} style={{ paddingLeft: '21px' }}>
						<Text semi style={{ fontSize: 12 }}>
							{trip_data.pickup_details.instruction_to_reach || '-'}
						</Text>
						<Text bold style={{ fontSize: 10, color: theme.colors.lightGrey6 }}>
							Instructions to Reach
						</Text>
					</div>
				</div>

				<div className={`${classes.trackDetailsContainer} box-shadow`}>
					<div style={{ borderBottom: '1px solid #E5E5E5', paddingBottom: '15px' }}>
						<div className={classes.display}>
							<div className={classes.status_icon}>
								<img src={purple_arrow_down} width='15' height='18' alt='' />
							</div>
							<Text bold className={classes.pickUpDetailsName}>
								{trip_data.drop_details.name}
							</Text>
						</div>
						<Text className={classes.pickUpAddress}>{trip_data.drop_details.address.address_line1}</Text>
					</div>
					<div className={classes.paddingTop15} style={{ paddingLeft: '21px' }}>
						<Text semi style={{ fontSize: 12 }}>
							{trip_data.drop_details.instruction_to_reach || '-'}
						</Text>
						<Text bold style={{ fontSize: 10, color: theme.colors.lightGrey6 }}>
							Instructions to Reach
						</Text>
					</div>
				</div>
				{trip_status != trip_data.status_id && (
					<div className={classes.footer} style={wrapper_styles}>
						{show_status_strip && (
							<div className={classes.status_strip_wrap}>
								<Text bold className={classes.status_text}>
									Please Note:
								</Text>
								<Text semi className={classes.status_text}>
									Status change will affect all trips in route.
								</Text>
							</div>
						)}
						<div className={classes.footer_btn_wrap}>
							<OutlinedButton className={classes.reset_btn} onClick={on_status_reset}>
								<img src={ImageLinks.refresh_purple} width={16} height={26} alt='' />
								<Text semi style={{ fontSize: 18, color: theme.colors.primary, marginLeft: 6 }}>
									Reset
								</Text>
							</OutlinedButton>
							<ContainedButton onClick={on_status_update} className={classes.save_btn}>
								<Text semi style={{ fontSize: 18, color: theme.colors.white }}>
									Save
								</Text>
							</ContainedButton>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default TripDetails;
