import * as React from 'react';
import Backdrop from '@material-ui/core/Backdrop';

import theme from 'resources/theme';
import Lottie from 'react-lottie';
import Animations from 'assets/lottie/Animations';

const Loader = ({ visible = false, component = false, component_style = {} }) => {
	return (
		<>
			{component ? (
				<div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', ...component_style }}>
					<Lottie
						options={{
							loop: true,
							autoplay: true,
							animationData: Animations.app_loader,
							rendererSettings: {
								preserveAspectRatio: 'xMidYMid slice',
							},
						}}
						height={150}
						width={150}
					/>
				</div>
			) : (
				<div
					style={{
						position: 'absolute',
						width: '100%',
						height: 0,
						top: 0,
						left: 0,
						zIndex: 9999,
						pointerEvents: visible ? 'auto' : 'none',
					}}>
					<Backdrop sx={{ color: theme.colors.primary, zIndex: (theme) => theme.zIndex.drawer + 1 }} open={visible}>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								animationData: Animations.app_loader,
								rendererSettings: {
									preserveAspectRatio: 'xMidYMid slice',
								},
							}}
							height={200}
							width={220}
						/>
					</Backdrop>
				</div>
			)}
		</>
	);
};

export default Loader;
