import React, { useEffect, useMemo, useState } from 'react';
import theme from 'resources/theme';
import moment from 'moment';
import { Dialog, DialogContent, Divider } from '@material-ui/core';
import { DateRangePicker } from 'materialui-daterange-picker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import clsx from 'clsx';

import Text from 'modules/Text';
import IconButton from 'modules/IconButton';

import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';

import OutlinedButton from 'modules/OutlinedButton';
import Select from 'modules/Select';
import OutlinedTextField from 'modules/OutlinedTextField';
import Switch from 'modules/Switch';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import FilterReportModal from 'modules/modals/FilterReportModal';
import { shallowEqual, useSelector } from 'react-redux';
import DateTimeInput from 'modules/DateTimeInput';
import { toast } from 'react-toastify';
import Button from 'modules/Button';
import { useStyles } from './style';
import TimeInput from 'modules/TimeInput';
import { util } from 'prettier';
import utils from 'resources/utils';

const initial_date_picker_details = {
	open: false,
	param: '',
};

const network_options_key = ['fulfillment_network_id', 'schedule_network_id', 'network_id'];
const master_zone_key = ['pickup_master_zone', 'drop_master_zone', 'master_zone_id'];

const Filters = ({
	close,
	all_filters: original_filter,
	set_selected_filters,
	title = 'Order',
	handle_export,
	source = 'default',
	report_items = [],
	report_filters = [],
	existing_filters = {},
	is_report_enabled = false,
	filter_from = '',
	is_report_item_single_select = false,
	skip_date_check = false,
	date_check_array = [],
	handle_custom_search = () => {},
	handle_custom_reset = () => {},
}) => {
	const { network_list, enterprise_child_business_data } = useSelector(
		(state) => ({
			network_list: state.app.app_configs.network_configs,
			enterprise_child_business_data: state.app.enterprise_child_business_data,
		}),
		shallowEqual,
	);
	const { zones } = useSelector(
		(state) => ({
			zones: state.app.zones,
		}),
		shallowEqual,
	);
	const [all_filters, set_all_filters] = useState(original_filter);

	const network_options = _.map(network_list, (network, index) => {
		return { title: network.name, value: index };
	});
	const master_zone = _.map(zones, (zone) => {
		return { title: zone.name, value: `${zone.id}` };
	});

	const child_biz_options = _.map(enterprise_child_business_data, (child_biz, index) => {
		return { title: child_biz.brand_name, value: `${child_biz.id}` };
	});

	const text_filters = _.filter(all_filters, (f) => {
		return f.type === 'text';
	});

	const text_filters_options = _.map(text_filters, (filter, index) => {
		return { title: filter.title, value: filter.param };
	});

	const not_text_filters = _.filter(all_filters, (f) => {
		return f.type !== 'text';
	});

	const [temp_selected_filters, set_temp_selected_filters] = useState({});
	const [selected_text_filter_param, set_selected_text_filter_param] = useState(text_filters_options[0]?.value);
	const [text_filter_value, set_text_filter_value] = useState('');
	const [date_picker_details, set_date_picker_details] = useState({ ...initial_date_picker_details });

	const [delivery_slots, set_delivery_slots] = useState([]);
	const [show_report_modal, set_show_report_modal] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		if (_.isEmpty(existing_filters)) {
			return;
		}
		const non_text_filters = {};
		for (let i = 0; i < all_filters.length; i++) {
			const filter = all_filters[i];
			const is_filter_applied = !_.isEmpty(existing_filters[filter.param]) || existing_filters[filter.param] != undefined;
			if (!is_filter_applied) continue;
			if (filter.type === 'text') {
				set_selected_text_filter_param(filter.param);
				set_text_filter_value(existing_filters[filter.param]);
			}
			if ((filter.type === 'dropdown' || filter.type === 'option') && filter.multi) {
				let result_arr = [];
				const applied_filter_value = existing_filters[filter.param];
				if (_.includes(network_options_key, filter.param?.toLowerCase())) {
					result_arr.push(...existing_filters[filter.param].split(','));
				}
				if (_.includes(master_zone_key, filter.param?.toLowerCase())) {
					result_arr.push(...existing_filters[filter.param].split(','));
				}
				if (filter.param?.toLowerCase() === 'owner_ids') {
					result_arr.push(...existing_filters[filter.param].split(','));
				}
				for (let j = 0; j < filter.values?.length; j++) {
					const values_element = filter.values[j];
					if (
						_.includes(applied_filter_value, ',' + values_element?.value + ',') ||
						_.startsWith(applied_filter_value, values_element?.value + ',') ||
						_.endsWith(applied_filter_value, ',' + values_element?.value) ||
						applied_filter_value == values_element?.value
					)
						result_arr.push(values_element?.value);
				}
				non_text_filters[filter.param] = [...result_arr];
			} else if (filter.type === 'boolean') {
				const string_value = existing_filters[filter.param];
				const boolean_value = string_value === 'true';
				non_text_filters[filter.param] = boolean_value;
			} else {
				non_text_filters[filter.param] = existing_filters[filter.param];
			}
		}
		set_temp_selected_filters(non_text_filters);
	}, [existing_filters]);

	const handle_change = (name, value) => {
		set_temp_selected_filters({
			...temp_selected_filters,
			[name]: value,
		});
	};
	const handle_delete = (filter) => {
		const _temp_selected_filters = _.cloneDeep(temp_selected_filters);
		delete _temp_selected_filters[filter.param];
		if (filter.dependant) {
			delete _temp_selected_filters[filter.dependant];
		}
		set_temp_selected_filters(_temp_selected_filters);
	};
	const handle_reset = () => {
		set_temp_selected_filters({});
		set_text_filter_value('');
		set_selected_text_filter_param(text_filters_options[0]?.value);
		set_show_report_modal(false);
		set_selected_filters({});
		handle_custom_reset?.();
	};

	const get_text_search_placeholder = () => {
		switch (selected_text_filter_param) {
			case 'mcp_id':
				return 'Enter PBID';
			case 'ref_id':
				return 'Enter Reference ID';
			case 'rider_id':
			case 'entity_id':
				return 'Enter Rider ID';
			case 'customer_mobile':
				return 'Enter customer mobile no.';
			case 'channel_order_id':
				return 'Enter Order ID';
			case 'pickup_pincode':
			case 'delivery_pincode':
				return 'Enter 6 digit pincode';
			case 'min_pickup_drop_dist':
			case 'max_pickup_drop_dist':
				return 'Enter KM';
			case 'fulfillment_order_id':
				return 'Enter Partner ID';
			case 'registration_number':
			case 'entity_name':
				return 'Enter vehicle no.';
			case 'capacity':
				return 'Enter Capacity';
			default:
				return 'Select Search Criteria';
		}
	};

	const validate_group_keys = (filters) => {
		// Filter out objects without a defined group_name, then extract unique group_names
		const group_name_set = new Set(
			_.map(
				_.filter(all_filters, (f) => !_.isEmpty(f.group)),
				(item) => item.group,
			),
		);

		// If there are no group_names in the array, it's valid
		if (group_name_set.size === 0) {
			return { is_valid: true, error_message: '' };
		}

		// Track invalid group_names
		const invalid_group_names = [];
		const missing_params = [];
		// Loop through each group_name
		for (const group_name of group_name_set) {
			const objects_with_group_name = _.filter(all_filters, (item) => item.group === group_name && item.required);
			// Check if all keys with this group_name exist in obj
			const keys_present_in_obj = _.filter(objects_with_group_name, (item) => filters.hasOwnProperty(item.param) && !_.isEmpty(filters[item.param]));
			// If some keys are present and others are missing, mark this group_name as invalid
			if (keys_present_in_obj.length !== objects_with_group_name.length && keys_present_in_obj.length > 0) {
				invalid_group_names.push(group_name);
				const missing = _.differenceBy(objects_with_group_name, keys_present_in_obj, 'param');
				missing_params.push(...missing);
				break;
			}
		}

		// Construct an error message with missing param titles
		let error_message = '';
		for (const item of missing_params) {
			error_message += `${item.title} is required. `;
		}

		// If there are invalid group_names or if no group_names exist in obj, return false
		if (invalid_group_names.length > 0) {
			return { is_valid: false, error_message };
		}

		return { is_valid: true, error_message: '' };
	};

	const handle_search = () => {
		const _temp_selected_filters = {};
		_.map(temp_selected_filters, (value, key) => {
			if (_.isArray(value)) {
				value = value.join(',').trim();
			}
			if (_.isBoolean(value) || _.isNumber(value)) {
				_temp_selected_filters[key] = value;
			} else {
				_temp_selected_filters[key] = value?.trim();
			}
			return value;
		});
		const all_group_filters_selected = validate_group_keys(_temp_selected_filters);
		if (!all_group_filters_selected.is_valid) {
			toast.error(all_group_filters_selected.error_message, {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			return;
		}

		const result = {
			..._temp_selected_filters,
			...(!_.isEmpty(text_filter_value.trim()) && { [selected_text_filter_param]: text_filter_value.trim() }),
		};
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.GLOBAL_FILTER, {
			filter: result,
		});
		set_selected_filters(result);
		handle_custom_search?.(result);
		close();
	};

	const _handle_export = (report_filters, callback = () => {}) => {
		if (!is_any_filter_selected && !is_report_enabled) return;
		const _temp_selected_filters = {};
		_.map(temp_selected_filters, (value, key) => {
			if (_.isArray(value)) {
				value = value.join(',');
			}
			if (value) _temp_selected_filters[key] = value;
			return value;
		});
		const combined_filtes = {
			..._temp_selected_filters,
			...(!_.isEmpty(text_filter_value) && { [selected_text_filter_param]: text_filter_value }),
			...report_filters,
		};
		handle_export(combined_filtes, callback);
	};

	const get_delivery_slots = (selected_delivery_date) => {
		let url = source === 'all_orders' ? 'store/bop/filter/delivery-slots' : 'user/bop/filter/delivery-slots';
		let base_url = source === 'all_orders' ? constant.BASE_URL.STORE : constant.BASE_URL.DEFAULT;
		api_requests
			.get_filter_delivery_slots(
				{
					delivery_date: selected_delivery_date,
					...(source !== 'pwp' && { group: title.split(' ').join('_').toLowerCase() }),
					...(base_url === constant.BASE_URL.DEFAULT && { unified_status: 1 }),
				},
				url,
				base_url,
			)
			.then((res) => {
				set_delivery_slots(res.data.items);
			});
	};
	const handle_change_select = (val) => {
		set_selected_text_filter_param(val);
		set_text_filter_value('');
		const _temp_selected_filters = _.cloneDeep(temp_selected_filters);
		text_filters_options.forEach((item) => {
			if (_temp_selected_filters.hasOwnProperty(item.value)) {
				delete _temp_selected_filters[item.value];
			}
		});
		set_temp_selected_filters(_temp_selected_filters);
	};

	const handle_change_dependant = (filter, value, v) => {
		let val = [...(temp_selected_filters[filter.dependant] || [])];
		if (!_.isEmpty(val)) {
			const selected_zone = _.filter(zones, (zone) => {
				return zone.id == v;
			});
			let idsToRemove = _.map(selected_zone[0].business_master_zones, (item) => item.id);
			val = _.filter(val, (id) => !idsToRemove.includes(id));
			set_temp_selected_filters({
				...temp_selected_filters,
				[filter.dependant]: val,
				[filter.param]: value,
			});
		} else {
			set_temp_selected_filters({
				...temp_selected_filters,
				[filter.param]: value,
			});
		}
	};
	const handle_select_drop_down = (v, filter) => {
		if (filter.multi) {
			let val = [...(temp_selected_filters[filter.param] || [])];
			const index = _.indexOf(val, v);
			if (index !== -1) {
				val.splice(index, 1);
			} else {
				val.push(v);
			}
			if (_.isEmpty(val)) {
				handle_delete(filter);
			} else {
				if (!filter.dependant) {
					handle_change(filter.param, val);
				} else {
					handle_change_dependant(filter, val, v);
				}
			}
			if (filter.dependant) {
				_.map(all_filters, (val) => {
					if (val.param == filter.dependant) {
						const is_zone_exits = val.values.some((item) => item.master_zone_id == v);
						if (is_zone_exits) {
							const update_sub_zone = _.filter(val.values, (zone) => {
								return zone.master_zone_id != v;
							});
							val.values = update_sub_zone;
							set_all_filters(all_filters);
						} else {
							const selected_master_zone = _.filter(zones, (zone) => {
								return zone.id == v;
							});
							if (!_.isEmpty(selected_master_zone)) {
								const zone = _.map(selected_master_zone[0].business_master_zones, (zone) => {
									return {
										title: zone.title,
										value: zone.id,
										master_zone_id: zone.master_zone_id,
									};
								});
								val.values = _.concat(val.values, zone);
								set_all_filters(all_filters);
							}
						}
					}
				});
			}
			return;
		}
		handle_change(filter.param, v);
	};
	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			handle_search();
		}
	};

	const is_any_filter_selected = !_.isEmpty(temp_selected_filters) || (!_.isEmpty(text_filter_value) && !_.isEmpty(selected_text_filter_param));

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={classes.container}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Text semi style={{ fontSize: 18, color: theme.colors.primary }}>
							Filter & Search
						</Text>
					</div>
					<IconButton onClick={close} style={{ padding: 10 }}>
						<img src={ImageLinks.cross_light} width={16} height={16} alt='cross' />
					</IconButton>
				</div>
				<Divider style={{ marginTop: 0, marginBottom: 12 }} />
				{!_.isEmpty(text_filters) && (
					<div className={classes.search_bar_container}>
						<div className='d-flex align-items-center' style={{ justifyContent: 'space-between', width: '100%' }}>
							<Select
								value={selected_text_filter_param}
								options={text_filters_options}
								handleChange={(val) => handle_change_select(val)}
								containerStyles={{ height: 20, width: 'fit-content', background: theme.colors.white, marginLeft: 8 }}
								text_styles={{ fontSize: 12, width: '100%' }}
								right_icon={ImageLinks.arrow_up_pink}
								image_styles={{ width: 10, marginLeft: 8 }}
								menu_styles={{ marginTop: 30 }}
								menu_item_class={{ className: 'sidebar_input_dropdown' }}
								text_props={{ className: 'sidebar_input_dropdown' }}
							/>
							<div style={{ flex: 1, marginLeft: 10, width: 250 }}>
								<input
									id='input_search_filter'
									placeholder={get_text_search_placeholder()}
									value={text_filter_value}
									onChange={(e) => set_text_filter_value(e.target.value)}
									className={classes.input_filed}
									onKeyDown={handleKeyDown}
								/>
							</div>
							<div style={{ marginRight: 12, marginTop: 5 }}>
								<img
									src={text_filter_value.length ? ImageLinks.arrow_right_light_purple : ImageLinks.search_light_pink}
									alt='purple-right-arrow'
									width={18}
									height={18}
									className='cursor-pointer'
								/>
							</div>
						</div>
					</div>
				)}

				<div className={classes.emptyContainer}>
					{!_.isEmpty(not_text_filters) && (
						<>
							<div className={classes.not_text_filters_wrapper}>
								{_.map(not_text_filters, (filter, key) => {
									const is_anchor_top = key > 2;
									return (
										<>
											<div className={classes.not_text_filters_text} key={`filter${key}`} style={{ ...(key !== 0 && { marginTop: 20 }) }}>
												<Text semi style={{ width: '100%', color: theme.colors.darkGrey2 }}>
													{filter.title}
												</Text>
												<div style={{ width: '100%' }}>
													{(filter.type === 'option' || filter.type === 'radio') && (
														<div style={{ flex: 1 }}>
															{_.map(filter.values, (value, key2) => {
																const current_val = temp_selected_filters[filter.param];
																const isSelected = current_val === value.value || _.includes(current_val, value.value);
																return (
																	<OutlinedButton
																		key={`option${key2}`}
																		onClick={() => {
																			if (filter.type === 'option') {
																				let val = [...(temp_selected_filters[filter.param] || [])];
																				const index = _.indexOf(val, value.value);

																				if (index !== -1) {
																					val.splice(index, 1);
																				} else {
																					val.push(value.value);
																				}

																				if (_.isEmpty(val)) {
																					handle_delete(filter);
																				} else {
																					handle_change(filter.param, val);
																				}

																				return;
																			}

																			if (filter.type === 'radio') {
																				let val = temp_selected_filters[filter.param];

																				if (val === value.value) {
																					handle_delete(filter);
																				} else {
																					handle_change(filter.param, value.value);
																				}
																			}
																		}}
																		style={{
																			marginTop: 8,
																			marginRight: 8,
																			height: 28,
																			...(isSelected && { backgroundColor: theme.colors.lightPurple }),
																		}}>
																		<Text semi>{value.title}</Text>
																	</OutlinedButton>
																);
															})}
														</div>
													)}
													{filter.type === 'dropdown' && (
														<div style={{ flex: 1 }}>
															<Select
																value={temp_selected_filters[filter.param] || (filter.multi ? [] : '')}
																defaultOption={{
																	title: `Select`,
																	value: '',
																}}
																disabled={
																	_.isEmpty(filter.values) &&
																	!_.includes(network_options_key, filter.param?.toLowerCase()) &&
																	!_.includes(master_zone_key, filter.param?.toLowerCase()) &&
																	filter.param?.toLowerCase() !== 'owner_ids'
																}
																options={
																	_.includes(network_options_key, filter.param?.toLowerCase())
																		? network_options
																		: _.includes(master_zone_key, filter.param?.toLowerCase())
																		? master_zone
																		: filter.param?.toLowerCase() === 'owner_ids'
																		? child_biz_options
																		: !_.isEmpty(filter.values)
																		? filter.values
																		: ''
																}
																handleChange={(v) => handle_select_drop_down(v, filter)}
																containerStyles={{
																	height: 36,
																	width: 140,
																	marginTop: 5,
																	border: `1px solid ${theme.colors.lightPurple}`,
																	boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
																}}
																text_props={{ semi: true }}
																menu_styles={{
																	width: 180,
																	height: 'fit-content',
																	...(is_anchor_top && { marginTop: -60 }),
																}}
																multiple={filter.multi}
															/>
														</div>
													)}

													{filter.type === 'date' && !filter.multi && (
														<>
															<div style={{ marginTop: 5 }}>
																<DatePicker
																	dateFormat='yyyy-MM-dd'
																	onChange={(date) => {
																		const selected_date = moment(date).format('YYYY-MM-DD');
																		handle_change(filter.param, selected_date);
																		if (filter.param === 'delivery_date') {
																			get_delivery_slots(selected_date);
																		}
																	}}
																	customInput={
																		<div className={classes.date_wrapper}>
																			<Text semi style={{ color: theme.colors.primary }}>
																				{temp_selected_filters[filter.param]
																					? moment(temp_selected_filters[filter.param]).format('DD MMM `YY')
																					: 'Select Date'}
																			</Text>
																			<img
																				src={ImageLinks.calendar_solid_circle}
																				alt='date'
																				className='cursor-pointer'
																				width={20}
																				height={20}
																			/>
																		</div>
																	}
																/>
															</div>
														</>
													)}

													{filter.type === 'input' && (
														<div style={{ flex: 1, marginTop: 5 }}>
															<OutlinedTextField
																type='text'
																value={temp_selected_filters[filter.param]}
																textStyle={{ fontSize: 14, color: theme.colors.darkGrey2 }}
																onChange={(e) => {
																	const value = e.target.value;
																	if (_.isEmpty(value)) handle_delete(filter);
																	else handle_change(filter.param, value);
																}}
																inputProps={{ style: { height: 10 } }}
																label='Enter Value'
																className={classes.root}
																style={{ width: 235 }}
																FormHelperTextProps={{
																	alignItems: 'center',
																	textAlign: 'center',
																}}
																onKeyDown={handleKeyDown}
															/>
														</div>
													)}

													{filter.type === 'date-time' && !filter.multi && (
														<>
															<div className='d-flex align-items-center' style={{ flex: 1, gap: 20, marginTop: 3 }}>
																<DateTimeInput
																	on_change={(value) => handle_change(filter.param, value)}
																	initial_value={
																		temp_selected_filters[filter.param] ? moment(temp_selected_filters[filter.param]) : null
																	}
																/>
															</div>
														</>
													)}

													{filter.type === 'time' && (
														<>
															<div className='d-flex align-items-center' style={{ flex: 1, gap: 20, marginTop: 3 }}>
																<TimeInput
																	value={
																		temp_selected_filters[filter.param]
																			? moment(temp_selected_filters[filter.param]).format('HH:mm')
																			: null
																	}
																	handleChange={(value) => {
																		const current_date = moment().format('YYYY-MM-DD');
																		const date_string = `${current_date} ${value}`;
																		const moment_date = moment(date_string, 'YYYY-MM-DD HH mm').utc().toISOString();
																		setTimeout(() => {
																			handle_change(filter.param, moment_date);
																		}, 0);
																	}}
																/>
															</div>
														</>
													)}

													{filter.type === 'date' && filter.multi && (
														<div
															className='cursor-pointer'
															style={{ flex: 1, marginTop: 4 }}
															onClick={() =>
																set_date_picker_details({
																	open: true,
																	param: filter.param,
																})
															}>
															<div className={classes.multi_date_picker}>
																<Text semi style={{ color: theme.colors.primary }}>
																	{temp_selected_filters[filter.param]
																		? `${moment(temp_selected_filters[filter.param]?.split(',')[0]).format(
																				'DD MMM `YY',
																		  )} - ${moment(temp_selected_filters[filter.param]?.split(',')[1]).format(
																				'DD MMM `YY',
																		  )}`
																		: 'Select Date(s)'}
																</Text>
																<img
																	src={ImageLinks.calendar_solid_circle}
																	className='cursor-pointer'
																	width={20}
																	height={20}
																	alt=''
																/>
															</div>
														</div>
													)}

													{filter.type === 'boolean' && (
														<div style={{ flex: 1, marginLeft: 0 }}>
															<Switch
																checked={_.get(temp_selected_filters, `[${filter.param}]`, false)}
																onChange={(e) => {
																	handle_change(filter.param, e.target.checked);
																}}
															/>
														</div>
													)}
												</div>
											</div>
											{filter.param === 'delivery_date' && !_.isEmpty(delivery_slots) && (
												<div className={classes.delivery_date} key={`filter${key}`} style={{ ...(key !== 0 && { marginTop: 20 }) }}>
													<Text semi style={{ width: '20%', color: theme.colors.darkGrey2 }}>
														Delivery Slots
													</Text>
													<div style={{ width: '70%' }}>
														<div style={{ flex: 1, marginLeft: 10 }}>
															<Select
																value={temp_selected_filters['delivery_slot'] || ''}
																defaultOption={{
																	title: `Select`,
																	value: '',
																}}
																options={delivery_slots}
																handleChange={(v) => {
																	handle_change('delivery_slot', v);
																}}
																containerStyles={{
																	height: 36,
																	width: 140,
																	marginTop: 5,
																	border: `1px solid ${theme.colors.lightPurple}`,
																	boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
																}}
																text_props={{ semi: true }}
																menu_styles={{
																	width: 180,
																	height: 'fit-content',
																	...(is_anchor_top && { marginTop: -210 }),
																}}
															/>
														</div>
													</div>
												</div>
											)}
										</>
									);
								})}
							</div>
						</>
					)}
				</div>
				<Divider style={{ marginTop: 0, marginBottom: 10 }} />
				<div className={classes.footer_wrap}>
					{handle_export && !show_report_modal ? (
						<div
							className={clsx(classes.report_btn, !is_any_filter_selected && !is_report_enabled ? classes.disabled_button : '')}
							onClick={report_items.length === 0 ? () => _handle_export() : () => set_show_report_modal(true)}>
							{is_any_filter_selected || is_report_enabled ? (
								<img width={16} height={16} src={ImageLinks.download} alt='' />
							) : (
								<img width={16} height={16} src={ImageLinks.download_gray} alt='' />
							)}
							<Text className={classes.report_btn_text} semi>
								Get Report
							</Text>
						</div>
					) : (
						<div></div>
					)}
					<div className='d-flex' style={{ gap: 10 }}>
						<Button
							type='outlined'
							text='Reset'
							size='large'
							onClick={handle_reset}
							disabled={!is_any_filter_selected}
							left_icon={is_any_filter_selected ? ImageLinks.refresh_single_arrow_purple : ImageLinks.refresh_single_arrow_grey}
							style={{ width: 110 }}
						/>
						<Button type='round' text='Search' size='large' onClick={handle_search} disabled={!is_any_filter_selected} style={{ width: 110 }} />
					</div>
				</div>
			</div>
			<Dialog open={date_picker_details.open} PaperProps={{ style: { background: 'transparent', maxWidth: 900 }, elevation: 0 }}>
				<DialogContent style={{}}>
					<DateRangePicker
						open={date_picker_details.open}
						toggle={() => set_date_picker_details({ ...initial_date_picker_details })}
						initialDateRange={
							temp_selected_filters[date_picker_details.param]
								? {
										startDate: moment(temp_selected_filters[date_picker_details.param].split(',')[0]),
										endDate: moment(temp_selected_filters[date_picker_details.param].split(',')[1]),
								  }
								: null
						}
						maxDate={filter_from == 'fleet' ? null : new Date()}
						style={{ width: 200 }}
						onChange={(range) => {
							handle_change(
								date_picker_details.param,
								moment(range.startDate).format('YYYY-MM-DD') + ',' + moment(range.endDate).format('YYYY-MM-DD'),
							);
							set_date_picker_details({ ...initial_date_picker_details });
						}}
					/>
				</DialogContent>
			</Dialog>
			{show_report_modal && (
				<FilterReportModal
					report_filters={report_filters}
					handle_export={_handle_export}
					report_items={report_items}
					wrapper_styles={{ right: 468 }}
					close={() => set_show_report_modal(false)}
					filter_from={filter_from}
					is_report_item_single_select={is_report_item_single_select}
					skip_date_check={skip_date_check}
					date_check_array={date_check_array}
				/>
			)}
		</>
	);
};
export default Filters;
