import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageLinks from 'assets/images/ImageLinks';
import Select from 'modules/Select';
import _ from 'lodash';
import theme from 'resources/theme';
import { useState } from 'react';
import api_requests from 'resources/api_requests';
import Text from 'modules/Text';
import clsx from 'clsx';
import { VEHICLE_REF_SEARCH_OPTIONS } from '../helper';

const useStyles = makeStyles((theme) => ({
	searchBoxContainer: {
		height: 36,
		width: 400,
		display: 'flex',
		padding: '0px 12px',
		alignItems: 'center',
		justifyContent: 'space-between',
		background: theme.colors.white,
		marginLeft: 12,
		marginRight: 8,
		borderRadius: 46,
		zIndex: 999,
	},
	menu_item_class: {
		height: 20,
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
	},
	rider_drop_wrap: { position: 'absolute', width: '95%', maxHeight: 107, marginLeft: 15, marginRight: 12, marginTop: 8 },
	rider_item_wrap: {
		backgroundColor: theme.colors.white,
		height: 33,
		padding: 8,
		boxShadow: `0px 2px 4px 0px rgba(196, 196, 196, 0.30)`,
		borderRadius: 4,
		marginBottom: 4,
	},
	form_wrap: {
		flex: 1,
		marginLeft: 10,
		width: 200,
	},
}));

const VehicleRefSearch = ({ handle_search }) => {
	const [search_by, set_search_by] = useState(VEHICLE_REF_SEARCH_OPTIONS[0].value);
	const [search_text, set_search_text] = useState('');
	const [search_rider_text, set_search_rider_text] = useState('');
	const [search_riders, set_search_riders] = useState([]);
	const classes = useStyles();
	const search_component_ref = useRef(null);

	useEffect(() => {
		const handle_click_outside = (event) => {
			if (search_component_ref.current && !search_component_ref.current.contains(event.target) && !event.target.className?.includes('rider_dropdown')) {
				handle_outside_click();
			}
		};
		document.addEventListener('click', handle_click_outside, true);
		return () => {
			document.removeEventListener('click', handle_click_outside, true);
		};
	}, []);

	const handle_outside_click = () => {
		set_search_by(VEHICLE_REF_SEARCH_OPTIONS[0].value);
		set_search_text('');
		set_search_rider_text('');
	};

	useEffect(() => {
		if (_.isEmpty(search_rider_text)) {
			set_search_riders([]);
			return;
		}
		api_requests
			.search_rider_without_zone({
				search: search_rider_text,
				is_active: true,
			})
			.then((res) => {
				set_search_riders(res.data);
			});
	}, [search_rider_text]);

	const handle_trip_details = (e) => {
		e.preventDefault();
		if (search_by === 'rider') return;
		handle_search(false, { [search_by]: search_text });
	};

	return (
		<div style={{ position: 'relative' }}>
			<div ref={search_component_ref} className={classes.searchBoxContainer}>
				<div className='align-items-center' style={{ paddingLeft: 4, background: theme.colors.lightGrey7 }}>
					<Select
						value={search_by}
						options={VEHICLE_REF_SEARCH_OPTIONS}
						handleChange={(val) => set_search_by(val)}
						disablePadding={true}
						containerStyles={{ height: 22, width: 110, background: theme.colors.white }}
						text_styles={{ fontSize: 12, width: '100%' }}
						right_icon={ImageLinks.arrow_up_pink}
						image_styles={{ width: 10 }}
						menu_styles={{ marginTop: 30, paddingTop: 0 }}
						text_props={{ medium: true }}
						menu_item_class={{ className: classes.menu_item_class }}
					/>
					<div className={classes.form_wrap}>
						<form onSubmit={handle_trip_details}>
							<input
								placeholder={`Search Live Tracking`}
								value={search_by === 'rider' ? search_rider_text : search_text}
								style={{ flex: 1, border: 'none', outline: 'none', background: theme.colors.lightGrey7 }}
								onChange={(e) => {
									search_by === 'rider' ? set_search_rider_text(e.target.value) : set_search_text(e.target.value);
								}}
							/>
						</form>
					</div>
				</div>

				<img
					src={ImageLinks.arrow_right_light_purple}
					onClick={handle_trip_details}
					style={{ opacity: `${search_text.length || search_rider_text.length ? 1 : 0.3}` }}
					alt='purple-right-arrow'
					width={'20px'}
					height={'20px'}
					className='cursor-pointer'
				/>
			</div>
			<div className={clsx(classes.rider_drop_wrap, 'rider_dropdown')}>
				{_.map(search_riders, (rider, index) => (
					<div
						key={`rider_item_${index}`}
						className={clsx(classes.rider_item_wrap, 'cursor-pointer', 'rider_dropdown')}
						onClick={() => {
							handle_search(true, rider.rider_id);
							handle_outside_click();
						}}>
						<Text semi style={{ fontSize: 14 }} className={'rider_dropdown'}>
							{`${rider.rider_name}`}
							<Text medium style={{ fontSize: 12, marginLeft: 12 }} component={'span'}>
								({rider.rider_id})
							</Text>
						</Text>
					</div>
				))}
				{!_.isEmpty(search_rider_text) && search_riders.length === 0 && (
					<div className={clsx('d-flex align-items-center justify-content-center', classes.rider_item_wrap)}>
						<img src={ImageLinks.exclamation_error} width={16} height={16} style={{ marginRight: 4 }} alt='' />
						<Text semi style={{ fontSize: 14 }}>
							Uh-oh! No rider found
						</Text>
					</div>
				)}
			</div>
		</div>
	);
};

export default VehicleRefSearch;
