import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import api_requests from 'resources/api_requests';
import { renderToString } from 'react-dom/server';
import { AVAILABLE_RIDER_TYPE, BOTTOM_STATE, TYPES } from './helper';
import constant from 'resources/constant';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import theme from 'resources/theme';
import { useHistory } from 'react-router-dom';
import Routes from 'resources/Routes';
import { shallowEqual, useSelector } from 'react-redux';
import { POWERED_WITH_PIDGE_MENU_ITEMS } from 'views/PoweredWithPidge/helper';

let map = null;
let bounds;
let marker;
let rider_marker = [];

const useAllocationModal = (close, pickup_coords, drop_coords, mcp_ids, is_multiple_network, network_id, on_success) => {
	const history = useHistory();
	const { app_configs } = useSelector(
		(state) => ({
			app_configs: state.app.app_configs,
		}),
		shallowEqual,
	);
	const [selected_type, set_selected_type] = useState(TYPES.BUSINESS);

	const [biz_available_rider_type, set_biz_available_rider_type] = useState(AVAILABLE_RIDER_TYPE.LOWEST_COST);
	const [biz_selected_rider, set_biz_selected_rider] = useState(null);

	// network state
	const [network_available_rider_type, set_network_available_rider_type] = useState(AVAILABLE_RIDER_TYPE.LOWEST_COST);
	const [network_selected_rider, set_network_selected_rider] = useState(null);

	const [bottom_bar_state, set_bottom_bar_state] = useState(BOTTOM_STATE.start);

	const [available_rider_list, set_available_rider_list] = useState([]);

	const timer_ref = useRef(null);
	const biz_selected_rider_ref = useRef(null);
	const network_selected_rider_ref = useRef(null);

	useEffect(() => {
		set_biz_available_rider_type(AVAILABLE_RIDER_TYPE.LOWEST_COST);
		set_network_available_rider_type(AVAILABLE_RIDER_TYPE.LOWEST_COST);
		set_network_selected_rider(null);
		set_biz_selected_rider(null);
	}, [selected_type]);

	useEffect(() => {
		if (biz_available_rider_type === AVAILABLE_RIDER_TYPE.AVAILABLE || network_available_rider_type === AVAILABLE_RIDER_TYPE.AVAILABLE) {
			fetch_top_riders();
		} else remove_rider_markers();
	}, [biz_available_rider_type, network_available_rider_type]);

	useEffect(() => {
		network_selected_rider_ref.current = network_selected_rider;
	}, [network_selected_rider]);

	useEffect(() => {
		biz_selected_rider_ref.current = biz_selected_rider;
	}, [biz_selected_rider]);

	function buildContent(property) {
		const content = document.createElement('div');
		content.innerHTML = `
		  <div style="border:4px solid #E4DCF3;width:38px;height:38px;border-radius:19px;display:flex;justify-content:center;align-items:center"><div style="border:2px solid #492897;width:30px;height:30px;border-radius:15px;display:flex;justify-content:center;align-items:center;background-color:#FFFFFF"><img src=${ImageLinks.rider_purple} width={16} height={16}/></div></div>
		  `;
		return content;
	}

	const remove_rider_markers = () => {
		for (let i = 0; i < rider_marker.length; i++) {
			rider_marker[i].setMap(null);
		}
		rider_marker = [];
		if (!map) return;
		bounds = new window.google.maps.LatLngBounds();
		let pickup_coord = new window.google.maps.LatLng(Number(pickup_coords?.latitude), Number(pickup_coords?.longitude));
		bounds.extend(pickup_coord);
		_.map(drop_coords, (val) => {
			let drop_coord = new window.google.maps.LatLng(Number(val?.latitude), Number(val?.longitude));
			bounds.extend(drop_coord);
		});
		map.fitBounds(bounds);
	};

	const fetch_top_riders = async () => {
		const id = selected_type === TYPES.BUSINESS ? -2 : network_id;
		const lat = Number(pickup_coords?.latitude);
		const long = Number(pickup_coords?.longitude);
		try {
			const response = await api_requests.get_network_top_riders({
				network_id: id,
				lat,
				long,
			});
			if (response.success && response.data.length > 0) {
				set_available_rider_list(response.data);
				// draw_rider_markers(response.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handle_track = () => {
		history.push(Routes.POWERED_WITH_PIDGE.path + '?type=' + POWERED_WITH_PIDGE_MENU_ITEMS.OnGround.id);
	};

	const handle_allocate_rider = async () => {
		set_bottom_bar_state(BOTTOM_STATE.in_progress);

		let payload;
		switch (selected_type) {
			case TYPES.BUSINESS:
				payload = {
					mcp_ids,
					partner: 'captive',
					pickup_now: true,
					allocation_param: {
						cost:
							biz_available_rider_type === AVAILABLE_RIDER_TYPE.BORADCAST
								? 4
								: biz_available_rider_type === AVAILABLE_RIDER_TYPE.AVAILABLE
								? 3
								: 1,
						...(biz_selected_rider && { rider_id: biz_selected_rider?.rider_id }),
					},
				};
				break;

			case TYPES.NETWORK:
				payload = {
					mcp_ids,
					partner: 'ppn-private',
					pickup_now: true,
					allocation_param: {
						network_id,
						network_name: _.get(app_configs, `network_configs.${network_id}.name`, null),
						cost:
							network_available_rider_type === AVAILABLE_RIDER_TYPE.BORADCAST
								? 4
								: network_available_rider_type === AVAILABLE_RIDER_TYPE.AVAILABLE
								? 3
								: 1,
						...(network_selected_rider && { rider_id: network_selected_rider?.rider_id }),
					},
				};
				break;

			default:
				break;
		}
		timer_ref.current = setTimeout(() => {
			api_requests.fulfill_order(payload, false).then(
				(res) => {
					set_bottom_bar_state(BOTTOM_STATE.success);
					on_success?.();
				},
				(err) => {
					set_bottom_bar_state(BOTTOM_STATE.failed);
				},
			);
		}, 4000);
	};

	const handle_try_again = () => {
		set_bottom_bar_state(BOTTOM_STATE.start);
	};

	return {
		selected_type,
		set_selected_type,
		biz_available_rider_type,
		set_biz_available_rider_type,

		network_available_rider_type,
		set_network_available_rider_type,

		bottom_bar_state,
		set_bottom_bar_state,
		biz_selected_rider,
		set_biz_selected_rider,
		network_selected_rider,
		set_network_selected_rider,
		handle_allocate_rider,
		fetch_top_riders,
		handle_track,
		handle_try_again,
		app_configs,
		available_rider_list,
	};
};

export default useAllocationModal;
