import { ListItem, makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import SelectWithInput from 'modules/SelectWithInput';
import Text from 'modules/Text';
import React, { useEffect, useState } from 'react';
import theme from 'resources/theme';
import { ALLOCATE_OPTIONS, AVAILABLE_RIDER_TYPE, TYPES } from '../helper';
import api_requests from 'resources/api_requests';
import utils from 'resources/utils';
import constant from 'resources/constant';

const useStyles = makeStyles(() => ({
	pref_wrap: { borderTop: `1px solid ${theme.colors.lightPurple}`, marginTop: 8, paddingTop: 8 },
	search_list: {
		width: 200,
		height: 99,
		overflowY: 'auto',
		paddingBottom: 10,
		marginTop: 5,
		borderRadius: 5,
		position: 'absolute',
		right: 40,
	},
	rider_row: {
		backgroundColor: theme.colors.white,
		padding: 8,
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		boxShadow: `0px 4px 4px 0px rgba(202, 202, 202, 0.25)`,
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		'&:hover': {
			backgroundColor: theme.colors.lightPink4,
		},
	},
	rider_allocation_option: {
		height: 26,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingLeft: 8,
		paddingRight: 8,
		borderRadius: 30,
		marginRight: 4,
	},
	rider_wrap: {
		flex: 1,
		padding: 10,
		borderRadius: 4,
		height: 40,
		backgroundColor: theme.colors.lightPurple,
		border: `1px solid ${theme.colors.primary}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		'&:hover': {
			'& .rider-duration': {
				display: 'none',
			},
			'& $remove_text': {
				display: 'block',
			},
		},
	},
	remove_text: { display: 'none', fontSize: 12, color: theme.colors.darkPurple, textDecoration: 'underline', cursor: 'pointer' },
	type_description: {
		width: 284,
		padding: 10,
		height: 52,
		borderRadius: 6,
		border: `1px solid ${theme.colors.primary}`,
		backgroundColor: theme.colors.lightPink4,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
}));

const RiderType = ({ network_id, available_rider_type, set_available_rider_type, selected_rider, set_selected_rider, parent_api_headers = {} }) => {
	const [search_rider_text, set_search_rider_text] = useState('');
	const [search_rider_list, set_search_rider_list] = useState([]);
	const classes = useStyles();

	useEffect(() => {
		if (_.isEmpty(search_rider_text)) {
			set_search_rider_list([]);
			return;
		}
		api_requests
			.search_rider_without_zone(
				{
					search: search_rider_text,
					is_active: true,
					...(network_id != -2 && { network_id }),
				},
				parent_api_headers,
			)
			.then((res) => {
				set_search_rider_list(res.data);
			});
	}, [search_rider_text]);

	return (
		<>
			<div className={classes.pref_wrap}>
				<div className='align-items-center justify-content-between'>
					<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
						Rider Preference:
					</Text>

					<SelectWithInput
						value={'Search Rider'}
						inputValue={search_rider_text}
						show_arrow={false}
						onChange={(text) => set_search_rider_text(text)}
						onClick={(e) => {
							e.stopPropagation();
							set_available_rider_type(null);
							set_search_rider_list([]);
						}}
						right_icon={
							search_rider_text ? (
								<img
									onClick={(e) => {
										e.stopPropagation();
										set_selected_rider(null);
										set_search_rider_text(null);
									}}
									src={ImageLinks.arrow_right_light_purple}
									width={16}
									height={16}
									alt=''
								/>
							) : (
								<img src={ImageLinks.search_light_purple} width={16} height={16} alt='' />
							)
						}
						mainContainerStyles={{ height: 26, width: 200, color: theme.colors.lightPurple }}
						containerStyles={{ borderColor: theme.colors.lightPurple3, width: 200 }}
					/>
				</div>
			</div>
			<div style={{ flex: 1, overflowY: 'hidden' }}>
				{!_.isEmpty(search_rider_list) && (
					<div className={classes.search_list}>
						{_.map(search_rider_list, (rider, key) => {
							return (
								<ListItem
									button
									key={`rider${key}`}
									className={classes.rider_row}
									style={{
										...(selected_rider?.rider_id === rider.rider_id && {
											border: `1px solid ${theme.colors.primary}`,
											background: theme.colors.lightPurple,
										}),
									}}
									onClick={(e) => {
										e.stopPropagation();
										set_selected_rider(rider);
										set_search_rider_list([]);
										set_search_rider_text('');
									}}>
									<Text medium style={{ fontSize: 14 }}>
										{rider.rider_name} ({rider.rider_id})
									</Text>
								</ListItem>
							);
						})}
					</div>
				)}
				<div className='align-items-center justify-content-center' style={{ marginTop: 4 }}>
					{_.map(ALLOCATE_OPTIONS, (item, index) => (
						<div
							key={`allocate_option_${index}`}
							onClick={(e) => {
								e.stopPropagation();
								utils.track_event_for_analytics(constant.TRACKING_EVENTS.RIDER_TYPE + `_${item.key}`);
								set_available_rider_type(item.key);
							}}
							className={classes.rider_allocation_option}
							style={{
								pointerEvents: !_.isEmpty(selected_rider) || !_.isEmpty(search_rider_text) ? 'none' : 'auto',
								backgroundColor: available_rider_type === item.key ? theme.colors.primary : theme.colors.white,
								border: `${
									_.isEmpty(available_rider_type) || !_.isEmpty(search_rider_text) ? 1 : available_rider_type === item.key ? 0 : 1
								}px solid ${_.isEmpty(available_rider_type) ? theme.colors.lightGrey2 : theme.colors.primary}`,
							}}>
							<Text
								semi
								style={{
									fontSize: 12,
									color:
										_.isEmpty(available_rider_type) || !_.isEmpty(search_rider_text)
											? theme.colors.lightGrey2
											: available_rider_type === item.key
											? theme.colors.white
											: theme.colors.primary,
								}}>
								{item.title}
							</Text>
						</div>
					))}
				</div>
				{!_.isEmpty(selected_rider) && (
					<div className='align-items-center' style={{ marginTop: 12 }}>
						<div className={classes.rider_wrap}>
							<Text semi style={{ fontSize: 12 }}>
								{selected_rider.rider_name} ({selected_rider.rider_id})
							</Text>
							<Text semi className={'rider-duration'} style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
								{/* ~10 min */}
							</Text>
							<Text
								semi
								className={classes.remove_text}
								onClick={(e) => {
									e.stopPropagation();
									set_selected_rider(null);
								}}>
								Remove
							</Text>
						</div>
					</div>
				)}

				{!_.isEmpty(available_rider_type) && _.isEmpty(selected_rider) && (
					<div className='align-items-center justify-content-center' style={{ marginTop: 12 }}>
						<div className={classes.type_description}>
							<img
								src={
									available_rider_type === AVAILABLE_RIDER_TYPE.LOWEST_COST
										? ImageLinks.inr_circle_light_purple
										: available_rider_type === AVAILABLE_RIDER_TYPE.AVAILABLE
										? ImageLinks.clock_check_light_purple
										: available_rider_type === AVAILABLE_RIDER_TYPE.TOP_RIDER
										? ImageLinks.rider_light_purple
										: ImageLinks.tower_light_purple
								}
								width={32}
								height={32}
								style={{ marginRight: 12 }}
								alt=''
							/>
							<Text bold style={{ fontSize: 10, color: theme.colors.primary }}>
								{available_rider_type === AVAILABLE_RIDER_TYPE.LOWEST_COST
									? 'Lowest Cost'
									: available_rider_type === AVAILABLE_RIDER_TYPE.AVAILABLE
									? 'Available'
									: available_rider_type === AVAILABLE_RIDER_TYPE.TOP_RIDER
									? 'Top Rider'
									: 'Broadcast'}
								<Text component={'span'} medium style={{ fontSize: 10, marginLeft: 2 }}>
									{available_rider_type === AVAILABLE_RIDER_TYPE.LOWEST_COST
										? 'automatically assign the nearest and most economical rider to fulfill your order'
										: available_rider_type === AVAILABLE_RIDER_TYPE.AVAILABLE
										? 'checks for the nearest and free rider to fulfill your order'
										: available_rider_type === AVAILABLE_RIDER_TYPE.TOP_RIDER
										? 'chooses the closest available rider to the pickup location'
										: 'attempts assignment to multiple riders nearest to the pickup location'}
								</Text>
							</Text>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default RiderType;
